import React, { Component, useEffect } from 'react';
import {LocalStorage} from '../components/LocalStorage';
import * as ReactBootStrap from 'react-bootstrap';
import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';

import logo from '../tools/images/logomedicall24.png';
import img from '../tools/images/logo.png';
import fondo from '../tools/images/fondo.png';
import '../tools/css/CambioContraseña.css';
import axios from 'axios';
import $ from 'jquery'; // <-to import jquery
import 'bootstrap';

const ApiURL="https://medicallapi-test.azurewebsites.net";
var mensaje='';

class CambiarContraseña extends Component {

   
   constructor()
    {
     super()
      this.state={
        loading_button: true,
        active_button: true,
        loading_buttonrecovery_pass: true,
        active_buttonrecovery_pass: true,
        loading_buttonchange_pass: true,
        active_buttonchange_pass: true,
        form:{
          cod_recuperacion:'',
          pass:'',
          pass_confirm:'',
          }              
      }     
    }

   handleChange=async e=>{
    await this.setState({
       form:{
            ...this.state.form,
            [e.target.name]: e.target.value
            }
       })
       console.log(this.state.form);
    }


    modal_recoverypass(){
      $('#mensaje_recupera').hide();
      $('#modal-recoverypass').modal('show');
      $("#modal-recoverypass input").val("");
      $('#cambio_contraseña').show();
      $('#link_iniciasesion').hide();
      $('#correo_recuperacion').hide();
      $('#btns_option').hide();
      this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = true })
    }

    validar_codigo(){
      $('#mensaje_recupera').hide();
      $('#cambio_contraseña').show();
      $('#correo_recuperacion').hide();
      $('#btns_option').hide();     
    }

    componentDidMount(){

      this.modal_recoverypass();

    }

    mostrarPassword(){
      var cambio = document.getElementById("txtPassword");
		if(cambio.type == "password"){
			cambio.type = "text";
			$('.icon').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
		}else{
			cambio.type = "password";
			$('.icon').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
		}
    }

   iniciarSesion=async()=>{

    if(this.state.form.username.length==0 || this.state.form.passwor.length==0){

      store.addNotification({
        title: 'Atención',
        message: "Faltan datos por ingresar",
        type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
        container: 'top-right',                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
          duration: 10000,
          showIcon:true
        }
      })

    }else{
      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ loading_button: this.state.active_button = false })

      await axios.post(ApiURL+"/api/Auth/LoginAdmin",

        {
        nit:this.state.form.nit_institucion,
        email: this.state.form.username,
        password: this.state.form.passwor
        })  

      .then(response=>{
        console.log(response)
        //var  status  = response.status;
        //alert(status); 
        //mensaje  = response.data.message;
              

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ loading_button: this.state.active_button = true })
       
              var  token  = response.data.token;
              var  id_institution= response.data.institution.id;
              var  institution  = response.data.institution.name;
              var  avatar  = response.data.institution.avatar;
              //alert(token); 
              sessionStorage.setItem('token',token)
              sessionStorage.setItem('id_institution',id_institution)
              sessionStorage.setItem('institution',institution)
              sessionStorage.setItem('avatar',avatar)
              window.location.href="/home";        
              
              
         
        //var  dato  = response.data.token;
        //alert(dato);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        
      })
      .catch(error=>{

        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ loading_button: this.state.active_button = true })

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
      }
      //console.log(error.config);
    
     })
      
    }    

   }

   _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
     
      this.iniciarSesion()
      
    }
  }



   Recuperar_cuenta=async()=>{

    if(!/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.state.form.correo_recupera)){

      store.addNotification({
        title: 'Atención',
        message: "La dirección de correo electrónico ingresada contiene caracteres no válidos.",
        type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
        container: 'top-right',                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
          duration: 10000,
          showIcon:true
        }
      })

    }else{
      this.setState({ loading_buttonrecovery_pass: this.state.loading_buttonrecovery_pass = false })
      this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = false })
      await axios.post(ApiURL+"/api/Auth/RecoveryPassword",

        {         
          email: this.state.form.correo_recupera          
        })  

      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
      store.addNotification({
          title: 'Muy bien',
          message: mensaje,
          type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
          duration: 10000,
          showIcon:true
          }
        })

        this.setState({ loading_buttonrecovery_pass: this.state.loading_buttonrecovery_pass = true })
        this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = false })

        //$('#alert-recoverypass').html('<div class="alert alert-success" role="alert">'+mensaje+'</div>');
        
        $('#mensaje_recupera').hide();
        $('#cambio_contraseña').show();
        $('#correo_recuperacion').hide();
        $('#btns_option').hide();
        
        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_buttonrecovery_pass: this.state.loading_buttonrecovery_pass = true })
          this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = true })

        $('#alert-recoverypass').html('<div class="alert alert-danger" role="alert">'+mensaje+'</div>');

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
      }
      //console.log(error.config);
    
    })

    }      

   }


   Cambiar_Contraseña=async()=>{

    if(this.state.form.cod_recuperacion.length==0 || this.state.form.pass.length==0 || this.state.form.pass_confirm.length==0 ){

      store.addNotification({
        title: 'Atención',
        message: "Faltan datos por ingresar",
        type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
        container: 'top-right',                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
          duration: 10000,
          showIcon:true
        }
      })

    }else{
      if(this.state.form.pass!==this.state.form.pass_confirm){

        store.addNotification({
          title: 'Atención',
          message: 'La contraseña ingresada no es correcta',
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
          duration: 10000,
          showIcon:true
          }
        })

      }else{
      
      this.setState({ loading_buttonchange_pass: this.state.loading_buttonchange_pass = false })
      this.setState({ active_buttonchange_pass: this.state.active_buttonchange_pass = false })    

      await axios.post(ApiURL+"/api/Users/ChangePassword", 

        {
          validationCode:this.state.form.cod_recuperacion,         
          password: this.state.form.pass          
        })
 
      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
      store.addNotification({
          title: 'Muy bien',
          message: mensaje,
          type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
          duration: 10000,
          showIcon:true
          }
        })

        this.setState({ loading_buttonchange_pass: this.state.loading_buttonchange_pass = true })
        this.setState({ active_buttonchange_pass: this.state.active_buttonchange_pass = true })

        $('#cambiocontra').hide();
        $('#link_iniciasesion').show();

        //$('#alert-recoverypass').html('<div class="alert alert-success" role="alert">'+mensaje+'</div>');
        
        
        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

        this.setState({ loading_buttonchange_pass: this.state.loading_buttonchange_pass = true })
        this.setState({ active_buttonchange_pass: this.state.active_buttonchange_pass = true })

        //$('#alert-recoverypass').html('<div class="alert alert-danger" role="alert">'+mensaje+'</div>');

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
      }
      //console.log(error.config);
    
    })

     }

    }      

   }

  render(){  

   return (    
   

      <div className="div_containe" id="container">   

            <nav className="navbar navbar-expand navbar-white navbar-light" id="nav2">       
                  
                 
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="nav-link" data-widget="pushmenu" href="#" role="button"></a>
                    </li>
                    <a  href="https://medicall24.com.co/" className="navbar-brand"> <img src={img} alt="logo"  width= "230"/> </a>
                    
                  </ul>           
                    
  
            </nav>             
            

            <div className="modal fade" id="modal-recoverypass" data-backdrop="static">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Recuperar contraseña</b></h4>
                     {/* / <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    */}
                    </div>
    
                    <div className="modal-body">

                          {/* /<span id="alert-recoverypass"> </span> */}
                      
                          <div className="row" id="mensaje_recupera"> 

                              <div className="col-md-12">

                                 Se enviará un código de recuperación al correo electrónico registrado. <strong> {this.state.form.email_inst} </strong> 

                              </div>
                                                  
                          
                          </div>

                          <br/>

                          <div className="row" id="correo_recuperacion">                           
                                          
                              <div className="col-md-12">
                                  <div className="form-group">                    
                                          <label >Ingrese el correo:</label>
                                          <input type="text" className="form-control" maxLength="30" name="correo_recupera" onChange={this.handleChange}/>                                                  
                                  </div>          
                              </div>                          
                          
                          </div>

                          <div id="cambio_contraseña">

                              <div className="row">                           
                                              
                                  <div className="col-md-12">
                                      <div className="form-group">                    
                                              <label >Código de validación:</label>
                                              <input type="text" className="form-control" maxLength="6" name="cod_recuperacion" onChange={this.handleChange}/>                                                  
                                      </div>          
                                  </div>
    
                                  <div className="col-md-12">
                                      <div className="form-group">                    
                                              <label >Nueva contraseña:</label>
                                              <input type="password" maxLength="20" className="form-control" name="pass" onChange={this.handleChange}/>                                                  
                                      </div>          
                                  </div>  
    
                                  <div className="col-md-12">
                                      <div className="form-group">                    
                                              <label >Confirmar contraseña:</label>
                                              <input type="password" maxLength="20" className="form-control" name="pass_confirm" onChange={this.handleChange}/>                                                  
                                      </div>          
                                  </div> 
    
                                  <div className="col-md-12 text-center" id="cambiocontra">
                                      <button type="submit" id="btn-cambiocontra" className="btn"  disabled={!this.state.active_buttonchange_pass} onClick={()=> this.Cambiar_Contraseña()}> {this.state.loading_buttonchange_pass?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Cambiar</button>                     
                                  </div>

                                  <div className="col-md-12 text-center" id="link_iniciasesion">
                                          <a type="button" id="btn-cambiocontra" className="btn" href="/cuotasmoderadoras">
                                            <svg width="22" height="22" viewBox="0 0 16 16" className="bi bi-box-arrow-in-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                 <path fillRule="evenodd" d="M8.146 11.354a.5.5 0 0 1 0-.708L10.793 8 8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z"/>
                                                 <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 1 8z"/>
                                                 <path fillRule="evenodd" d="M13.5 14.5A1.5 1.5 0 0 0 15 13V3a1.5 1.5 0 0 0-1.5-1.5h-8A1.5 1.5 0 0 0 4 3v1.5a.5.5 0 0 0 1 0V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5h-8A.5.5 0 0 1 5 13v-1.5a.5.5 0 0 0-1 0V13a1.5 1.5 0 0 0 1.5 1.5h8z"/>
                                            </svg> Iniciar Sesión
                                          </a>
                                  </div> 
    
                              </div> 

                          </div>        
                              
                    </div> 
    
                    <div className="modal-footer justify-content-between" id="btns_option">      
                                        
                          <button type="button" className="btn btn-primary" disabled={!this.state.active_buttonrecovery_pass} onClick={()=> this.Recuperar_cuenta()}> {this.state.loading_buttonrecovery_pass?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Recuperar contraseña </button>           
                    
                          <button type="button" className="btn btn-primary" onClick={()=> this.validar_codigo()}>  Tengo un código </button>            
              
                             
                    </div>

                  </div>
                  
                </div>
                
              </div>

</div>


  );
 }
}


export default CambiarContraseña;
