import React, {Component} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types'

//Componente para listar en select los contratos consumido en API

class Departamentos extends Component {   

    constructor()
    {
     super()
      this.state={        
        Depaetamentos: [],
        id_departamento:''
      }
    }

    componentDidMount(){    
    
        axios.get("https://medicallapi-test.azurewebsites.net/api/Departments/ListDepartments")
        .then((response) => {
          console.log(response);
          this.setState({Depaetamentos: response.data})
        })
        .catch((error) => {
          console.log(error);
        })
      } 
      
      handleChange = (e) =>{
        this.setState({id_departamento:e.target.value})
        //console.log(e.target.value)
        //var med=(e.target.value)
        //alert(med)
      } 
      

    render(){
      const {handleriddepartamento} = this.props  
      const {id_departamento} = this.state
        
   
     return (   
     
        <div >

                <select onChange={this.handleChange} onClick={()=>handleriddepartamento(id_departamento)} value={this.state.id_departamento}  className="form-control" id="id_departamento">
                    <option  value="null"> Seleccione...</option> ,
                    {this.state.Depaetamentos.map(item=>                              
                    <option key={item.id} value={item.id}> {item.nombre.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                    )}
                            
                </select>                    
         
        </div>    
      
    )

  }
  
}  

Departamentos.propTypes = {
  handleriddepartamento:PropTypes.func.isRequired
}


export default Departamentos;