import React, {Component,useState} from 'react';
import axios from 'axios';
import * as ReactBootStrap from 'react-bootstrap';
import '../tools/css/Servicios.css';

import Departamentos from '../API/Departamentos';

import emptypage from '../tools/images/carpeta.gif';

import BtnEdit from '../components/BtnEdit';
import $ from 'jquery'; // <-to import jquery
import 'bootstrap';

import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';



const ApiURL="https://medicallapi-test.azurewebsites.net";
var mensaje='';
var coddepa='0';
var codmuni='0';
var muni='';
var idmuni_edit='0';
var depa='';
var iddepa_edit='0';
var institution_id =sessionStorage.getItem('id_institution');


class Sedes extends Component {

  constructor()
    {
     super()
      this.state={
        show:false,
        Sedes:[] , 
        Departamentos: [],
        Municipios:[],
        loading: false,
        loading_button: true,
        active_button: true,
        form:{   
          nom_sede:'',  
          cod_habilita:'',   
          dir_sede:'',
          tel_sede:'',
          idsede_edit:'',     
          nom_sedeedit:'',  
          cod_habilitaedit:'',   
          dir_sedeedit:'',
          tel_sedeedit:'',      
          }     
      }
    }


    handleChange=async e=>{
     
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
        
         console.log(this.state.form);
         //this.Selectdrop();
    }

    Campo_numerico(){
      $(".camponum").keypress(function(tecla)
      {
        if(tecla.charCode<48 || tecla.charCode>57){
         
          return false;

        }
      })
    }

    
    handleModalnewsede()
    {
     
      $('#modal-newsede').modal('show'); // <- to show modal crear sede 
    }

    handleModaleditsede()
    {
      
      $('#modal-editsede').modal('show'); // <- to show modal editar sede
    }

    Limpiar_form=()=>{
      this.setState({
        form:{          
          nom_sede:'',  
          cod_habilita:'',   
          dir:'',
          valor_servi:''     
        }
      })
     
    }

    handleModalrespuestacitadrop()
    {
      $('#modal-newsede').on('show.bs.modal', function (event) {
        $("#modal-newsede input").val("");       
        $("#modal-newsede select").val("Seleccione...");
      
    });
      $("#id_departamento").val($('#id_departamento > option:first').val());
       
        coddepa="0";
        codmuni="0";  
        
        
        this.setState({Municipios: []})

    }


    handleModaleditsededrop()
    {
      
      $("#id_departamentoedit").val($('#id_departamentoedit > option:first').val());
       
        iddepa_edit="0";
        idmuni_edit="0"  
        
        this.setState({Municipios: []})

    }


    componentDidMount(){

      this.Cargar_sedes();

      axios.get(ApiURL+"/api/Departments/ListDepartments")
        .then((response) => {
          console.log(response);
          this.setState({Departamentos: response.data})
        })
        .catch((error) => {
          console.log(error);
        })

    }


    empty_page_hide(){
      $('#tabla1').hide();     
      $('#empty_page').hide();
      $('#text_page').hide();
    }


    Cargar_Municipios(coddepa){    
      
      axios.get(ApiURL+"/api/Departments/ListMunicipalties/"+coddepa)
      .then((response) => {
        console.log(response);
        this.setState({Municipios: response.data})
        
      })
      .catch((error) => {
        console.log(error);
      })
    }



    seleccionarsede=(sede)=>{

      this.handleModaleditsededrop();

     
     
      idmuni_edit=sede.municipality.id
      muni=sede.municipality.nombre.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
      iddepa_edit=sede.department.id
      depa=sede.department.nombre.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
      this.Cargar_Municipios(iddepa_edit)
      //alert(muni)
      
      this.setState({
        form:{
          idsede_edit:sede.id,
          nom_sedeedit:sede.name,  
          cod_habilitaedit:sede.providerCode,   
          dir_sedeedit:sede.address ,
          tel_sedeedit:sede.phone               
         
        }
      })

      
      //var  dato  = servicio.id;
      //alert(servicio.id);
    }


    Cargar_sedes(){
      
      this.empty_page_hide();
      
      this.setState({ loading: this.state.loading = false })
    
      axios.get(ApiURL+"/api/Sedes/ListByInstitution/"+institution_id)
      .then((response) => {
        console.log(response);
        this.setState({Sedes: response.data})
        //this.setState({Pager_Convenios: response.data.pager.items})
        //this.setState({pager_infoconvenio: response.data.pager.showData})
        //this.Selectdrop()

        if(this.state.Sedes.length==0){
          $('#tabla1').hide();
          $('#empty_page').show();
          $('#text_page').show();
        }else{
          $('#tabla1').show();
          $('#empty_page').hide();
          $('#text_page').hide();
        }

        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })

    }


    Crear_Sede=async()=>{

      if(this.state.form.nom_sede.length==0 || this.state.form.cod_habilita.length==0 || this.state.form.dir_sede.length==0 || this.state.form.tel_sede.length==0 || coddepa=="0" || codmuni=="0"){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.post(ApiURL+"/api/Sedes/Create",
  
          {
            name: this.state.form.nom_sede,
            providerCode: this.state.form.cod_habilita,
            departmentId:coddepa,
            municipalityId: codmuni,
            address: this.state.form.dir_sede,
            phone: this.state.form.tel_sede
          })  
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

          this.componentDidMount();
          $('#modal-newsede').modal('hide');
          this.handleModalrespuestacitadrop();

          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })
      })

      }      
  
     }


     Editar_Sede=async()=>{

      if(this.state.form.nom_sedeedit.length==0 || this.state.form.cod_habilitaedit.length==0 || this.state.form.dir_sedeedit.length==0 || this.state.form.tel_sedeedit.length==0 || iddepa_edit=="0" || idmuni_edit=="0"){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.put(ApiURL+"/api/Sedes/Edit",
  
          {
            id:this.state.form.idsede_edit,
            name: this.state.form.nom_sedeedit,
            providerCode: this.state.form.cod_habilitaedit,
            departmentId:iddepa_edit,
            municipalityId: idmuni_edit,
            address: this.state.form.dir_sedeedit,
            phone: this.state.form.tel_sedeedit,
            status: "activo"
          })  
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

          this.componentDidMount();
          $('#modal-editsede').modal('hide');
          this.handleModalrespuestacitadrop();

          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })
      })

      }      
  
     }


    handleriddepartamento = (d) => {
      console.log(d)
      coddepa=(d)
      //alert(id_profe)
      this.Cargar_Municipios(coddepa)
    }

    GetDepartamento = (dp) =>{
      console.log(dp.target.value)
      coddepa=(dp.target.value)  

      this.Cargar_Municipios(coddepa)
     
    }

    GetMunicipio = (cm) =>{
      console.log(cm.target.value)
      codmuni=(cm.target.value)  
     
    }

    GetDepartamento_edit = (dpe) =>{
      console.log(dpe.target.value)
      iddepa_edit=(dpe.target.value)  

      this.Cargar_Municipios(iddepa_edit)

      idmuni_edit="0"      
      muni="Seleccione..."
     
    }

    GetMunicipio_edit = (cme) =>{
      console.log(cme.target.value)
      idmuni_edit=(cme.target.value)
      
      $('#cb_municipio_edit').hide();  
     
    }
       

    render(){  
       
     const {form} = this.state;

     return (

     
      <div className="content-wrapper">


  <div className="col-12 col-sm-12">

          <div className="row">
    
              <div className="col-sm-10">
    
                   <br></br>
                   <div className="text-center" id="title"> <h6> <b> GESTIONAR SEDES </b>  </h6> </div>
    
              </div>
    
              <div  className="col-sm-2 col-md-offset-2">
                    <button id="btn_crear" className="btn btn-add" onClick={()=>{this.handleModalnewsede()}}>Crear
                       <svg width="20" height="15" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                       <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                       </svg> 
                    </button> 
      
              </div>
    
          </div>
    
          <div className="card">            
                      
                <div className="card-body"> 
                      <div className="d-flex justify-content-center">
          
                            {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                    
                      </div>                   
                          
                      <div className="card table-responsive" id="tabla1">
                
                
                              <table id="tabla_convenios" className="table table-bordered table-striped">
                                <thead>
                                  <tr>
                                    <th>ID</th>
                                    <th>Nombre</th>         
                                    <th>Cod. habilitación</th>
                                    <th>Dirección</th>
                                    <th>Departamento</th>
                                    <th>Ciudad</th>
                                    <th>Télefono</th>
                                    <th>Editar</th>
                                    
                                  </tr>
                                </thead>
                                <tbody>
                                {this.state.Sedes.map(sede=>{
                                  return(      
                                  
                                  <tr>          
                                    <td>{sede.id}</td>                                              
                                    <td>{(sede.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                    <td>{sede.providerCode}</td>
                                    <td>{(sede.address).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                    <td>{(sede.department.nombre).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                    <td>{(sede.municipality.nombre).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                    <td>{sede.phone}</td>  
                                    <td onClick={()=>{this.handleModaleditsede();this.seleccionarsede(sede)}}> <BtnEdit  /> 
                                    </td>       
                                  </tr>
                          
                                    )
                                   }
                                 )}                
                                </tbody>
                              </table>
                
                      </div>
                      
                      <div className="d-flex justify-content-center">                              
                           <img id="empty_page" src={emptypage} alt="logo"  width= "200"/>                             
                      </div>
                      <div className="d-flex justify-content-center">                  
                           <h5 id="text_page"> <b>No hay información que mostrar</b> </h5>                          
                      </div>
                </div>
                          
          </div>

  
  </div>


  <div className="modal fade" id="modal-newsede" data-backdrop="static">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title"><b>Crear Sede</b></h4>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> this.handleModalrespuestacitadrop()}>
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">

              <div className="row">

                  <div className="col-md-6">

                      <div className="form-group">                    
                          <b id="asterisco">* </b><label >Nombre de la Sede:</label>
                          <input type="text" className="form-control"   placeholder="Nombre de la sede" name="nom_sede" autoComplete="nope" onChange={this.handleChange}/>                       
                      </div>

                  </div>

                  <div className="col-md-6">

                      <div className="form-group">                    
                          <b id="asterisco">* </b><label >Código de habilitación:</label>
                          <input type="text" maxLength="12" className="form-control camponum"   placeholder="Código de habilitación" autoComplete="nope" name="cod_habilita" onChange={this.handleChange} onKeyDown={this.Campo_numerico()}/>                       
                      </div>

                  </div>
      
              </div>  

              <div className="row">                                

                  <div className="col-md-6">                
                         <div className="form-group">                    
                            <b id="asterisco">* </b> <label >Departamento:</label>
                            <select onChange={this.GetDepartamento}  className="form-control" id="id_departamento">
                               <option  value="0"> Seleccione...</option> ,
                               {this.state.Departamentos.map(item=>                              
                               <option key={item.id} value={item.id}> {item.nombre.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                               )}
                            
                            </select>
                                                  
                         </div>
                             
                  </div>
                  
                  <div className="col-md-6"> 

                      <div className="form-group">                    
                          <b id="asterisco">* </b><label >Municipio:</label>
                          <select onChange={this.GetMunicipio}  className="form-control"  id="cod_muni">
                              <option  value="0"> Seleccione...</option> ,
                              {this.state.Municipios.map(item=>                              
                              <option key={item.id} value={item.id}> {(item.nombre).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                              )}                                                                      
                          </select>
                      </div>
                             
                  </div>  

              </div>      
                  
                  
              <div className="row">
                              
                  <div className="col-md-6">
                      <div className="form-group">                    
                              <b id="asterisco">* </b><label >Dirección:</label>
                              <input type="text" className="form-control"  placeholder="Dirección" name="dir_sede" autoComplete='nope' onChange={this.handleChange}/>                       
                      </div>
  
                  </div>
                  <div className="col-md-6">
  
                      <div className="form-group">                    
                              <b id="asterisco">* </b><label >Telefono:</label>
                              <input type="text" className="form-control"  placeholder="Telefono" name="tel_sede" autoComplete='nope' onChange={this.handleChange}/>                       
                      </div>
  
                  </div>
  
              </div>  
       
        </div>

        <div className="modal-footer justify-content-between">        
          <button type="button" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.Crear_Sede()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar</button>
          <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={()=> this.handleModalrespuestacitadrop()}>Cerrar</button>
        </div>
      </div>
      {/* /.modal-content */}
    </div>
    {/* /.modal-dialog */}
  </div>
  {/* /.modal */}



  <div className="modal fade" id="modal-editsede" data-backdrop="static">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title"><b>Editar Sede</b></h4>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          
              <div className="row">

                  <div className="col-md-6">

                      <div className="form-group">                    
                          <label >Nombre de la Sede:</label>
                          <input type="text" className="form-control" id="nom_sedeedit" name="nom_sedeedit" autoComplete='nope' value={form.nom_sedeedit} onChange={this.handleChange}/>                       
                      </div>

                  </div>

                  <div className="col-md-6">

                      <div className="form-group">                    
                          <label >Código de habilitación:</label>
                          <input type="text" maxLength="12" className="form-control camponum" id="cod_habilitaedit" autoComplete='nope' name="cod_habilitaedit" value={form.cod_habilitaedit} onChange={this.handleChange} onKeyDown={this.Campo_numerico()}/>                       
                      </div>

                  </div>
      
              </div> 

              <div className="row">
                              
                  <div className="col-md-6">
                      <div className="form-group">                    
                              <label >Dirección:</label>
                              <input type="text" className="form-control" id="dir_sedeedit" name="dir_sedeedit" autoComplete='nope' value={form.dir_sedeedit} onChange={this.handleChange}/>                       
                      </div>
  
                  </div>
                  <div className="col-md-6">
  
                      <div className="form-group">                    
                              <label >Telefono:</label>
                              <input type="text" className="form-control" id="tel_sedeedit"  name="tel_sedeedit" autoComplete='nope' value={form.tel_sedeedit} onChange={this.handleChange}/>                       
                      </div>
  
                  </div>
  
              </div> 

              <div className='row'>

                  <div className="col-md-6">                
                        <div className="form-group">                    
                            <label >Departamento:</label>
                            <select onChange={this.GetDepartamento_edit}   className="form-control" id="id_departamentoedit">
                               <option id="cb_departamento_edit" value={iddepa_edit}>{depa}</option>  
                               {this.state.Departamentos.map(item=>                              
                               <option key={item.id} value={item.id}> {item.nombre.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                               )}                                                                                  
                            </select>                                                                 
                        </div>                                             
                  </div>

                  <div className="col-md-6">                
                        <div className="form-group">                    
                            <label >Municipio:</label>
                            <select onChange={this.GetMunicipio_edit} onClick={this.GetMunicipio_edit}  className="form-control" id="id_municipioedit">
                               {/* <option id="cb_municipio_seleccione"  value="0">Seleccione...</option> */}
                               <option id="cb_municipio_edit"  value={idmuni_edit}>{muni}</option>  
                               {this.state.Municipios.map(item=>                              
                                <option key={item.id} value={item.id}> {(item.nombre).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                )}                                                                                  
                            </select>                                                                 
                        </div>                                             
                  </div>
                
                
              </div> 
       
        </div>

        <div className="modal-footer justify-content-between">        
        <button type="button" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.Editar_Sede()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar</button>
            <button type="button" className="btn btn-danger" data-dismiss="modal" >Cerrar</button>
        </div>

      </div>
      {/* /.modal-content */}
    </div>
    {/* /.modal-dialog */}
  </div>
  {/* /.modal */}


</div>


      
      
    )

  }
  
}



export default Sedes;