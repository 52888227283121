import React, {Component} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types'

//Componente para listar en select las Especialidades consumido en API

class Especialidad extends Component {   

    constructor()
    {
     super()
      this.state={        
        especialidad: [],
        id_especialidad:''
      }
    }

    componentDidMount(){    
    
        axios.get("https://medicallapi-test.azurewebsites.net/api/Specialties")
        .then((response) => {
          console.log(response);
          this.setState({especialidad: response.data})
        })
        .catch((error) => {
          console.log(error);
        })
      } 
      
      handleChange = (e) =>{
        this.setState({id_especialidad:e.target.value})
        //console.log(e.target.value)
        //var med=(e.target.value)
        //alert(med)
      } 
      

    render(){
      const {handleridespe} = this.props  
      const {id_especialidad} = this.state
        
   
     return (   
     
        <div >

                <select onChange={this.handleChange} onClick={()=>handleridespe(id_especialidad)} value={this.state.id_especialidad}  className="form-control" id="especialidad">
                    <option  value="0"> Seleccione...</option> ,
                    {this.state.especialidad.map(item=>                              
                    <option key={item.id} value={item.id}> {(item.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                    )}
                            
                </select>                    
         
        </div>    
      
    )

  }
  
}  

Especialidad.propTypes = {
  handleridespe:PropTypes.func.isRequired
}


export default Especialidad;