import React, {Component} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types'

//Componente para listar en select las Especialidades consumido en API

class TypeService extends Component {   

    constructor()
    {
     super()
      this.state={        
        servicios: [],
        id_servicio:''
      }
    }
    

    componentDidMount(){    
    
        axios.get('https://medicallapi-test.azurewebsites.net/api/TypeServices')
        .then((response) => {
          console.log(response);
          this.setState({servicios: response.data})
        })
        .catch((error) => {
          console.log(error);
        })
      } 
      
      handleChange = (e) =>{
        this.setState({id_servicio:e.target.value})
        //console.log(e.target.value)
        //var med=(e.target.value)
        //alert(med)
      } 
      

    render(){ 
      const {handleridservi} = this.props  
      const {id_servicio} = this.state
   
     return (   
     
        <div >
                <select onChange={this.handleChange} onClick={()=>handleridservi(id_servicio)} value={this.state.id_servicio} className="form-control" id="api_servicio">
                    <option  value="0"> Seleccione...</option> ,
                    {this.state.servicios.map(item=>                              
                    <option key={item.id} value={item.id}> {(item.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                    )}
                            
                </select>    
        </div>    
      
    )

  }

  
  
}  

TypeService.propTypes = {
  handleridservi:PropTypes.func.isRequired
}


export default TypeService;