import React from 'react';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import inicio from '../tools/images/inicio.png';
import sedes from '../tools/images/sedes.png';
import convenios from '../tools/images/convenios.png';
import horarios from '../tools/images/horarios.png';
import reportes from '../tools/images/reporte.png';
import img from '../tools/images/logo.png';
import ico from '../tools/images/medicall24logo.png';
import '../tools/css/Sidebar.css';


var Institution =sessionStorage.getItem('institution');
var Avatar =sessionStorage.getItem('avatar');



function Sidebar() {
    
    return (

  <aside className="main-sidebar sidebar-dark-primary elevation-4">
  {/* Brand Logo */}
  <a href="/home" className="brand-link" id="item_ips">
   {/* <img src={'https://medicallapi-test.azurewebsites.net/'+Avatar} alt="AdminLTE Logo" className="brand-image img-circle elevation-4" /> */}
    <span className="brand-text font-weight-light">{Institution}</span>
  </a>
  {/* Sidebar */}
  <div id="sidebar_personalizado" className="sidebar">
   
    {/* Sidebar Menu */}
    <nav className="mt-2">
      <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
        <li className="nav-item">
          <a href="/inicio" className="nav-link">
            <i className="nav-icon fas fa-home"></i>
            <p>Inicio              
            </p>
          </a>
        </li>
        <li className="nav-item">
          <a href="/home" className="nav-link">
            <i className="nav-icon fas fa-heartbeat "/>
            <p>
              Solicitudes por urgencia            
            </p>
          </a>
        </li>
        <li className="nav-item">
          <a href="/convenios" className="nav-link">
          <i className="nav-icon fas fa-stethoscope"></i>
            <p>
              Convenios           
            </p>
          </a>
        </li>
        <li className="nav-item">
          <a href="/citas" className="nav-link">
          <i className="nav-icon fas fa-file-contract"></i>
            <p>
              Asignación de citas           
            </p>
          </a>
        </li>
        <li className="nav-item">
          <a href="/agendas" className="nav-link">
          <i class="nav-icon far fa-calendar-alt"></i>
            <p>
              Agendas           
            </p>
          </a>
        </li>  
        
        <li className="nav-item has-treeview">
          <a href="#" className="nav-link">
            <i className="nav-icon fas fa-file-medical-alt" />
            <p>
              Historias clínicas  
              <i className="fas fa-angle-left right" />
            </p>
          </a>
          <ul className="nav nav-treeview">
            <li className="nav-item">
              <a href="/consultas_urgencias" className="nav-link">
              <i class="nav-icon fas fa-ambulance"></i>
                <p>Urgencias</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="/consultas_programadas" className="nav-link">
                <i className="nav-icon fas fa-clock" />
                <p>Ambulatorias</p>
              </a>
            </li>
            
          </ul>
        </li>

        <li className="nav-item">
          <a href="/informes" className="nav-link">
          <i class="nav-icon fas fa-chart-bar"></i>
            <p>
              Informes           
            </p>
          </a>
        </li>      
        
        
        <li className="nav-header">OPCIONES</li>        
        
        <li className="nav-item has-treeview">
          <a href="#" className="nav-link">
            <i className="nav-icon fas fa-cog" />
            <p>
              Configuración
              <i className="fas fa-angle-left right" />
            </p>
          </a>
          <ul className="nav nav-treeview">
          <li className="nav-item">
              <a href="/ajustes" className="nav-link">
                <i className="nav-icon fas fa-cogs" />
                <p>Ajustes</p>
              </a>
            </li>
            <li className="nav-item">
              <a href="/perfil" className="nav-link">
                <i className="nav-icon fas fa-user" />
                <p>Perfil</p>
              </a>
            </li>  
            <li className="nav-item">
              <a href="/publicaciones" className="nav-link">
                <i className="nav-icon far fa-newspaper" />
                <p>Publicaciones</p>
              </a>
            </li>        
            <li className="nav-item">
              <a href="/" onClick={()=> sessionStorage.clear()} className="nav-link">
              <i class="nav-icon fas fa-sign-out-alt"></i>
                <p>Cerrar sesión</p>                
              </a>
            </li>
          </ul>
        </li>      
              
       
        
        
       
       
        
      </ul>
    </nav>
    {/* /.sidebar-menu */}
  </div>
  {/* /.sidebar */}

</aside>








    )
}

export default Sidebar;