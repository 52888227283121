import React, {Component, useRef} from 'react';
import SignatureCanvas from 'react-signature-canvas'
import * as ReactBootStrap from 'react-bootstrap';
import '../tools/css/FirmaCanvas.css'
import '../tools/css/Membresia.css';
import '../tools/css/Paginacion.css';
import Departamentos from '../API/Departamentos';

import '../tools/css/Crear_cuenta.css';

import axios from 'axios';
import img from '../tools/images/logoblanco.png';
import iconofotoavatar from '../tools/images/upload.svg';
import fondo_cover from '../tools/images/imagen portada.jpg'
import fondo_avatar from '../tools/images/imagen logo.jpg'
import pasos from '../tools/images/pasos.svg'
import paso1 from '../tools/images/paso 1.svg'
import paso2 from '../tools/images/paso 2.svg'
import paso3 from '../tools/images/paso 3.svg'
import paso1compra from '../tools/images/paso1compra.svg'
import paso2compra from '../tools/images/paso2compra.svg'
import paso3compra from '../tools/images/paso3compra.svg'
import free from '../tools/images/MEMBRESIA FREE.png'
import platinium from '../tools/images/MEMBRESIA platinum.png'
import golden from '../tools/images/MEMBRESIA golden.png'
import diamond from '../tools/images/MEMBRESIA diamond.png'
import sumitech from '../tools/images/MEMBRESIA diamond.png'
import icono_aprobado from '../tools/images/aprobado icono.svg'
import card_credit from '../tools/images/credit-card.svg';
import pse from '../tools/images/pse.png';
import bancolombia from '../tools/images/bancolombia.png';
import Moment from 'react-moment';
import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';

import $ from 'jquery'; // <-to import jquery
import 'bootstrap';
import { FaTextHeight } from 'react-icons/fa';

const ApiURL="https://medicallapi-test.azurewebsites.net";
var institution_id = 0;
var queryString = window.location.search;
var urlParams = new URLSearchParams(queryString);
var nom_membresia = urlParams.get('membresia');
var tipo_pres = urlParams.get('user');
var id_membresia=1;
var metodo_pago='';
var tipo_persona='';
var img_membresia='';
var url='';
var url_pse='';
var rterminos='false';
var rpoliticas='false';



if(nom_membresia=='platinum'){

  id_membresia=2;
  img_membresia=platinium

  //alert(nom_membresia)

}else{
  if(nom_membresia=='golden'){

    id_membresia=3;
    img_membresia=golden
    //alert(nom_membresia)

  }else{
    if(nom_membresia=='diamond'){

      id_membresia=4;
      img_membresia=diamond
      //alert(nom_membresia)

    }else{

      id_membresia=2;
      img_membresia=free
      //alert("free")

    }
  }
}

var tipo_id='null';
var sexo='';
var mensaje='';
var msj_usuario='';
var msj_prestador='';
var avatar=[];
var avatar_length='Ningún archivo seleccionado';
var cover=[];
var files_view='';
var tipoid='null';
var cover_length='Ningún archivo seleccionado';
var files_viewp='';
var tipoidrl='null';
var clasepres='null';
var tiponatu='null';
var idnivel='0';
var Ese='null';
var coddepa='0';
var codmuni='0';
var codactiva='0';
var tipo_usuario="";

const body = {
  background: '#b6b6b6',
  height: 'auto'
};


class Pagos_sumitech extends Component  { 

  constructor(props)
    {
     super()
      this.state={
        show:false,
        Municipios:[],
        Detalle_membresia:[],
        Detalle_compra:[],
        Bancos:[],
        loading: false,
        loading_button: true,
        active_button: true,
        loading_button_activacuenta: true,
        loading_buttonrecovery_pass: true,
        active_buttonrecovery_pass: true,
        loading_buttonchange_pass: true,
        active_buttonchange_pass: true,
        active_buttonpaso1:true,
        nom_inst_active:true,
        button_activacuenta: true,
        checked:false,
        checkedT:false,
        active_button_terminos: true,
          form:{   
          tipo_id:'',
          dv:'',  
          id_inst:'',
          nom_inst:"",
          nombre1:"",
          nombre2:"",
          apellido1:"",
          apellido2:"",
          email_inst:'',
          clase_pres:'',
          cod_pres:'',
          tipo_naturaleza:'',
          nivel:'',
          ese:'no',
          cod_depa:'',
          cod_muni:'',
          dir_inst:'',
          tele_inst:'',
          tele_inst2:'',
          pag_web:'',
          tipo_idrl:'',
          id_rl:'',
          nom1_rl:"",
          nom2_rl:"",
          apell1_rl:"",
          apell2_rl:"",
          pass:'',
          pass_confirm:'',
          selectedFile:null,
          selectedFilep:null,
          cod_activa1:'',
          cod_activa2:'',
          cod_activa3:'',
          cod_activa4:'',
          cod_activa5:'',
          cod_activa6:'',
          municipio:'',
          email_cuenta:'',
          pass_cuenta:'',
          correo_recupera:'',
          cod_recuperacion:'',
          passr:'',
          pass_confirmr:'',
          llave_publi:"",
          llave_priv:"",
          tipoid_comprador_general:'null',
          id_comprador_general:'',
          nombres_comprador_general:'',
          apellidos_comprador_general:'',
          //correo_comprador_general:'',
          dir_comprador_general:'',
          tel_comprador_general:'',
          num_tc:'',
          tarjetahabiente:'',
          mes_tc:'',
          year_tc:'',
          cuotas_tc:'1',
          cod_tc:'',
          //tipodcto_pse:'CC',
          //numerodcto_pse:'',
          id_banco_pse:'',
          cel_nequi:'',
          btnpaso1: <button id="btn_save" className="btn btn-save" onClick={()=> this.Validar_Paso1()} > Continuar
                      <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" fill="currentColor" class="bi bi-arrow-right-square" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                      <path fill-rule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                      </svg>
                    </button>
          }
          
        }

    }

    

    state = { trimmedDataURL: null }

  sigPad = {}

  clear = () => {
    this.sigPad.clear()
  }

  trim = () => {
    this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas()
      .toDataURL('image/png') })
  }

    handleChange=async e=>{
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
         
         console.log(this.state.form);
    }

    Campo_numerico(){
      $(".camponum").keypress(function(tecla)
      {
        if(tecla.charCode<48 || tecla.charCode>57){
         
          return false;

        }
      })
    }


   Validarnumero(){
    $('.validanumericos').keypress(function(e) {
      if(isNaN(this.value + String.fromCharCode(e.charCode))) 
         return false;
      })
      .on("cut copy paste",function(e){
      e.preventDefault();
      });
   }


    GetTipoID = (id) =>{
      this.setState({tipo_id:id.target.value})
      console.log(id.target.value)
      tipoid=(id.target.value)
      this.label_razon_prestador();
     
    }

    GetClasepres = (cp) =>{
      this.setState({clase_pres:cp.target.value})
      console.log(cp.target.value)
      clasepres=(cp.target.value)
      //this.Cuota_mod();
     
    }

    GetTipoNatu = (tn) =>{

      Ese='null';

      this.setState({tipo_naturaleza:tn.target.value})
      console.log(tn.target.value)
      tiponatu=(tn.target.value)
      

      $("#ese").val($('#ese > option:first').val());

      if(tiponatu=='privada'){
        
        $('#sel_eseno').show(); 
        $('#sel_esesi').hide();     
      }else{

        $('#sel_eseno').show(); 
        $('#sel_esesi').show();

      }

      //this.Cuota_mod();
     
    }

    GetNivel = (n) =>{
      this.setState({nivel:n.target.value})
      console.log(n.target.value)
      idnivel=(n.target.value)  
     
    }

    GetESE = (e) =>{
      this.setState({ese:e.target.value})
      console.log(e.target.value)
      Ese=(e.target.value)    
     
    }

    GetMunicipio = (cm) =>{
      this.setState({cod_muni:cm.target.value})
      console.log(cm.target.value)
      codmuni=(cm.target.value)  
     
    }

    GetTipoIDLD = (tild) =>{
      this.setState({tipo_idrl:tild.target.value})
      console.log(tild.target.value)
      tipoidrl=(tild.target.value)  
     
    }


    GetCodActiva = (c) =>{      
      
      if(this.state.cod_activa1.length>0 && this.state.cod_activa2.length>0 && this.state.cod_activa3.length>0 && this.state.cod_activa4.length>0 && this.state.cod_activa5.length>0 && this.state.cod_activa6.length>0){
        this.setState({ button_activacuenta: this.state.button_activacuenta = true })
      }else{
        this.setState({ button_activacuenta: this.state.button_activacuenta = false })
      }
     
    }

    GetMetodopago = (mp) =>{
     
      //console.log(mp.target.value)
      metodo_pago=(mp.target.value)

      this.Formulario_metodopago();
     
    }

    GetTipopersona = (tp) =>{
     
      //console.log(tp.target.value)
      tipo_persona=(tp.target.value)    
     
    }

    GetTerminos = (t) =>{
      //this.setState({Racepta_autori:a.target.value})
      console.log(t.target.value)
      rterminos=(t.target.value)
      this.setState({ checked: this.state.checked = true })
      this.setState({ active_button_terminos: this.state.active_button_terminos = true })
     
    }

    GetTerminosstado = (t) =>{
      //this.setState({Racepta_autori:a.target.value})
      console.log(t.target.value)
      rterminos=(t.target.value)
     
      this.setState({ checked: this.state.checked = true })
      this.setState({ checkedT: this.state.checkedT = true })
      this.setState({ active_button_terminos: this.state.active_button_terminos = false })
     
    }

    GetTerminos_desmarcados = (td) =>{
      //this.setState({Racepta_autori:a.target.value})
      rterminos='false'
      this.setState({ checked: this.state.checked = false })
      this.setState({ checkedT: this.state.checkedT = false })
      this.setState({ active_button_terminos: this.state.active_button_terminos = false })
     
    }

    GetPoliticas = (p) =>{
      //this.setState({Racepta_autori:a.target.value})
      console.log(p.target.value)
      rpoliticas=(p.target.value)
     
    }

    Formulario_metodopago(){     
      if(metodo_pago==='CARD'){
  
        $('#tarjeta_credito').show();
        $('#pse').hide();
        $('#bancolombia').hide();
        $('#nequi').hide();


      }else{

        if(metodo_pago==='PSE'){

          $('#tarjeta_credito').hide();
          $('#pse').show();
          $('#bancolombia').hide();
          $('#nequi').hide();

          tipo_persona='';

          $("#tipo_persona_pse").val($('#tipo_persona_pse > option:first').val());


        }else{

          if(metodo_pago==='BANCOLOMBIA_TRANSFER'){

            $('#tarjeta_credito').hide();
            $('#pse').hide();           
            $('#nequi').hide();

            tipo_persona='PERSON';

            $("#tipo_persona_pse").val($('#tipo_persona_pse > option:first').val());
         

          }else{

            if(metodo_pago==='NEQUI'){

              $('#tarjeta_credito').hide();
              $('#pse').hide();
              $('#bancolombia').hide();
              $('#nequi').show();

            }
          }
        }
      }
      
    }

   

    _handleKeyDown = (e) => {
      if (e.key === 'Enter' || e.key=='Tab') {
       
        this.Buscar_Institucion()
        
      }
    }

    modal_activacuenta(){
      $('#modal-activacuenta').modal('show');
      $("#modal-activacuenta input").val("");
      $('#link_panelweb').hide();     
    }

    modal_img_perfil(){
      $('#modal_img_perfil').modal('show'); 
    }

    modal_img_portada(){
      $('#modal_img_portada').modal('show'); 
    }


    modal_recoverypass(){
    
      $('#modal-recoverypass').modal('show');
      $('#modal-confirmaridentidad').modal('hide');
    
      this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = true })
    }
    

    modal_llaveswompi()
    {     
      if(this.state.form.id_inst.length==0 || this.state.form.cod_pres.length==0 || this.state.form.nom_inst.length==0 || clasepres=='null' || this.state.form.email_inst.length==0 || tiponatu=='null' || idnivel=='0' || Ese=='null' || coddepa=='0' || codmuni=='0' || this.state.form.dir_inst.length==0 || this.state.form.tele_inst.length==0 || this.state.form.nom1_rlh=='' || this.state.form.apell1_rl=='' || tipoidrl=='null' || this.state.form.id_rl=='' || this.state.form.pass.length==0 || this.state.form.pass_confirm.length==0 ||  avatar.length==0 || cover.length==0 ){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        if(rterminos=='false'){

          store.addNotification({
            title: 'Atención',
            message: "Debe aceptar los términos y condiciones de uso",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
  
        }else{

          this.registrar();
          //$('#modal-llaveswompi').modal('show'); // <- to show modal crear sede 

        }       

      }
      
      
      
    }

    modal_terminos_condiciones(){
    
      $('#modal-terminos_condiciones').modal('show');

      this.setState({ active_button_terminos: this.state.active_button_terminos = true })
      
  
    }

    validar_codigo(){
      $('#mensaje_recupera').hide();
      $('#cambio_contraseña').show();
      $('#correo_recuperacion').hide();
      $('#btns_option').hide();     
    }


    componentDidMount(){

      
      $('#modal-compramembresia').modal('show');

      $('[data-toggle="tooltip"]').tooltip()
      this.detalle_membresia();
      $('#registro').hide();
      $('#label_nomprestador').hide(); 
      $('#link_panelweb').hide();      
      $('#btn_cerraractivacion').hide();
      $('#vista_imgs').hide();
      $('#vista_imgs1').hide();
      $('#vista_imgs2').hide();
      $('#rutas_logos').hide();
      $('#tabla_imgs1').hide();
      $('#tabla_imgs2').hide();
      $('#cambio_contraseña').hide();

      $('#tarjeta_credito').hide();
      $('#pse').hide();
      $('#bancolombia').hide();
      $('#nequi').hide();
      $('#compra_paso2').hide();
      $('#compra_paso3').hide();
      $('#btn_compra_paso2').hide();  
      $('#btn_compra_paso3').hide();  
      $('#img_paso2').hide();  

      $('#form_registro').hide();
      $('#paso1').show();
      $('#paso2').hide();
      $('#paso3').hide();

      if(tipo_pres=='instituciones'){
        $('#sel_cc').hide();
        $('#sel_ce').hide();
        $('#label_razonsocial').show();
        $('#label_nomprestador').hide();
        $('#sel_ips').show();
        $('#sel_independiente').hide();
        $('#sel_transporte').show();
      }else{

        if(tipo_pres=='independiente'){

          $('#sel_nit').hide();
          $('#label_razonsocial').hide();
          $('#label_nomprestador').show();
          $('#sel_ips').hide();
          $('#sel_independiente').show();
          $('#sel_transporte').hide();

        }
        
      }

    }


    validar_paso1_compra(){

      if(this.state.form.tipoid_comprador_general=='null' || this.state.form.id_comprador_general.length==0 || this.state.form.nombres_comprador_general.length==0 || this.state.form.apellidos_comprador_general.length==0 || this.state.form.dir_comprador_general.length==0 || this.state.form.tel_comprador_general.length==0 || this.state.form.tel_comprador_general.length==0){
  
        store.addNotification({
          title: 'Atención',
          message: "Faltan datos del comprador por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        $('#img_paso1').hide();
        $('#img_paso2').show();

        $('#compra_paso1').hide();
        $('#compra_paso2').show();
  
        $('#btn_compra_paso1').hide();
        $('#btn_compra_paso2').show();

      }

      
    
    }


    dropmodal_comprar_membresia() {

      $("#modal-compramembresia input").val("");
      $("#modal-compramembresia input[type='radio']").prop('checked', false).change();      
      $("#tipoid_comprador_general").val($('#tipoid_comprador_general > option:first').val());
      $("#mes_tc").val($('#mes_tc > option:first').val());
      $("#year_tc").val($('#year_tc > option:first').val());
      $("#cuotas_tc").val($('#cuotas_tc > option:first').val());
      $("#cuotas_tc").val($('#cuotas_tc > option:first').val());
      $("#tipo_persona_pse").val($('#tipo_persona_pse > option:first').val());
      $("#id_banco_pse").val($('#id_banco_pse > option:first').val());


      this.setState({
        form:{
          id_inst:this.state.form.id_inst,
          dv:this.state.form.dv,
          nom_inst:this.state.form.nom_inst,
          dir_inst:this.state.form.dir_inst,
          tele_inst:this.state.form.tele_inst,
          tipoid_comprador_general:'null',
          id_comprador_general:this.state.form.id,
          nombres_comprador_general:this.state.form.nom_inst,
          apellidos_comprador_general:'',
          email_inst:this.state.form.email_inst,
          dir_comprador_general:'',
          tel_comprador_general:'',    
          num_tc:'',
          tarjetahabiente:'',
          mes_tc:'',
          year_tc:'',
          cuotas_tc:'1',
          cod_tc:'',
          tipodcto_pse:'CC',
          numerodcto_pse:'',
          id_banco_pse:'',
          cel_nequi:''     
        }
      }) 
      
      this.Drop_formularios_pagos();
    }

    Drop_formularios_pagos(){

      $('#img_paso1').show();
      $('#img_paso2').hide();

      $('#compra_paso1').show();
      $('#compra_paso2').hide();

      $('#btn_compra_paso1').show();
      $('#btn_compra_paso2').hide();


      $('#tarjeta_credito').hide();
      $('#pse').hide();
      $('#bancolombia').hide();
      $('#nequi').hide();


    }

    atras(){

      $('#img_paso1').show();
      $('#img_paso2').hide();

      $('#compra_paso1').show();
      $('#compra_paso2').hide();
      $('#compra_paso2').hide();

      $('#btn_compra_paso1').show();
      $('#btn_compra_paso2').hide();
      $('#btn_compra_paso3').hide();
    
    }

    atras3(){

      $('#img_paso1').show();
      $('#img_paso2').hide();

      $('#compra_paso1').hide();
      $('#compra_paso2').show();
      $('#compra_paso3').hide();

      $('#btn_compra_paso1').hide();
      $('#btn_compra_paso2').show();
      $('#btn_compra_paso3').hide();
    
    }

    Validar_formularios_compra(){

      if(this.state.form.tipoid_comprador_general=='null' || this.state.form.id_comprador_general.length==0 || this.state.form.nombres_comprador_general.length==0 || this.state.form.apellidos_comprador_general.length==0 || this.state.form.dir_comprador_general.length==0 || this.state.form.tel_comprador_general.length==0 ){
  
        store.addNotification({
          title: 'Atención',
          message: "Faltan datos del comprador por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })
      
      }else{
        if(metodo_pago===''){
          store.addNotification({
            title: 'Atención',
            message: "Seleccione un método de pago",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
        }
      }
      if(metodo_pago==='CARD'){
  
        if(this.state.form.num_tc=='' || this.state.form.tarjetahabiente=='' || this.state.form.mes_tc==='MM' || this.state.form.year_tc==='YY' || this.state.form.cuotas_tc==='0' || this.state.form.cod_tc==''){

          store.addNotification({
            title: 'Atención',
            message: "Faltan datos de la tarjeta por ingresar",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
  
        }else{
          this.detalle_compraXmetodo();
        }
  
      }else{

        if(metodo_pago==='PSE'){

          if( tipo_persona==='' || this.state.form.id_banco_pse.length==0 ){

            store.addNotification({
              title: 'Atención',
              message: "Faltan datos de PSE por ingresar",
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })
    
          }else{
            this.detalle_compraXmetodo();
          }              

        }else{

          if(metodo_pago==='NEQUI'){

            if( this.state.form.cel_nequi.length==0 ){

              store.addNotification({
                title: 'Atención',
                message: "Faltan datos de Nequi por ingresar",
                type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
                container: 'top-right',                // where to position the notifications
                animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                dismiss: {
                  duration: 10000,
                  showIcon:true
                }
              })
      
            }else{
              this.detalle_compraXmetodo();
            }

          }else{
            if(metodo_pago==='BANCOLOMBIA_TRANSFER'){

              if( tipo_persona==='' ){

                store.addNotification({
                  title: 'Atención',
                  message: "Faltan datos de bancolombia por ingresar",
                  type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
                  container: 'top-right',                // where to position the notifications
                  animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                  animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                  dismiss: {
                    duration: 10000,
                    showIcon:true
                  }
                })
        
              }else{
                this.detalle_compraXmetodo();
              }

            }
          }
        }
        
      }

    }

    ComprarMembresia=async()=>{


      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })
  
      await axios.post(ApiURL+"/api/Payments/Membership",  
      {
        typeId: this.state.form.tipoid_comprador_general,
        identification: this.state.form.id_comprador_general,
        names: this.state.form.nombres_comprador_general,
        lastNames: this.state.form.apellidos_comprador_general,
        email: this.state.form.correo_comprador_general,
        address: this.state.form.dir_comprador_general,
        phone: this.state.form.tel_comprador_general,
        institutionId: 1,
        membershipId: id_membresia,
        paymentMethod: {
          type: metodo_pago,
          installments: this.state.form.cuotas_tc,
          card: {
            number: this.state.form.num_tc,
            cvc: this.state.form.cod_tc,
            expMonth: this.state.form.mes_tc,
            expYear: this.state.form.year_tc,
            cardHolder: this.state.form.tarjetahabiente
          },
          userType: tipo_persona,
          paymentDescription: "Compra Membresia",
          phoneNumber: this.state.form.cel_nequi,
          userLegalIdType: this.state.form.tipoid_comprador_general,
          userLegalId: this.state.form.id_comprador_general,
          financialInstitutionCode: this.state.form.id_banco_pse
        }
      } )          
  
      .then(response=>{
        console.log(response.data)
        mensaje  = response.data.message;
        url  = response.data.data.transactionId;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        console.log(url)     
        
        if(mensaje=='No fué posible generar la transacción.'){
  
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
  
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
  
        }else{
  
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
  
          //window.location.href=url;
        //window.open(url)
        $('#modal-compramembresia').modal('hide');
        this.setState({ loading_recurso: this.state.loading_recurso = false })
        $('#modal-loader').modal('show') // <- to show modal descarga video
  
        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })
  
        this.long_polling()
  
        }             
        
        
        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          store.addNotification({
            title: 'Atención',
            message: mensaje,
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
  
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
          this.setState({ loading_recurso: this.state.loading_recurso = false })
  
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
      }
      //console.log(error.config);
    
    })
  
  
    }

    long_polling=async()=>{

      if(metodo_pago==='CARD'){
  
          axios.get(ApiURL+"/api/Payments/GetStatusOrder/"+url)
          .then((response) => {
  
            if(response.status=='200'){
      
              console.log(response.data.data.status);
      
              url_pse=response.data.data.status
      
      
              store.addNotification({
                title: 'Muy bien',
                message: 'Su compra con tarjeta de crédito ha sido: '+url_pse,
                type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
                container: 'top-right',                // where to position the notifications
                animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                dismiss: {
                  duration: 10000,
                  showIcon:true
                }
              })
  
              $('#modal-compramembresia').modal('hide');
              this.setState({ loading_recurso: this.state.loading_recurso = true })
              $('#modal-loader').modal('hide') // <- to hide modal proceso compre
  
              this.dropmodal_comprar_membresia();
  
              window.location.href="/detalle_compra?id="+url; 
  
      
      
            }else{
                this.long_polling();  
        
              } 
          
       
          })
          .catch((error) => {
            console.log(error.message);
            //console.log(error.response.data.data);
  
            if(error.response.status=='417'){
  
              url_pse=error.response.data.message
      
      
              store.addNotification({
                title: 'Atención',
                message: url_pse,
                type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
                container: 'top-right',                // where to position the notifications
                animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                dismiss: {
                  duration: 10000,
                  showIcon:true
                }
              })
  
              $('#modal-compramembresia').modal('hide');
              this.setState({ loading_recurso: this.state.loading_recurso = true })
              $('#modal-loader').modal('hide') // <- to hide modal proceso compre
  
              this.dropmodal_comprar_membresia();
  
              window.location.href="/detalle_compra?id="+url;
  
            }else{
  
              this.long_polling()
  
            }
   
            
          })
  
      }else{
        
              if(metodo_pago==='PSE'){
        
                axios.get(ApiURL+"/api/Payments/GetStatusOrder/"+url)
                .then((response) => {
        
                  if(response.status=='200'){
            
                    console.log(response.data.data.payment_method.extra.async_payment_url);
            
                    url_pse=response.data.data.payment_method.extra.async_payment_url
  
                    this.setState({ loading_recurso: this.state.loading_recurso = true })
                    $('#modal-loader').modal('hide') // <- to hide modal proceso compre
            
            
                    window.location.href=url_pse;   
            
            
                  }else{
                    this.long_polling();  
            
                  }         
       
                })
                .catch((error) => {
                  //console.log(error.message);
                 
                  console.log(error.response.data.data.payment_method);
                  if(error.response.data.data.payment_method.extra==null){
  
                  this.long_polling();
        
                  }else{
        
                    let msj_pse=error.response.data.message
        
                    url_pse=error.response.data.data.payment_method.extra.async_payment_url
            
                    store.addNotification({
                      title: 'Atención',
                      message: msj_pse,
                      type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
                      container: 'top-right',                // where to position the notifications
                      animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                      animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                      dismiss: {
                        duration: 10000,
                        showIcon:true
                      }
                    })
        
                    $('#modal-compramembresia').modal('hide');
                    this.setState({ loading_recurso: this.state.loading_recurso = true })
                    $('#modal-loader').modal('hide') // <- to hide modal proceso compre
        
                    this.dropmodal_comprar_membresia();
      
                    window.location.href=url_pse;
                    //window.location.href="http://app.medicall24.com.co/detalle_compra?id="+url;
                    //this.long_polling()
        
                  }
        
                })
  
        }else{
  
            if(metodo_pago==='BANCOLOMBIA_TRANSFER'){
        
            axios.get(ApiURL+"/api/Payments/GetStatusOrder/"+url)
            .then((response) => {
    
              if(response.status=='200'){
        
                console.log(response.data.data.payment_method.extra.async_payment_url);
        
                url_pse=response.data.data.payment_method.extra.async_payment_url   
                
                this.setState({ loading_recurso: this.state.loading_recurso = true })
                $('#modal-loader').modal('hide') // <- to hide modal proceso compre
        
                window.location.href=url_pse;   
        
        
              }else{
                this.long_polling();  
        
              }         
   
            })
            .catch((error) => {
              //console.log(error.message);
              console.log(error.response.data.data.payment_method);
              if(error.response.data.data.payment_method.extra==null){
  
                this.long_polling();
    
              }else{
    
                let msj_pse=error.response.data.message
        
                url_pse=error.response.data.data.payment_method.extra.async_payment_url
        
                store.addNotification({
                  title: 'Atención',
                  message: msj_pse,
                  type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
                  container: 'top-right',                // where to position the notifications
                  animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                  animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                  dismiss: {
                    duration: 10000,
                    showIcon:true
                  }
                })
    
                $('#modal-compramembresia').modal('hide');
                this.setState({ loading_recurso: this.state.loading_recurso = true })
                $('#modal-loader').modal('hide') // <- to hide modal proceso compre
    
                this.dropmodal_comprar_membresia();
  
                window.location.href=url_pse;
                //window.location.href="http://app.medicall24.com.co/detalle_compra?id="+url;
                //this.long_polling()
    
              }
    
            })
  
          }else{
  
            if(metodo_pago==='NEQUI'){
        
              axios.get(ApiURL+"/api/Payments/GetStatusOrder/"+url)
              .then((response) => {
      
                if(response.status=='200'){
          
                  console.log(response.data.data.status);
      
                  url_pse=response.data.data.status
          
          
                  store.addNotification({
                    title: 'Muy bien',
                    message: 'Su compra con Nequi ha sido: '+url_pse,
                    type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
                    container: 'top-right',                // where to position the notifications
                    animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                    animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                    dismiss: {
                      duration: 10000,
                      showIcon:true
                    }
                  })
      
                  $('#modal-compramembresia').modal('hide');
                  this.setState({ loading_recurso: this.state.loading_recurso = true })
                  $('#modal-loader').modal('hide') // <- to hide modal proceso compre
      
                  this.dropmodal_comprar_membresia(); 
  
                  window.location.href="/detalle_compra?id="+url;
          
          
                }else{
                  this.long_polling();  
          
                }         
     
              })
              .catch((error) => {
                console.log(error.message);
                
                if(error.response.status=='417'){
  
                  url_pse=error.response.data.message
          
          
                  store.addNotification({
                    title: 'Atención',
                    message: url_pse,
                    type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
                    container: 'top-right',                // where to position the notifications
                    animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                    animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                    dismiss: {
                      duration: 10000,
                      showIcon:true
                    }
                  })
      
                  $('#modal-compramembresia').modal('hide');
                  this.setState({ loading_recurso: this.state.loading_recurso = true })
                  $('#modal-loader').modal('hide') // <- to hide modal proceso compre
      
                  this.dropmodal_comprar_membresia();
  
                  window.location.href="/detalle_compra?id="+url;
      
                }else{
      
                  this.long_polling()
      
                }
      
              })
    
            }
  
          }
  
  
        }
  
      } 
      
  
    }


    detalle_membresia(){

      this.setState({ loading: this.state.loading = false })
      
      axios.get(ApiURL+"/api/Products/GetById/"+id_membresia)
      .then((response) => {
        console.log(response.data);
        
        this.setState({Detalle_membresia: response.data}) 

        this.setState({ loading: this.state.loading = true })

        $('#form_registro').show();

      })
      .catch((error) => {
        console.log(error);
      })
      

    }

    detalle_compraXmetodo(){      
      
      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      axios.post(ApiURL+"/api/Payments/GetDetailPayment",
      {
        productID: id_membresia,
        PaymentMethod: metodo_pago        
      })
      .then((response) => {
        console.log(response.data);
        
        this.setState({Detalle_compra: response.data}) 

      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })

        $('#compra_paso2').hide();
        $('#compra_paso3').show();

        $('#btn_compra_paso2').hide();
        $('#btn_compra_paso3').show();


      })
      .catch((error) => {
        console.log(error);

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })
      })
      

    }


    paso1(){

      if(tipoid=='CC' || tipoid=='CE' || tipoid=='null'){

        if(tipoid=='null' || this.state.form.id_inst.length==0 || this.state.form.dv.length==0 || this.state.form.cod_pres.length==0 || this.state.form.nombre1.length==0 || this.state.form.apellido1.length==0 || clasepres=='null' || this.state.form.email_inst.length==0 ){

          store.addNotification({
            title: 'Atención',
            message: "Faltan datos por ingresar",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
  
        }else{
  
          $('#paso1').hide();
          $('#paso2').show();
          $('#paso3').hide();
  
        }

      }else{

        if(tipoid=='NIT' || tipoid=='null'){

          if(tipoid=='null' || this.state.form.id_inst.length==0 || this.state.form.dv.length==0 || this.state.form.cod_pres.length==0 || this.state.form.nom_inst.length==0 || clasepres=='null' || this.state.form.email_inst.length==0 ){

            store.addNotification({
              title: 'Atención',
              message: "Faltan datos por ingresar",
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })
    
          }else{
    
            $('#paso1').hide();
            $('#paso2').show();
            $('#paso3').hide();
    
          }

        }

      }      

    }

    validar_paso1(){

      if(tipoid=='CC' || tipoid=='CE'){

        this.setState({
          form:{
            id_inst:this.state.form.id_inst,
            dv:this.state.form.dv,
            nom_inst:this.state.form.nombre1 +' '+ this.state.form.nombre2   + ' '+ this.state.form.apellido1 + ' '+ this.state.form.apellido2,   
            nombre1:this.state.form.nombre1,  
            nombre2: this.state.form.nombre2,
            apellido1:this.state.form.apellido1,
            apellido2:this.state.form.apellido2, 
            pass:this.state.form.pass, 
            pass_confirm:this.state.form.pass,
            cod_pres:this.state.form.cod_pres,     
            email_inst:this.state.form.email_inst,
            pag_web:this.state.form.pag_web,
            dir_inst:this.state.form.dir_inst,
            tele_inst:this.state.form.tele_inst,
            tele_inst2:this.state.form.tele_inst2,
            id_rl:this.state.form.id_rl,
            nom1_rl:this.state.form.nom1_rl, 
            nom2_rl:this.state.form.nom2_rl,
            apell1_rl:this.state.form.apell1_rl,
            apell2_rl:this.state.form.apell2_rl,             
            pass_cuenta:'',
            cod_activa1:this.state.form.cod_activa1,
            cod_activa2:this.state.form.cod_activa2,
            cod_activa3:this.state.form.cod_activa3,
            cod_activa4:this.state.form.cod_activa4,
            cod_activa5:this.state.form.cod_activa5,
            cod_activa6:this.state.form.cod_activa6,
            llave_publi:this.state.form.llave_publi,
            llave_priv:this.state.form.llave_priv,
            tipoid_comprador_general:'CC',
            id_comprador_general:'',
            nombres_comprador_general:'',
            apellidos_comprador_general:'',
            correo_comprador_general:'',
            dir_comprador_general:'',
            tel_comprador_general:'',    
            num_tc:'',
            tarjetahabiente:'',
            mes_tc:'',
            year_tc:'',
            cuotas_tc:'1',
            cod_tc:'',
            tipodcto_pse:'CC',
            numerodcto_pse:'',
            id_banco_pse:'',
            cel_nequi:''
            
          }
        })


      }else{

        if(tipoid=='NIT'){

          this.setState({
            form:{
              id_inst:this.state.form.id_inst,
              dv:this.state.form.dv,
              nom_inst:this.state.form.nom_inst,   
              nombre1:this.state.form.nom_inst,  
              nombre2: this.state.form.nombre2,
              apellido1:this.state.form.apellido1,
              apellido2:this.state.form.apellido2, 
              pass:this.state.form.pass, 
              pass_confirm:this.state.form.pass_confirm,
              cod_pres:this.state.form.cod_pres,     
              email_inst:this.state.form.email_inst,
              pag_web:this.state.form.pag_web,
              dir_inst:this.state.form.dir_inst,
              tele_inst:this.state.form.tele_inst,
              tele_inst2:this.state.form.tele_inst2,
              id_rl:this.state.form.id_rl,
              nom1_rl:this.state.form.nom1_rl, 
              nom2_rl:this.state.form.nom2_rl,
              apell1_rl:this.state.form.apell1_rl,
              apell2_rl:this.state.form.apell2_rl,              
              pass_cuenta:'',
              cod_activa1:this.state.form.cod_activa1,
              cod_activa2:this.state.form.cod_activa2,
              cod_activa3:this.state.form.cod_activa3,
              cod_activa4:this.state.form.cod_activa4,
              cod_activa5:this.state.form.cod_activa5,
              cod_activa6:this.state.form.cod_activa6,
              llave_publi:this.state.form.llave_publi,
              llave_priv:this.state.form.llave_priv,
              tipoid_comprador_general:'CC',
              id_comprador_general:'',
              nombres_comprador_general:'',
              apellidos_comprador_general:'',
              correo_comprador_general:'',
              dir_comprador_general:'',
              tel_comprador_general:'',    
              num_tc:'',
              tarjetahabiente:'',
              mes_tc:'',
              year_tc:'',
              cuotas_tc:'1',
              cod_tc:'',
              tipodcto_pse:'CC',
              numerodcto_pse:'',
              id_banco_pse:'',
              cel_nequi:''
              
            }
          })

        }
         
      }      

    }

    validar_paso2(){

      if(tiponatu=='null' || idnivel=='0' || Ese=='null' || coddepa=='0' || codmuni=='0' || this.state.form.dir_inst=='' || this.state.form.tele_inst==''){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        $('#paso1').hide();
        $('#paso2').hide();
        $('#paso3').show();

      }

      
     
    }

    validar_atras2(){

      $('#paso1').show();
      $('#paso2').hide();
      $('#paso3').hide();
     
    }

    validar_atras3(){

      $('#paso1').hide();
      $('#paso2').show();
      $('#paso3').hide();
     
    }

    modal_comprar_membresia(){

      axios.get(ApiURL+"/api/Payments/FinancialInstitutions")
      .then((response) => {
        //console.log(response);
        this.setState({Bancos: response.data.data})
      $('#modal-compramembresia').modal('show'); 

      })
      .catch((error) => {
        console.log(error);
      })
      

    }


    label_razon_prestador(){
      
      clasepres='null'
      if(tipoid=='NIT'){
        $('#label_razonsocial').show();
        $('#label_nomprestador').hide();
        $('#sel_ips').show();
        $('#sel_independiente').hide();
        $('#sel_transporte').show();
      }else{
        if(tipoid=='CC' || tipoid=='CE'){

          $('#label_razonsocial').hide();
          $('#label_nomprestador').show();
          $('#sel_ips').hide();
          $('#sel_independiente').show();
          $('#sel_transporte').hide();
        }
        
      }
    }


    mostrarPassword(){
      var cambio = document.getElementById("txtPassword");
		if(cambio.type == "password"){
			cambio.type = "text";
			$('.icon').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
		}else{
			cambio.type = "password";
			$('.icon').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
		}
    this.mostrarPassword_confirm();
    }


    mostrarPassword_confirm(){
      var cambio_confirm = document.getElementById("txtPassword_confirm");
		if(cambio_confirm.type == "password"){
			cambio_confirm.type = "text";
			$('.icon').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
		}else{
			cambio_confirm.type = "password";
			$('.icon').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
		}
    }


    Buscar_Institucion=async()=>{

      if(tipoid=='null' || this.state.form.id_inst.length==0){

        store.addNotification({
          title: 'Atención',
          message: "Los campos tipo ID o Identificación/NIT no han sido diligenciados",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        if(this.state.form.email_inst.length > 0){

        }else{       

        axios.post(ApiURL+"/api/Institutions/GetInstitution",

        {
        identification: this.state.form.id_inst,
        typeId: tipoid        
        })  
        
      .then(response=>{
        console.log(response.data)
        if(response.data.providerClass=='ips'){
          tipo_usuario='Institución IPS'
        }else{
          if(response.data.providerClass=='profesional independiente'){
            tipo_usuario='Profesional Independiente'
          }
        }
        
        msj_prestador="El usuario ya se encuentra registrado como " + tipo_usuario+ ", inicia sesión y prueba las ventajas de usar Medicall24."
       
        this.setState({
          form:{
            pass_cuenta:this.state.form.pass_cuenta,
            email_cuenta:this.state.form.email_cuenta,
            id_inst:this.state.form.id_inst,
            dv:this.state.form.dv,
            cod_pres:this.state.form.cod_pres,
            nombre1:this.state.form.nombre1,
            apellido1:this.state.form.apellido1,
            nom_inst:this.state.form.nom_inst,
            email_inst:this.state.form.email_inst               
          }
        })

        $('#modal-verificaprestador').modal('show');
        this.setState({ active_buttonpaso1: this.state.active_buttonpaso1 = false })
  
      })
      .catch(error=>{

        this.setState({ active_buttonpaso1: this.state.active_buttonpaso1 = true })

        this.Buscar_Usuario();
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
      }
      //console.log(error.config);
    
    })

       }

      }
  
     }



    Buscar_Usuario=async()=>{

      if(tipoid=='null' || this.state.form.id_inst.length==0){

        store.addNotification({
          title: 'Atención',
          message: "Los campos tipo de ID y/o identificación están vacios!!!",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        if(this.state.form.email_inst.length > 0){

        }else{
       

        axios.post(ApiURL+"/api/Users/GetUser",

        {
        identification: this.state.form.id_inst,
        typeId: tipoid        
        })  

      .then(response=>{
        console.log(response.data)
        if(response.data.rol=='Professional'){
          tipo_usuario='Profesional'
        }else{
          if(response.data.rol=='Patient'){
            tipo_usuario='Paciente'
          }
        }
        
        msj_usuario="Ya eres un usuario de Medicall24, si deseas registrarte como un Prestador de Salud (Profesional Independiente), ingresa la misma contraseña que utilizaste para iniciar sesión en la aplicación."

        this.setState({
          form:{
            pass_cuenta:this.state.form.pass_cuenta,
            email_cuenta:response.data.user.email,
            id_inst:this.state.form.id_inst,
            dv:this.state.form.dv,
            cod_pres:this.state.form.cod_pres,
            nombre1:this.state.form.nombre1,
            apellido1:this.state.form.apellido1,
            nom_inst:this.state.form.nom_inst,
            email_inst:this.state.form.email_inst               
          }
        })
       

        $('#modal-confirmaridentidad').modal('show');

        $('#modal-recoverypass').modal('hide');
        $('#mensaje_recupera').show();
        $('#cambio_contraseña').hide();
        $('#correo_recuperacion').show();
        $('#btns_option').show();  

    
        
        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message
          
          
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
      }
      //console.log(error.config);
    
    })

       }

      }
  
     } 


     Buscar_Usuario_pass=async()=>{

      if (this.state.form.pass_cuenta.length==0){

        store.addNotification({
          title: 'Atención',
          message: "Ingrese una contraseña",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = false })
        this.setState({ button_activacuenta: this.state.button_activacuenta = false })

        axios.post(ApiURL+"/api/Auth/SimpleLogin",

        {
        email: this.state.form.email_cuenta,
        password: this.state.form.pass_cuenta
        
        })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  =response.data.user.id +' - '+ response.data.user.name1;
        //alert(mensaje);

      

          this.setState({
            form:{
              id_inst:response.data.user.identification,
              dv:this.state.form.dv,
              nom_inst:response.data.user.name1 ? response.data.user.name1 : '' +' '+ response.data.user.name2 ? response.data.user.name2 : ''   + ' '+ response.data.user.lastname1 ? response.data.user.lastname1 : '' + ' '+ response.data.user.lastname2 ? response.data.user.lastname2 : '',   
              nombre1:response.data.user.name1 ? (response.data.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',  
              nombre2: response.data.user.name2 ? (response.data.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
              apellido1:response.data.user.lastname1 ? (response.data.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '',
              apellido2:response.data.user.lastname2 ? (response.data.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '', 
              pass:'00000', 
              pass_confirm:'00000',
              cod_pres:this.state.form.cod_pres,     
              email_inst:response.data.user.email,
              pag_web:this.state.form.pag_web,
              dir_inst:'',
              tele_inst:this.state.form.tele_inst1,
              tele_inst2:this.state.form.tele_inst2,
              id_rl:this.state.form.id_rl,
              nom1_rl:this.state.form.nom1_rl, 
              nom2_rl:this.state.form.nom2_rl,
              apell1_rl:this.state.form.apell1_rl,
              apell2_rl:this.state.form.apell2_rl,              
              pass_cuenta:'',
              llave_publi:this.state.form.llave_publi,
              llave_priv:this.state.form.llave_priv,
              
            }
          })

        
          $('#show_password').hide();
        
        
        
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
        //alert(this.state.form.patientid)
               
        //window.location.href="/home";
      store.addNotification({
          title: 'Muy bien',
          message: "Ahora ingresa los datos solicitados en el formulario de registro para finalizar.",
          type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration:10000,
            showIcon:true
          }
        })



      
        this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = true })
        this.setState({ button_activacuenta: this.state.button_activacuenta = true })
        this.setState({ nom_inst_active: this.state.nom_inst_active = false })

        $('#modal-confirmaridentidad').modal('hide');
        
        
        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          mensaje=error.response.data.message

          store.addNotification({
            title: 'Atención',
            message: "La contraseña ingresa no es correcta, si la olvidaste, ingresa en la opción de recuperación de constraseña.",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

         

          this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = true })
          this.setState({ button_activacuenta: this.state.button_activacuenta = true })
          
          
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = true })
          this.setState({ button_activacuenta: this.state.button_activacuenta = true })
          console.log(error.request);
      } else {
          // Something happened in setting up the request and triggered an Error
          this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = true })
          this.setState({ button_activacuenta: this.state.button_activacuenta = true })
          console.log('Error', error.message);
      }
      //console.log(error.config);
    
    })

      }
  
     }
     
     
    registrar(){    

            //this.saveuser();
            this.saveinstitution();
    
    }


     saveuser=async()=>{

      if(tipo_id=='null' || this.state.id_user==''){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ loading_button: this.state.active_button = false })
        await axios.post(ApiURL+"/api/Users/Create",
  
          {
          identification: this.state.form.id_user,
          typeId: tipo_id,
          password: this.state.form.pass,
          name1: this.state.form.nom1,
          name2: this.state.form.nom2,
          lastName1: this.state.form.apell1,
          lastName2: this.state.form.apell2,
          gender:sexo,
          email:this.state.form.email,
          phone:this.state.form.telefono
          })  
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ loading_button: this.state.active_button = true })
          
          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ loading_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })

      }      
  
     }


     saveinstitution=async()=>{

      if(this.state.form.id_inst.length==0 || this.state.form.cod_pres.length==0 || this.state.form.nom_inst.length==0 || clasepres=='null' || this.state.form.email_inst.length==0 || tiponatu=='null' || idnivel=='0' || Ese=='null' || coddepa=='0' || codmuni=='0' || this.state.form.dir_inst.length==0 || this.state.form.tele_inst.length==0 || this.state.form.nom1_rlh=='' || this.state.form.apell1_rl=='' || tipoidrl=='null' || this.state.form.id_rl=='' || this.state.form.pass.length==0 || this.state.form.pass_confirm.length==0 ||  avatar.length==0 || cover.length==0 ){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        if(this.state.form.pass!==this.state.form.pass_confirm){

          store.addNotification({
            title: 'Atención',
            message: 'Las contraseñas no coinciden',
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })

        }else{
        
        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        let formData = new FormData();
        formData.append('typeId', tipoid)
        formData.append('nit', this.state.form.id_inst)
        formData.append('name', this.state.form.nom_inst)
        formData.append('name1', this.state.form.nombre1)
        formData.append('name2', this.state.form.nombre2)
        formData.append('lastname1', this.state.form.apellido1)
        formData.append('lastname2', this.state.form.apellido2)
        formData.append('Password', this.state.form.pass)
        formData.append('providerCode', this.state.form.cod_pres)
        formData.append('email', this.state.form.email_inst)  
        formData.append('web', this.state.form.pag_web)      
        formData.append('address', this.state.form.dir_inst)
        formData.append('phone1', this.state.form.tele_inst)
        formData.append('phone2', this.state.form.tele_inst2)
        formData.append('departmentId', coddepa)
        formData.append('municipalityId', codmuni)
        formData.append('ProviderClass', clasepres)
        formData.append('LegalNature', tiponatu)
        formData.append('Ese', Ese)
        formData.append('AtentionLevel', idnivel)
        formData.append('TipeIdRepresentative', tipoidrl)
        formData.append('IdRepresentative', this.state.form.id_rl)
        formData.append('NameRepresentative1', this.state.form.nom1_rl)
        formData.append('NameRepresentative2', this.state.form.nom2_rl)
        formData.append('LastNameRepresentative1', this.state.form.apell1_rl)
        formData.append('LastNameRepresentative2', this.state.form.apell2_rl)
        formData.append('PublicKey', "")
        formData.append('PrivateKey', "")
        formData.append('avatar', avatar)
        formData.append('cover', cover)

        await axios.post(ApiURL+"/api/Institutions/Save", formData,{

          headers: {
            'Content-Type': 'multipart/form-data'            
          }

        })  
        .then(response=>{
          //console.log(response)
          mensaje  = response.data.message;
          institution_id = response.data.institution.id
          
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })

          $('#modal-llaveswompi').modal('hide');

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

          //$('#alert-activacuenta').html('<div class="alert alert-success" role="alert">'+mensaje+'</div>');
          if(response.status=='200'){

            if(id_membresia==1){

              $('#modal-creaprestador').modal('show');

            }else{
              $('#form_registro').hide();
              $('#registro').show();
            }           
            

          }else{
            if(response.status=='201' && response.data.emailValidated==true){

              if(id_membresia==1){

                $('#modal-creaprestador').modal('show');
  
              }else{
                $('#form_registro').hide();
                $('#registro').show();
              }              

            }else{
              
              if(response.status=='201' && response.data.emailValidated==false){             

                  this.modal_activacuenta();               

              }
            }
          }
          
          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ loading_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
      
      })

       }

      }      
  
     }


     Activar_cuenta=async()=>{

      if(this.state.form.cod_activa1=='' || this.state.form.cod_activa2=='' || this.state.form.cod_activa3=='' || this.state.form.cod_activa4=='' || this.state.form.cod_activa5=='' || this.state.form.cod_activa6==''){

        store.addNotification({
          title: 'Atención',
          message: "Ingrese el codigo de activación enviado al correo electrónico: "+this.state.form.email_inst,
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = false })
        this.setState({ button_activacuenta: this.state.button_activacuenta = false })
        await axios.post(ApiURL+"/api/Users/ActivateAccount",
  
          {
            type: 'Activation',
            validationCode: this.state.form.cod_activa1+this.state.form.cod_activa2+this.state.form.cod_activa3+this.state.form.cod_activa4+this.state.form.cod_activa5+this.state.form.cod_activa6        
          })  

        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })

          this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = true })
          this.setState({ button_activacuenta: this.state.button_activacuenta = true })

 

          if(id_membresia==1){

            $('#btn_activacuenta').hide();
            $('#link_panelweb').show();

          }else{
            $('#modal-activacuenta').modal('hide');
            $('#btn_activacuenta').hide();
            $('#link_panelweb').hide();      
            $('#btn_cerraractivacion').show();

            $('#form_registro').hide();
            $('#registro').show();
          }

          //$('#alert-activacuenta').html('<div class="alert alert-success" role="alert">'+mensaje+'</div>');
          
          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

          this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = true })
          this.setState({ button_activacuenta: this.state.button_activacuenta = true })

          //$('#alert-activacuenta').html('<div class="alert alert-danger" role="alert">'+mensaje+'</div>');

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = true })
            this.setState({ button_activacuenta: this.state.button_activacuenta = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button_activacuenta: this.state.loading_button_activacuenta = true })
            this.setState({ button_activacuenta: this.state.button_activacuenta = true })
        }
        //console.log(error.config);
      
      })

      }      
  
     }



     Recuperar_cuenta=async()=>{

      if(!/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.state.form.correo_recupera)){
  
        store.addNotification({
          title: 'Atención',
          message: "La dirección de correo electrónico ingresada contiene caracteres no válidos.",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })
  
      }else{
        this.setState({ loading_buttonrecovery_pass: this.state.loading_buttonrecovery_pass = false })
        this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = false })
        await axios.post(ApiURL+"/api/Auth/RecoveryPassword",
  
          {         
            email: this.state.form.correo_recupera          
          })  
  
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })
  
          this.setState({ loading_buttonrecovery_pass: this.state.loading_buttonrecovery_pass = true })
          this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = false })
  
          //$('#alert-recoverypass').html('<div class="alert alert-success" role="alert">'+mensaje+'</div>');
          
          $('#mensaje_recupera').hide();
          $('#cambio_contraseña').show();
          $('#correo_recuperacion').hide();
          $('#btns_option').hide();
          
          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })
  
            this.setState({ loading_buttonrecovery_pass: this.state.loading_buttonrecovery_pass = true })
            this.setState({ active_buttonrecovery_pass: this.state.active_buttonrecovery_pass = true })
  
          $('#alert-recoverypass').html('<div class="alert alert-danger" role="alert">'+mensaje+'</div>');
  
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })
  
      }      
  
     }


     Cambiar_Contraseña=async()=>{

      if(this.state.form.cod_recuperacion.length==0 || this.state.form.passr.length==0 || this.state.form.pass_confirmr.length==0 ){
  
        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })
  
      }else{
        if(this.state.form.passr!==this.state.form.pass_confirmr){
  
          store.addNotification({
            title: 'Atención',
            message: 'La contraseña ingresada no es correcta',
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })
  
        }else{
        
        this.setState({ loading_buttonchange_pass: this.state.loading_buttonchange_pass = false })
        this.setState({ active_buttonchange_pass: this.state.active_buttonchange_pass = false })    
  
        await axios.post(ApiURL+"/api/Users/ChangePassword", 
  
          {
            validationCode:this.state.form.cod_recuperacion,         
            password: this.state.form.passr          
          })
   
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
        store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
            duration: 10000,
            showIcon:true
            }
          })
  
          this.setState({ loading_buttonchange_pass: this.state.loading_buttonchange_pass = true })
          this.setState({ active_buttonchange_pass: this.state.active_buttonchange_pass = true })
  
          $('#modal-recoverypass').modal('hide');
          $('#mensaje_recupera').show();
          $('#cambio_contraseña').hide();
          $('#correo_recuperacion').show();
          $('#btns_option').show();   
    
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })
  
          this.setState({ loading_buttonchange_pass: this.state.loading_buttonchange_pass = true })
          this.setState({ active_buttonchange_pass: this.state.active_buttonchange_pass = true })
  
          //$('#alert-recoverypass').html('<div class="alert alert-danger" role="alert">'+mensaje+'</div>');
  
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })
  
       }
  
      }      
  
     }


     Validar_Paso1(){

      if(tipoid.length<=0 || this.state.form.id_inst.length<=0 || this.state.form.dv.length<=0 || this.state.form.nom_inst<=0 || this.state.form.email_inst<=0 ){

        store.addNotification({
          title: 'Atención',
          message: 'Faltan datos por ingresar',
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
          duration: 10000,
          showIcon:true
          }
        })

      }else{
        
        if(!/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(this.state.form.email_inst)){

          store.addNotification({
            title: 'Atención',
            message: "La dirección de correo electrónico ingresada contiene caracteres no válidos.",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
    
        }else{
          
          this.setState({ btnpaso1: this.state.form.btnpaso1 = <button id="btn_save"  data-toggle="pill" href="#custom-tabs-three-profile" aria-controls="custom-tabs-three-profile"  className="btn btn-save" onClick={()=> this.Validar_Paso1()} > Continuar
                           <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" fill="currentColor" class="bi bi-arrow-right-square" viewBox="0 0 16 16">
                           <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                           <path fill-rule="evenodd" d="M4 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5A.5.5 0 0 0 4 8z"/>
                          </svg>
                        </button> })
        }
      }

     }   
     
     Cargar_Municipios(coddepa){    
      
      axios.get(ApiURL+"/api/Departments/ListMunicipalties/"+coddepa)
      .then((response) => {
        console.log(response);
        this.setState({Municipios: response.data})
        
      })
      .catch((error) => {
        console.log(error);
      })
    }


     handleridtipoid = (t) => {
      console.log(t)
      tipo_id=(t)
      //alert(tipo_id)
    }


    GetImg = (i) =>{      
      this.setState({selectedFile:i.target.files})
      avatar=i.target.files[0]
      files_view=URL.createObjectURL(i.target.files[0]);
      avatar_length="Ha seleccionado "+(i.target.files.length)+" archivo" 
      $('#vista_imgs').show();  
      $('#vista_imgs1').show(); 
      $('#quitar_avatar').hide();
      $('#tabla_imgs2').show();     
      //this.modal_img_perfil() 
      //alert(avatar)
    }

    GetImg_portada = (ip) =>{     
      this.setState({selectedFilep:ip.target.files})
      cover=ip.target.files[0]
      files_viewp=URL.createObjectURL(ip.target.files[0]);
      cover_length="Ha seleccionado "+(ip.target.files.length)+" archivo" 
      $('#vista_imgs').show();  
      $('#vista_imgs2').show(); 
      $('#quitar_portada').hide();
      $('#tabla_imgs1').show();  
      //this.modal_img_portada() 
      //alert(cover)
    }

   

    handleriddepartamento = (d) => {
      console.log(d)
      coddepa=(d)
      //alert(id_profe)
      this.Cargar_Municipios(coddepa)
    }

    


   render(){  
      const {form} = this.state;

      return(      
        
       
        <div style={body} className=""> 

              <nav className="navbar navbar-expand navbar-white navbar-light" id="nav2">       
                 
                      <a id="img_center"  href="https://medicall24.com.co/" className="navbar-brand"> <img  src={img} alt="logo"  width= "230"/> </a>
                      
                      <a id="btn-tengocuenta" href="/" target="_blank"> Iniciar sesión </a>                                            
    
                      {/* <button id="btn_crear" className="btn btn-edit" onClick={()=>{this.modal_llaveswompi()}}> Crear
                        
                      </button>  */}
              </nav>
         
               

              <div className="modal fade" id="modal-llaveswompi" data-backdrop="static">

                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Llaves asociadas a Wompi</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
  
                          <div className="col-md-12">
                                  <div className="form-group"> 

                                    <label >Llave pública</label>

                                    <div className="input-group">
                                        <input type="text" maxLength="50" className="form-control" name="llave_publi" onChange={this.handleChange} />
                                        <div className="input-group-append">
                                             <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde a la llave pública asignada por Wompi." > <span className="fas fa-info-circle"></span> </button>
                                        </div>                                                                          
                                        
                                    </div>                                                              
                                                          
                                 </div>                 
                          </div>

                          <div className="col-md-12">
                                  <div className="form-group"> 

                                    <label >Llave privada</label>

                                    <div className="input-group">
                                        <input type="text" maxLength="50" className="form-control" name="llave_priv"  onChange={this.handleChange} />
                                        <div className="input-group-append">
                                             <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde a la llave privada asignada por Wompi." > <span className="fas fa-info-circle"></span> </button>
                                        </div>                                                                          
                                        
                                    </div>                                                              
                                                          
                                 </div>                 
                          </div>
                                 
                    </div>
          
                          <div className="modal-footer justify-content-between">        
                             <button type="button" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.registrar()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}Registrar</button>
                             <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                          </div>
                  </div>                
                </div>
            
              </div>       
              

              <div className="modal fade" id="modal-activacuenta" data-backdrop="static">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Código enviado</b></h4>
                      <a type="button" className="close" href="/" aria-label="Close">
                       <span aria-hidden="true">&times;</span>
                      </a>
                    </div>
    
                    <div className="modal-body">

                          <span id="alert-activacuenta"> </span>
                      
                          <div className="row justify-content-md-center"> <br/>

                              <div className="col-md-10">

                                 Hemos enviado el código de activación de la cuenta al correo <strong id="correo_activacion"> {this.state.form.email_inst} </strong> 
                                 ingrésalo para finalizar el registro.

                              </div>
                                                  
                          
                          </div>

                          <br/>

                          <div className="row justify-content-md-center"> <br/>                                      
                              
                                 
                                          <input type="text" className="form-control" maxLength="1" name="cod_activa1" id="cod_activa" onChange={this.handleChange}/>
                                          <input type="text" className="form-control" maxLength="1" name="cod_activa2" id="cod_activa" onChange={this.handleChange}/>    
                                          <input type="text" className="form-control" maxLength="1" name="cod_activa3" id="cod_activa" onChange={this.handleChange}/>
                                          <input type="text" className="form-control" maxLength="1" name="cod_activa4" id="cod_activa" onChange={this.handleChange}/>
                                          <input type="text" className="form-control" maxLength="1" name="cod_activa5" id="cod_activa" onChange={this.handleChange}/>
                                          <input type="text" className="form-control" maxLength="1" name="cod_activa6" id="cod_activa" onChange={this.handleChange}/>                                              
                                         
                          
                          </div> 

                          <br/>

                          <div className="row justify-content-md-center" id="btn_activacuenta"> <br/>

                              <div className="col-md-10">                          
                                              
                                    <button id="btn-registrar" type="button" className="btn btn-save" disabled={!this.state.button_activacuenta} onClick={()=> this.Activar_cuenta()}> {this.state.loading_button_activacuenta?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Activar cuenta</button>
                               
                              </div>  

                          </div>

                          

                          <div className="col-md-12 text-center" id="link_panelweb">
                            <a type="button" id="btn-ttc" className="btn" href="/">
                                                   <svg width="22" height="22" viewBox="0 0 16 16" className="bi bi-box-arrow-in-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                   <path fillRule="evenodd" d="M8.146 11.354a.5.5 0 0 1 0-.708L10.793 8 8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z"/>
                                                   <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 1 8z"/>
                                                   <path fillRule="evenodd" d="M13.5 14.5A1.5 1.5 0 0 0 15 13V3a1.5 1.5 0 0 0-1.5-1.5h-8A1.5 1.5 0 0 0 4 3v1.5a.5.5 0 0 0 1 0V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5h-8A.5.5 0 0 1 5 13v-1.5a.5.5 0 0 0-1 0V13a1.5 1.5 0 0 0 1.5 1.5h8z"/>
                                                   </svg> Iniciar Sesión
                                                 </a>
                          </div>

                          <div className="col-md-12 text-center" id="btn_cerraractivacion">
                            <a type="button" id="btn-ttc" className="btn" data-dismiss="modal">
                                                   <svg width="22" height="22" viewBox="0 0 16 16" className="bi bi-box-arrow-in-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                   <path fillRule="evenodd" d="M8.146 11.354a.5.5 0 0 1 0-.708L10.793 8 8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z"/>
                                                   <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 1 8z"/>
                                                   <path fillRule="evenodd" d="M13.5 14.5A1.5 1.5 0 0 0 15 13V3a1.5 1.5 0 0 0-1.5-1.5h-8A1.5 1.5 0 0 0 4 3v1.5a.5.5 0 0 0 1 0V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5h-8A.5.5 0 0 1 5 13v-1.5a.5.5 0 0 0-1 0V13a1.5 1.5 0 0 0 1.5 1.5h8z"/>
                                                   </svg> Cerrar
                                                 </a>
                          </div>        
                              
                    </div> 
    
                {/*<div className="modal-footer justify-content-between">      
                                        
                          <button type="button" className="btn btn-primary" disabled={!this.state.button_activacuenta} onClick={()=> this.Activar_cuenta()}> {this.state.loading_button_activacuenta?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Activar cuenta</button>           
                    
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>            
              
                             
                    </div> */}

                  </div>
                  
                </div>
                
              </div> 


              <div className="modal fade" id="modal-creaprestador" data-backdrop="static">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Prestador creado</b></h4>
                      <a type="button" className="close"  href="/" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </a>
                    </div>
    
                    <div className="modal-body">

                          <span id="alert-activacuenta"> </span>
                      
                          <div className="row justify-content-md-center"> <br/>

                              <div className="col-md-10">

                                 Su registro como prestador ha sido exítoso

                              </div>
                                                  
                          
                          </div>

                          <br/>

                         


                          <div className="col-md-12 text-center" id="link_panelwebp">
                            <a type="button" id="btn-ttc" className="btn" href="/">
                                                   <svg width="22" height="22" viewBox="0 0 16 16" className="bi bi-box-arrow-in-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                   <path fillRule="evenodd" d="M8.146 11.354a.5.5 0 0 1 0-.708L10.793 8 8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z"/>
                                                   <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 1 8z"/>
                                                   <path fillRule="evenodd" d="M13.5 14.5A1.5 1.5 0 0 0 15 13V3a1.5 1.5 0 0 0-1.5-1.5h-8A1.5 1.5 0 0 0 4 3v1.5a.5.5 0 0 0 1 0V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5h-8A.5.5 0 0 1 5 13v-1.5a.5.5 0 0 0-1 0V13a1.5 1.5 0 0 0 1.5 1.5h8z"/>
                                                   </svg> Iniciar Sesión
                                                 </a>
                          </div>        
                              
                    </div> 
    
                {/*<div className="modal-footer justify-content-between">      
                                        
                          <button type="button" className="btn btn-primary" disabled={!this.state.button_activacuenta} onClick={()=> this.Activar_cuenta()}> {this.state.loading_button_activacuenta?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Activar cuenta</button>           
                    
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>            
              
                             
                    </div> */}

                  </div>
                  
                </div>
                
              </div>

              <div className="modal fade" id="modal-verificaprestador" data-backdrop="static">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Atención</b></h4>
                      <a type="button" className="close" data-dismiss="modal"  aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </a>
                    </div>
    
                    <div className="modal-body">

                          <span id="alert-activacuenta"> </span>
                      
                          <div className="row justify-content-md-center"> <br/>

                              <div className="col-md-11">

                              {msj_prestador}

                              </div>
                                                  
                          
                          </div>

                          <br/>                         


                          <div className="col-md-12 text-center" id="link_panelwebp">
                              <a type="button" id="btn-ttc" className="btn" href="/">
                                 <svg width="22" height="22" viewBox="0 0 16 16" className="bi bi-box-arrow-in-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                 <path fillRule="evenodd" d="M8.146 11.354a.5.5 0 0 1 0-.708L10.793 8 8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z"/>
                                 <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 1 8z"/>
                                 <path fillRule="evenodd" d="M13.5 14.5A1.5 1.5 0 0 0 15 13V3a1.5 1.5 0 0 0-1.5-1.5h-8A1.5 1.5 0 0 0 4 3v1.5a.5.5 0 0 0 1 0V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5h-8A.5.5 0 0 1 5 13v-1.5a.5.5 0 0 0-1 0V13a1.5 1.5 0 0 0 1.5 1.5h8z"/>
                                 </svg> Iniciar Sesión
                              </a>
                          </div>        
                              
                    </div> 
    
                {/*<div className="modal-footer justify-content-between">      
                                        
                          <button type="button" className="btn btn-primary" disabled={!this.state.button_activacuenta} onClick={()=> this.Activar_cuenta()}> {this.state.loading_button_activacuenta?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Activar cuenta</button>           
                    
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>            
              
                             
                    </div> */}

                  </div>
                  
                </div>
                
              </div>


              <div className="modal fade" id="modal-confirmaridentidad">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Confirmar identidad</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
    
                    <div className="modal-body">

                          <span id="alert-activacuenta"> </span>
                      
                          <div className="row justify-content-md-center"> 

                              <div className="col-md-11">

                                 {msj_usuario}
                                

                              </div>
                                                  
                          
                          </div>



                          <br/>

                          <div className="row justify-content-md-center">                                    
                              
                                 
                              <div className="col-md-6">
                                    <div className="form-group">  
                                        
                                          <div className="input-group">                                                                  
                                            <input id="txtPassword" disabled={!this.state.nom_inst_active} type="password" maxLength="20" className="form-control" name="pass_cuenta" onChange={this.handleChange} value={form.pass_cuenta} />                                                                                                                                   
                                          </div>
                                    </div>                 
                              </div>

                              
                          
                          </div> 

                          <br/>

                          <div className="row justify-content-md-center"> 

                              <div className="col-md-10">                          
                                              
                                    <button id="btn-registrar" type="button" className="btn btn-save" disabled={!this.state.button_activacuenta} onClick={()=> this.Buscar_Usuario_pass()}> {this.state.loading_button_activacuenta?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Verificar </button>
                               
                              </div>  

                          </div>

                          <div className="col-md-12 text-center">
                              <button id="btn-recoveripass" onClick={()=> this.modal_recoverypass()}>¿Olvidó su contraseña?</button> 
                          </div>

                            
                              
                    </div> 
    
                {/*<div className="modal-footer justify-content-between">      
                                        
                          <button type="button" className="btn btn-primary" disabled={!this.state.button_activacuenta} onClick={()=> this.Activar_cuenta()}> {this.state.loading_button_activacuenta?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Activar cuenta</button>           
                    
                          <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>            
              
                             
                    </div> */}

                  </div>
                  
                </div>
                
              </div>


              <div className="modal fade" id="modal-recoverypass">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Recuperar contraseña</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                      </button>
                    </div>
    
                    <div className="modal-body">

                          
                          {/* /<span id="alert-recoverypass"> </span> */}
                      
                          <div className="row justify-content-md-center" id="mensaje_recupera"> 

                              <div className="col-md-12">

                                 Se enviará un código de recuperación al correo electrónico registrado. <strong> {this.state.form.email_inst} </strong> 

                              </div>
                                                  
                          
                          </div>

                          <br/>

                          <div className="row" id="correo_recuperacion">                           
                                          
                              <div className="col-md-12">
                                  <div className="form-group">                    
                                          <label >Ingresa el correo:</label>
                                          <input type="text" className="form-control" maxLength="50" name="correo_recupera" onChange={this.handleChange}/>                                                  
                                  </div>          
                              </div>                          
                          
                          </div>

                          <div id="cambio_contraseña">

                          <div className="row">                           
                                          
                              <div className="col-md-12">
                                  <div className="form-group">                    
                                          <label >Código de validación:</label>
                                          <input type="text" className="form-control" maxLength="6" name="cod_recuperacion" onChange={this.handleChange}/>                                                  
                                  </div>          
                              </div>

                              <div className="col-md-12">
                                  <div className="form-group">                    
                                          <label >Nueva contraseña:</label>
                                          <input type="password" maxLength="20" className="form-control" name="passr" onChange={this.handleChange}/>                                                  
                                  </div>          
                              </div>  

                              <div className="col-md-12">
                                  <div className="form-group">                    
                                          <label >Confirmar contraseña:</label>
                                          <input type="password" maxLength="20" className="form-control" name="pass_confirmr" onChange={this.handleChange}/>                                                  
                                  </div>          
                              </div> 

                              <div className="col-md-12 text-center">
                                  <button type="submit" id="btn-cambiocontra" className="btn"  disabled={!this.state.active_buttonchange_pass} onClick={()=> this.Cambiar_Contraseña()}> {this.state.loading_buttonchange_pass?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Cambiar</button>                     
                              </div>

                          </div> 

                          </div> 

                             
                              
                    </div> 
    
                    <div className="modal-footer justify-content-between" id="btns_option">      
                                        
                          <button type="button" id="btn_recupera" className="btn btn-primary" disabled={!this.state.active_buttonrecovery_pass} onClick={()=> this.Recuperar_cuenta()}> {this.state.loading_buttonrecovery_pass?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Recuperar contraseña </button>           
                    
                          <button type="button" id="btn_recupera"  className="btn btn-primary" onClick={()=> this.validar_codigo()}>  Tengo un código </button>            
              
                             
                    </div>

                  </div>
                  
                </div>
                
              </div>


              <div className="modal fade" id="modal-compramembresia" data-backdrop="static">

                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header" id="fondo_modal_compra">
                        <h4 className="modal-title"><b>{this.state.Detalle_membresia.name}</b></h4>
                                            
                      </div>    
    
                      <form>                               

                          <div className="modal-body">
                              
                              <div id="compra_paso1">


                                      <div className="d-flex justify-content-center">              
                                                                                                 
                                            <img src={paso1compra} />                                                             
                                
                                      </div>

                                       
                                      <br/> 

                                      <div className="row justify-content-md-center">

                                            <div className="col-lg-12">

                                                <div className="card" id="card_body">
                                                      <div className="card-header text-center">
                                                         <b>Detalle del producto</b> <br/>
                                                      </div>
                                                      <div className="card-body">
                                                          <div className="row d-flex justify-content-center">
                                                       
                                                              <div className="col col-lg-5 text-right">                                                                      
                                                                 
                                                                  <b>Subtotal: </b> <br/>
                                                                  <b>IVA: </b> <br/>
                                                                  <b>Total: </b> <br/>


                                                              </div> 
                                                              <div className="col-sm-6">                                                                      
                                                                 
                                                                  <b>{(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP", minimumFractionDigits: 0}).format(this.state.Detalle_membresia.priceCOP))}</b> <br/>
                                                                  <b>{(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP", minimumFractionDigits: 0}).format(this.state.Detalle_membresia.percentIVA))}</b> <br/>
                                                                  <b>{(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP", minimumFractionDigits: 0}).format(this.state.Detalle_membresia.priceCOP))}</b> <br/>


                                                              </div>          
    
                                                          </div>
                                                      </div>
                                                </div>
            
                                            </div>
                                  
                                      </div>
                                      
                                      <div className="row justify-content-md-center">

                                          <div className="col-md-5">                
                                                <div className="form-group">                    
                                                    <label > <h5>Datos del comprador</h5></label>
                                                                                                                 
                                                </div>                                             
                                          </div> 
                                  
                                      </div>  

                                      <div className="row">

                                            <div className="col-md-6">
                                                  <div className="form-group"> 

                                                        <label >Tipo ID</label>
                                                        <select  className="form-control" name="tipoid_comprador_general" id="tipoid_comprador_general" onChange={this.handleChange}  required>                                                          
                                                           <option  value="null">Seleccione</option> 
                                                           <option  value="CC">Cédula de ciudadania</option> 
                                                           <option  value="CE">Cédula de extrangeria</option>
                                                           <option  value="NIT">NIT</option>                    
                                                        </select>
                                                                            
                                                  </div>                 
                                            </div> 
                          
                                            <div className="col-md-6">
                                                  <div className="form-group">                    
                                                    <label >Identificación</label>
                                                    <input type="text" className="form-control" name="id_comprador_general" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div>
              
                                      </div> 
        
                                      <div className="row">
              
                                            <div className="col-md-6">
                                                  <div className="form-group">                    
                                                    <label >Nombres</label>
                                                    <input type="text" className="form-control" name="nombres_comprador_general" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div> 
              
                                            <div className="col-md-6">
                                                  <div className="form-group">                    
                                                    <label >Apellidos</label>
                                                    <input type="text" className="form-control" name="apellidos_comprador_general" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div>                         
                          
                                      </div>

                                      <div className="row">
                          
                                            <div className="col-md-12">
                                                  <div className="form-group">                    
                                                    <label >Correo electrónico</label>
                                                    <input type="email" className="form-control" name="correo_comprador_general" onChange={this.handleChange} required/>                      
                                                  </div>                 
                                            </div>                   
                          
                                      </div>                           
                                    
                    
                                      <div className="row">
              
                                            <div className="col-md-6">
                                                  <div className="form-group">                    
                                                    <label >Dirección</label>
                                                    <input type="text" className="form-control" name="dir_comprador_general" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div> 
              
                                            <div className="col-md-6">
                                                  <div className="form-group">                    
                                                    <label >Telefono</label>
                                                    <input type="text" className="form-control" name="tel_comprador_general" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div>                         
                          
                                      </div>
                              </div>

                              <div id="compra_paso2">

                                <div className="row">                                      
                                                                                           
                                      <img src={paso2compra} />                                                                
                          
                                </div>

                                <br/> 

                                <div className="row justify-content-md-center">

                                      <div className="col-md-7">                
                                            <div className="form-group">                    
                                                <label > <h5>Seleccione un método de pago</h5></label>
                                                                                                             
                                            </div>                                             
                                      </div> 
                                  
                                </div>  
                                            
                                <div className="form-check">
                                      <div className="input-group">
                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="CARD" onClick={this.GetMetodopago}/>
                                            
                                            <label className="form-check-label" for="exampleRadios1">  Tarjeta de crédito  </label>
                                            <div className="input-group-append">
                                                  <img src={card_credit} />
                                            </div>
                                      </div>                                                        
                                           
                                </div> 

                                <br/>

                                <div className="row justify-content-md-center" id="tarjeta_credito">

                                    <div className="col-sm-12">
                                      

                                      <div className="row">
              
                                            <div className="col-md-12">
                                                  <div className="form-group">                    
                                                    <label >Número completo de la tarjeta (sin espacios)</label>
                                                    <input type="text" className="form-control" name="num_tc" placeholder="4400330022001100" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div>                        
                          
                                      </div>

                                      <div className="row">
              
                                            <div className="col-md-12">
                                                  <div className="form-group">                    
                                                    <label >Nombre en la tarjeta</label>
                                                    <input type="text" className="form-control" maxLength="30" name="tarjetahabiente" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div>                        
                          
                                      </div>

                                      <div className="row">
              
                                            <div className="col-md-4">
                                                  <div className="form-group"> 

                                                        <label >Fecha de expiración</label>
                                                        <div className="input-group">

                                                              <div className="input-group-append">
      
                                                                    <select  onChange={this.handleChange}  className="form-control" name="mes_tc" id="mes_tc" >                                                          
                                                                       <option  value="MM">MM</option>
                                                                       <option  value="01">01</option> 
                                                                       <option  value="02">02</option>
                                                                       <option  value="03">03</option>
                                                                       <option  value="04">04</option> 
                                                                       <option  value="05">05</option>
                                                                       <option  value="06">06</option>  
                                                                       <option  value="07">07</option> 
                                                                       <option  value="08">08</option>
                                                                       <option  value="09">09</option>  
                                                                       <option  value="10">10</option> 
                                                                       <option  value="11">11</option>
                                                                       <option  value="12">12</option>                      
                                                                    </select>
      
                                                              </div>

                                                              <div className="input-group-append">
      
                                                                    <select  onChange={this.handleChange}  className="form-control" name="year_tc" id="year_tc" >                                                          
                                                                       <option  value="YY">YY</option>
                                                                       <option  value="21">21</option> 
                                                                       <option  value="22">22</option>
                                                                       <option  value="23">23</option> 
                                                                       <option  value="24">24</option> 
                                                                       <option  value="25">25</option>
                                                                       <option  value="26">26</option>
                                                                       <option  value="27">27</option> 
                                                                       <option  value="28">28</option>
                                                                       <option  value="29">29</option>
                                                                       <option  value="30">30</option>                                                                            
                                                                    </select>
      
                                                              </div>
                                                        </div>                                                    
                                                                            
                                                  </div>                 
                                            </div> 

                                            <div className="col-md-4">
                                                  <div className="form-group">                    
                                                        <label >Cuotas</label>
                                                        <select  onChange={this.handleChange} className="form-control" name="cuotas_tc"  id="cuotas_tc">                                                         
                                                           <option  value="1">1</option> 
                                                           <option  value="2">2</option> 
                                                           <option  value="3">3</option> 
                                                           <option  value="4">4</option>
                                                           <option  value="5">5</option> 
                                                           <option  value="6">6</option>
                                                           <option  value="7">7</option> 
                                                           <option  value="8">8</option>  
                                                           <option  value="9">9</option> 
                                                           <option  value="10">10</option>
                                                           <option  value="11">11</option> 
                                                           <option  value="12">12</option>                                                                     
                                                        </select>
                                                                              
                                                     </div>                 
                                            </div>

                                            <div className="col-md-4">
                                                  <div className="form-group">                    
                                                    <label >Código de seguridad</label>
                                                    <input type="text" maxLength="3" className="form-control" name="cod_tc" placeholder="000" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div> 

                                      </div>

                                      <hr></hr>

                                    </div>

                                </div>

                                                      
                                <div className="form-check">
                                      <div className="input-group">
                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="PSE" onClick={this.GetMetodopago}/>
                                            
                                            <label className="form-check-label" for="exampleRadios2">  PSE  </label>
                                            <div className="input-group-append">
                                                  <img src={pse} />
                                            </div>
                                      </div>                                                        
                                           
                                </div> 

                                <br/>  

                                <div className="row justify-content-md-center" id="pse">

                                    <div className="col-sm-12">

                                      {/* <div className="row">              
                                           
              
                                            <div className="col-md-4">
                                                  <div className="form-group">                    
                                                        <label >Tipo de documento</label>
                                                        <select onChange={this.handleChange} className="form-control" name="tipodcto_pse"  className="form-control">                                                         
                                                           <option  value="CC">CC</option> 
                                                           <option  value="NIT">NIT</option>                                                                        
                                                        </select>
                                                                              
                                                     </div>                 
                                            </div>  

                                            <div className="col-md-8">
                                                  <div className="form-group">                    
                                                    <label >Número de documento</label>
                                                    <input type="text" maxLength="10" className="form-control" name="numerodcto_pse"  onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div>                      
                          
                                      </div> */}


                                      <div className="row">

                                            <div className="col-md-4">
                                                  <div className="form-group">                    
                                                        <label >Tipo de persona</label>
                                                        <select onChange={this.GetTipopersona} className="form-control" name="tipo_persona_pse"  id="tipo_persona_pse">                                                         
                                                           <option  value="">Seleccione...</option>
                                                           <option  value="0">Natural</option> 
                                                           <option  value="1">Júridica</option>                                                                        
                                                        </select>
                                                                              
                                                     </div>                 
                                            </div> 
              
                                            <div className="col-md-8">                                                                          
                                                    <div className="form-group">                    
                                                        <label >Banco</label> 
                                                        <select onChange={this.handleChange} className="form-control" name="id_banco_pse" id="id_banco_pse">
                                                            <option  value=""> Seleccione...</option> ,
                                                            {this.state.Bancos.map(banco=>                              
                                                            <option key={banco.financial_institution_code} value={banco.financial_institution_code}> {banco.financial_institution_name.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                            )}
                                                                    
                                                        </select>                                                                         
                                                        
                                                    </div>                                        
                                            </div> 
              
                                                                   
                          
                                      </div>

                                      <hr></hr>

                                    </div>

                                </div>                                  
                                                      
                                <div className="form-check">
                                      <div className="input-group">
                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="BANCOLOMBIA_TRANSFER" onClick={this.GetMetodopago}/>
                                            
                                            <label className="form-check-label" for="exampleRadios3">  Bancolombia  </label>
                                            <div className="input-group-append">
                                                  <img src={bancolombia} />
                                            </div>
                                      </div>                                                        
                                           
                                </div> 

                                <br/>
                                                                 
                                                      
                              {/*  <div className="form-check">
                                      <div className="input-group">
                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" value="NEQUI" onClick={this.GetMetodopago}/>
                                            
                                            <label className="form-check-label" for="exampleRadios4">  Nequi  </label>
                                            <div className="input-group-append">
                                                  <img src={nequi} />
                                            </div>
                                      </div>                                                                                                        
                                           
                                </div> 

                                <br/> 

                                <div className="row justify-content-md-center" id="nequi">

                                    <div className="col-sm-12">

                                      <div className="row"> 
                          
                                            <div className="col-md-12">
                                                  <div className="form-group">                    
                                                    <label >Numero de celular (cuenta Nequi)</label>
                                                    <input type="text" maxLength="10" className="form-control" name="cel_nequi" onChange={this.handleChange} required />                      
                                                  </div>                 
                                            </div>
              
                                      </div>  

                                    </div>        

                                                            </div>  */}

                              </div>

                              <div id="compra_paso3">


                                      <div className="d-flex justify-content-center">              
                                                                                                 
                                            <img src={paso3compra} />                                                                
                                
                                      </div>

                                       
                                      <br/> 

                                      <div className="row justify-content-md-center">

                                            <div className="col-lg-12">

                                                <div className="card" id="card_body">
                                                      <div className="card-header text-center">
                                                         <b>Detalle de la compra</b> <br/>
                                                      </div>
                                                      <div className="card-body">
                                                          <div className="row d-flex justify-content-center">
                                                       
                                                              <div className="col col-lg-5 text-right">                                                                      
                                                                 
                                                                  <b>Subtotal: </b> <br/>
                                                                  <b>IVA: </b> <br/>
                                                                  <b>Valor de la transacción: </b> <br/>
                                                                  <b>Total: </b> <br/>


                                                              </div> 
                                                              <div className="col-sm-6">                                                                      
                                                                 
                                                                  <b>{(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP"}).format(this.state.Detalle_compra.subtotal))}</b> <br/>
                                                                  <b>{(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP"}).format(this.state.Detalle_compra.iva))}</b> <br/>
                                                                  <b>{(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP"}).format(this.state.Detalle_compra.commission))}</b> <br/>
                                                                  <b>{(new Intl.NumberFormat("es-CO", {style: "currency", currency: "COP"}).format(this.state.Detalle_compra.total))}</b> <br/>


                                                              </div>          
    
                                                          </div>
                                                      </div>
                                                </div>
            
                                            </div>
                                  
                                      </div>                                      
                                     
                              </div>
                    
                          </div>
                                
                    
                          <div className="modal-footer justify-content-between" id="btn_compra_paso1">

                                <button type="button" id="btn_compra" className="btn btn-danger" data-dismiss="modal" onClick={()=> this.dropmodal_comprar_membresia()}>Cancelar</button>
                                <button type="submit" id="btn_compra" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.validar_paso1_compra()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Método de pago
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="2 3 10 10">
                                          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                        </svg> 
                                </button>

                          </div>

                          <div className="modal-footer justify-content-between" id="btn_compra_paso2">                         

                                <button type="button" id="btn_compra" className="btn btn-danger" onClick={()=> this.atras()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="currentColor" class="bi bi-arrow-left-short" viewBox="3 3 10 10">
                                          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                        </svg>
                                        Atras
                                </button>
                                <button type="button" id="btn_compra" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.Validar_formularios_compra()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Continuar
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="2 3 10 10">
                                          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                        </svg> 
                                </button>

                            
                                    
                          </div>

                          <div className="modal-footer justify-content-between" id="btn_compra_paso3">                         

                                <button type="button" id="btn_compra" className="btn btn-danger" onClick={()=> this.atras3()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="currentColor" class="bi bi-arrow-left-short" viewBox="3 3 10 10">
                                          <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                                        </svg>
                                        Atras
                                </button>
                                <button type="submit" id="btn_compra" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.ComprarMembresia()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Pagar
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="2 3 10 10">
                                          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                        </svg> 
                                </button>

                            
                                    
                          </div>

                      </form>

                    </div>
                    
                  </div>
              
              </div>


              <div className="modal fade" id="modal_img_perfil">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Imagen de perfil</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
    
                    <div className="modal-body">
                        
                          <div className="row"> <br/>

                              <img src={files_view} id="imgs" alt="logo" width= "440" className="rounded"/>
                                                                
                          </div>

                    </div> 
    
                    <div className="modal-footer justify-content-between">      
                                                        
                          <button type="button" className="btn btn-primary" data-dismiss="modal">Aceptar</button>            
                                   
                    </div>

                  </div>                  
                </div>                
              </div> 


              <div className="modal fade" id="modal_img_portada">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Imagen de portada</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
    
                    <div className="modal-body">
                        
                          <div className="row"> <br/>
                               
                              <img src={files_viewp} id="imgs" alt="logo" width= "440" className="rounded"/>                                   
                          
                          </div>

                    </div> 
    
                    <div className="modal-footer justify-content-between">      
                                                        
                          <button type="button" className="btn btn-primary" data-dismiss="modal">Aceptar</button>            
              
                             
                    </div>

                  </div>  

                </div>

              </div>  

              <div className="modal fade" id="modal-terminos_condiciones" data-backdrop="static">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h5 className="col-12 modal-title text-center" id="correo_activacion"><b>TÉRMINOS Y CONDICIONES DE USO</b></h5>
                      
                    </div>
    
                    <div className="modal-body">

                          
                          <div className="row justify-content-md-center"> <br/>

                              <div id="titulo_terminos" className="col-md-12">

                                  <b>TÉRMINOS Y CONDICIONES DE USO DE LOS CANALES VIRTUALES DE MEDICALL24 Y TRATAMIENTO DE DATOS PERSONALES</b>

                              </div>
                                                  
                          
                          </div>
                          <br/>
                          <div className="row justify-content-md-center"> <br/>

                              <div id="texto_terminos" className="col-md-12">                                

                                En cumplimiento de las normativas bajo las cuales usted puede utilizar esta aplicación, MEDICALL24 SAS, (en adelante "LA COMPAÑÍA") establece los términos y condiciones de uso de los siguientes CANALES VIRTUALES: i) Portal para prestadores de salud; ii) Aplicación para dispositivos móviles denominada "App Medicall24", y iii) Página web www.medicall24.com.co; por favor lea cuidadosamente. Al hacer clic en la casilla “Acepto termino y condiciones”, usted habrá manifestado su aceptación sin restricciones de este aviso legal y, por lo tanto, de los Termino y Condiciones de uso acá establecidas. Si usted no acepta los Términos y Condiciones establecidos aquí, usted no podrá acceder ni utilizar los servicios de los CANALES VIRTUALES. <br/> <br/>
                                LA COMPAÑÍA puede modificar estos Términos y Condiciones en cualquier momento sin previo aviso. Usted deberá leer lo contenido en este instrumento legal periódicamente para revisar las normativas aquí establecidas, debido a que las mismas son obligatorias para usted. Los términos "usted" o "usuario" tal como se usan aquí, se refieren a todas las personas naturales o jurídicas o entidades de cualquier naturaleza que accedan a los CANALES VIRTUALES. <br/> <br/>
                                
                                Los términos y condiciones generales que a continuación se establecen, regulan el uso de los CANALES VIRTUALES de LA COMPAÑÍA. Si usted no está de acuerdo con estos Términos y Condiciones, le solicitamos abstenerse de utilizar estos CANALES VIRTUALES, ya que su uso de cualquier forma, indicará que usted acepta tácitamente estos Términos y Condiciones. <br/> <br/>

                                <b>1.	INTRODUCCIÓN.</b> <br/>
                                Los presentes Términos y Condiciones de uso de los CANALES VIRTUALES se publican con el fin de informar a todos los Usuarios de la misma, que LA COMPAÑÍA, ha puesto a su disposición la los CANALES VIRTUALES con la finalidad de realizar transacciones y gestionar sus servicios de manera ágil y segura. <br/> <br/>

                                Los presentes Términos y Condiciones constituyen un acuerdo legal y vinculante entre el Usuario de los CANALES VIRTUALES y LA COMPAÑÍA y establecen las condiciones de su uso. Por lo anterior, es su obligación como Usuario de los CANALES VIRTUALES leer cuidadosamente los presentes Términos y Condiciones, y debe tener en cuenta que si decide no aceptarlos, no podrá acceder ni utilizar los servicios de los CANALES VIRTUALES. Por lo anterior, al hacer Click en la casilla “Acepto termino y condiciones”, el Usuario habrá manifestado su aceptación expresa, sin restricciones, reservas ni modificaciones a este Aviso Legal y por lo tanto a los Términos y Condiciones de uso acá establecidos. <br/> <br/>

                                <b>2.	FUNCIONES DE LOS CANALES VIRTUALES.</b> <br/>
                                Los CANALES VIRTUALES permiten el acceso mediante un licenciamiento de uso de software, el cual tiene como función principal permitir a los Usuarios (pacientes y profesionales de la salud de un prestador), interactuar mediante el uso de la Telemedicina para llevar a cabo la prestación del servicio de salud. La atención médica se brinda mediante videollamadas en tiempo real, donde los profesionales de la salud contratados por un prestador de salud, pueden emitir un diagnóstico y definir tratamientos médicos, dejando registro en la historia clínica de cada atención, y prescribiendo las fórmulas de medicamentos u órdenes médicas que sean requeridas. <br/> <br/>

                                El contenido informativo que se encuentra en los CANALES VIRTUALES, es presentado de manera informativa. Por lo tanto, ni LA COMPAÑÍA, ni su(s) autor(es), se responsabilizan del entendimiento, interpretación y/o uso de este contenido por parte del usuario, pues la veracidad de la información contenida y publicada, es de exclusiva responsabilidad del Usuario que la genere. <br/><br/>

                                LA COMPAÑÍA, se reserva la facultad de modificar el contenido y/o el servicio de CANALES VIRTUALES, por sí mismo o mediante un tercer autorizado, sin notificar previamente al usuario. <br/> <br/>

                                Con la aceptación de las condiciones de uso de los CANALES VIRTUALES, el usuario se obliga a usar su contenido conforme a la normatividad que rige la materia y los términos y condiciones que aquí se contemplan, siempre en observancia de la ley, la moral, la confidencialidad y las buenas costumbres; así mismo, se compromete a no destinar su uso a fines ilícitos o inmorales, o en detrimento de los interés de terceros o de LA COMPAÑÍA. <br/><br/>
                                
                                <b>3.	LIMITACIÓN DE RESPONSABILIDADES MÉDICAS. </b> <br/>
                                El contenido disponible a través de los CANALES VIRTUALES de LA COMPAÑÍA no sustituye el criterio médico del profesional de la salud llamado a realizar consultas mediante el uso de la Telemedicina, donde se generen diagnósticos y se definan tratamientos a los pacientes. LA COMPAÑÍA, por sí misma, no provee servicios médicos, ni diagnóstico o tratamientos. El contenido publicado en los CANALES VIRTUALES es de exclusiva responsabilidad del Usuario que lo genera, por tanto usted sabrá determinar el nivel de confianza en el mismo. Los usuarios que utilicen los CANALES VIRTUALES son los únicos que pueden proveer servicios médicos, de diagnóstico y tratamiento para pacientes, siempre y cuando el registro se haya realizado como prestador de salud. <br/> <br/>

                                <b>4.	REQUISITOS PARA EL ACCESO</b> <br/>
                                •	LA COMPAÑÍA no garantiza el acceso permanente e ininterrumpido a los CANALES VIRTUALES, ni que este acceso sea libre de errores, o que el servicio o el servidor que lo pone a disposición, estén libres de virus u otros agentes nocivos. Cuando el usuario accede a los CANALES VIRTUALES, será su responsabilidad tomar las medidas pertinentes para evitar y/o corregir los virus u otros agentes nocivos existentes en su dispositivo. <br/>
                                •	El usuario será el único responsable del uso de la información contenida en los CANALES VIRTUALES. <br/>
                                •	Los CANALES VIRTUALES solo funcionaran si el dispositivo del USUARIO está conectado a internet. <br/>
                                •	El correcto funcionamiento de los CANALES VIRTUALES depende de la estabilidad y velocidad de internet y/o de la red de datos a la cual está conectado el dispositivo del USUARIO. <br/>
                                •	LA COMPAÑÍA puede permitir el acceso a otros sitios de internet a través de vínculos contenidos en los CANALES VIRTUALES; pero la inclusión de estos en los CANALES VIRTUALES, no implica ninguna relación contractual o comercial entre LA COMPAÑÍA y el operador del sitio vinculado. LA COMPAÑÍA no es responsable del contenido de ninguno de estos sitios y no garantiza los productos o servicios ofrecidos por el sitio vinculado. Por lo tanto, cualquier transacción que el usuario lleve a cabo con estos, se realiza única y exclusivamente entre el usuario y el anunciante del sitio vinculado, bajo su propia responsabilidad y autonomía. LA COMPAÑÍA no es responsable por ningún tipo de transmisión recibida desde cualquier sitio vinculado. <br/> <br/>

                                <b>5.	MARCO LEGAL</b> <br/>
                                En el presente documento el Usuario encontrará las pautas para el uso de los CANALES VIRTUALES así como el marco que regirá el adecuado tratamiento de los datos personales que sean incorporados o circulen en la misma, incluyendo los datos de carácter sensible o datos de salud. Teniendo en cuenta lo anterior, el tratamiento de los datos personales del Usuario, será realizado de conformidad con lo establecido en la legislación vigente en materia de Habeas Data y de manera particular por lo previsto en la Ley Estatutaria 1581 de 2012, Decreto Único Reglamentario del Sector Comercio, Industria y Turismo- Decreto 1074 de 2015, y las Políticas de Privacidad y Tratamiento de Datos Personales adoptadas por LA COMPAÑÍA. <br/><br/>
                                
                                El contenido de los Términos y Condiciones aquí previstos, puede ser objeto de modificaciones o actualizaciones, razón por la que será obligación del Usuario revisar periódicamente el contenido de los mismos con el fin de mantenerse informado frente a los cambios que se puedan presentar. <br/> <br/>

                                Por lo anterior, mediante la puesta en conocimiento de los Términos y Condiciones de los CANALES VIRTUALES y la información contenida en la página web www.medicall24.com.co, se entenderá cumplido el deber de información al Usuario. <br/> <br/>

                                <b>6.	AUTORIZACIÓN DE USO DE LOS CANALES VIRTUALES</b> <br/>
                                LA COMPAÑÍA, autoriza únicamente el uso de los CANALES VIRTUALES, sujeto a las reglas contenidas en el presente acuerdo de Términos y Condiciones de licencia de uso, permaneciendo restringido su uso comercial o con cualquier otro fin diferente al autorizado. <br/> <br/>

                                El Usuario se abstendrá de modificar, manipular, alterar, copiar, distribuir, transmitir, reproducir, licenciar, crear sitios web y aplicaciones para dispositivos móviles derivadas, vender o entregar la información recibida de los CANALES VIRTUALES en su dispositivo a un tercero; y en consecuencia asumirá los efectos legales si no llegare a cumplir con esta condición. Esta prohibición también incluye expresamente, sin limitarla, a la práctica de Screen Scraping o raspado de pantalla para obtener información, y el uso por parte de un tercero o en beneficio de un tercero. LA COMPAÑÍA no se hará responsables del mal uso que se haga de los CANALES VIRTUALES; el Usuario se hará responsable de toda copia, emulación, alteración o modificación que afecte la integridad de LA COMPAÑÍA. <br/> <br/>

                                El Usuario garantizará a LA COMPAÑÍA que no usará los CANALES VIRTUALES para fines contrarios a la ley o a lo estipulado en el presente Acuerdo de Términos y Condiciones de licenciamiento de uso de software. En caso de utilizarse, legitimará a LA COMPAÑÍA a desactivar e impedir que el Usuario continúe ingresando a la aplicación y al contenido comercial del mismo, y a iniciar las acciones legales, si a ello hubiere lugar. <br/> <br/>

                                La calidad de Usuario se obtiene al momento de registrarse en los CANALES VIRTUALES, creando el vínculo contractual con LA COMPAÑÍA. <br/> <br/>

                                La calidad de Usuario se perderá en los siguientes eventos: <br/><br/>

                                1.	Cuando el estado de la cuenta del usuario en los CANALES VIRTUALES de LA COMPAÑÍA, este suspendida o cancelada. <br/>
                                2.	En el evento en que se logre demostrar que existió suplantación de identidad. <br/>
                                3.	En cualquier momento en que el Usuario registrado realice alguna actuación considerada como violatoria de estos Términos y Condiciones o cualquier conducta contraria a la legislación colombiana, el orden público o las buenas costumbres. <br/> <br/>

                                <b>6.1.	Creación de Usuario para el acceso a los CANALES VIRTUALES:</b> <br/>
                                El Usuario de los CANALES VIRTUALES entiende y acepta que, para hacer uso de los mismos, se requiere la creación de una cuenta de Usuario que lo identifique como tal, a la cual, se podrá acceder, realizando la validación de inicio de sesión de la cuenta, ingresando la identificación del Usuario, el correo electrónico (solo para prestadores de salud), y una contraseña de uso personal e intransferible. <br/><br/>
                                
                                <b>6.2.	Uso de la contraseña.</b> <br/>
                                El Usuario acepta que las contraseñas ingresadas por este, al momento de su registro, para acceder al contenido de los servicios de los CANALES VIRTUALES, son privadas e intransferibles, por lo que tendrá la obligación de custodia de las mismas, siendo el único responsable de las consecuencias derivadas del uso que otras personas o terceros hagan de ellas, por la falta del cumplimiento del deber de custodia de dichas contraseñas. <br/><b/>

                                Por lo anterior, el Usuario se compromete a informar a más tardar dentro del día hábil siguiente a LA COMPAÑÍA, la pérdida o robo de su contraseña, el uso no autorizado de su contraseña por parte de terceros, o cualquier circunstancia que a su juicio deba ser conocida por LA COMPAÑÍA con el fin de evitar actos fraudulentos en contra de su propia persona, de LA COMPAÑÍA, o de terceros. <br/><br/>

                                El Usuario al hacer uso de su contraseña se obliga a abstenerse de realizar las siguientes acciones: a) Acceder a documentos confidenciales o datos de salud de personas de las que no se encuentra legitimado por ley o por convención para hacerlo. b) Suministrar información falsa a título personal o de su grupo familiar, así como omitir datos necesarios para la buena prestación del servicio. b) Dar un uso de la los CANALES VIRTUALES contrario a la Ley, la moral y las buenas costumbres. c) Realizar acciones tendientes a ocasionar daño o interrupción del servicio de los CANALES VIRTUALES. <br/> <br/>

                                El Usuario que viole cualquiera de las condiciones contenidas en este numeral, será responsable por los daños y perjuicios de cualquier naturaleza que pueda sufrir LA COMPAÑÍA, o cualquier tercero que resulte perjudicado por esta actuación. <br/><br/>

                                <b>6.3.	Obligaciones del Usuario</b> <br/>
                                •	El Usuario se compromete a hacer uso de los CANALES VIRTUALES, de conformidad con la ley colombiana, estos Términos y Condiciones y la demás información e instrucciones que sean puestas en su conocimiento por parte de LA COMPAÑÍA, así como de conformidad con el orden público, la moral y las buenas costumbres. <br/>
                                •	El Usuario garantiza la autenticidad y veracidad de todos aquellos datos personales e información que entregue para completar el formulario de suscripción o registro. Así mismo, el Usuario se compromete y se responsabiliza de mantener actualizada toda la información que haya entregado, permitiendo con ello prestar un mejor servicio por parte de LA COMPAÑÍA. <br/>
                                •	Cuando el Usuario inserte o incorpore cualquier información a los CANALES VIRTUALES, garantiza a LA COMPAÑÍA que la información es completa y veraz, que posee todos los derechos sobre la misma y que se encuentra autorizado para entregarla. <br/>
                                •	El uso de los CANALES VIRTUALES únicamente se encuentra permitida para personas mayores de 18 años. En ese sentido y por el principio de la buena fe, se entiende que la persona que está creando el usuario para acceder a los CANALES VIRTUALES es mayor de edad, razón por la que no existirá responsabilidad alguna para LA COMPAÑÍA. <br/> <br/>

                                <b>7.	POLÍTICA PARA EL USO DE LOS CANALES VIRTUALES POR PARTE DE MENORES DE 18 AÑOS DE EDAD, O DE SUS PADRES O REPRESENTANTES LEGALES, O DE PERSONAS INCAPACES O ADULTOS MAYORES.</b> <br/>
                                Los menores de 18 años de edad no podrán registrarse como Usuarios de los CANALES VIRTUALES por sí mismos, razón por la que, en caso de que los padres o representantes legales del menor deseen hacer uso de los CANALES VIRTUALES para los menores a su cargo, deberán manifestar su consentimiento en relación con las condiciones aquí descritas y aportar la información personal del menor requerida para la funcionalidad de los CANALES VIRTUALES. <br/><br/>

                                Los menores de 18 años de edad no podrán registrarse como Usuarios de los CANALES VIRTUALES por sí mismos, razón por la que, en caso de que los padres o representantes legales del menor deseen hacer uso de los CANALES VIRTUALES para los menores a su cargo, deberán manifestar su consentimiento en relación con las condiciones aquí descritas y aportar la información personal del menor requerida para la funcionalidad de los CANALES VIRTUALES. <br/><br/>

                                <b>7.1.	Personas incapaces o adultos mayores</b> <br/>
                                En los casos de representación por condiciones de incapacidad legal, o por relaciones de parentesco (por ejemplo en caso de que un hijo mayor de edad quiera manejar la cuenta de sus padres adultos mayores, o un padre quiera manejar la cuenta de su hijo adolescente) los Usuarios tendrán la posibilidad de abrir cuenta de Usuarios en los CANALES VIRTUALES para las personas a su cargo; sin embargo, deberá contar con autorización expresa de las partes relacionadas o del tutor o curador del incapaz, en donde conste que se actúa como la persona a cargo de la información del incapaz o adulto mayor y en consecuencia, que podrá tener acceso directo a la cuenta de éste. <br/> <br/>

                                <b>8.	DERECHOS DE PROPIEDAD INDUSTRIAL E INTELECTUAL</b> <br/>
                                Todas las marcas, nombres comerciales, signos distintivos, diseños industriales, modelos de utilidad, patentes, servicios, contenidos e informaciones de cualquier clase que aparecen en los CANALES VIRTUALES son propiedad de LA COMPAÑÍA, por lo que no podrán ser reproducidos, distribuidos, comunicados públicamente, transformados o modificados sin autorización expresa. <br/> <br/>

                                Por lo anterior, el Usuario se abstendrá de obtener los contenidos de los CANALES VIRTUALES, empleando para ello medios o procedimientos distintos de los que, en algunos casos, se han puesto a su disposición o, en general, de los que se empleen habitualmente en Internet siempre que, estos últimos, no entrañen un riesgo o daño o inutilización de los CANALES VIRTUALES y sus contenidos. <br/><br/>

                                En ningún caso se entenderá que el acceso y la navegación del Usuario en los CANALES VIRTUALES, implica que LA COMPAÑÍA haya otorgado una autorización o haya renunciado, transmitido, cedido total o parcialmente sus derechos, ni la concesión de ningún derecho ni expectativa de derecho y en concreto, de la alteración, transformación, explotación, reproducción, distribución o comunicación pública sobre la misma. <br/> <br/>

                                Los titulares de los derechos de propiedad industrial podrán iniciar acciones legales comerciales, civiles, penales, o de cualquier clase contra el Usuario de la aplicación que realice actos contrarios a las reglas de propiedad industrial contenidas en la ley o en el presente acuerdo de Términos y Condiciones de licenciamiento de uso de software. <br/> <br/>

                                <b>9.	DERECHOS DE AUTOR</b> <br/>

                                Todo el contenido de cualquier clase que aparezca en los CANALES VIRTUALES susceptible de ser objeto de derechos patrimoniales de autor, conforme a la Ley 23 de 1982 y demás normas que regulen esta materia, son propiedad de LA COMPAÑÍA, por lo que no podrán ser reproducidos, distribuidos, comunicados públicamente, transformados, copiados o modificados sin autorización expresa. <br/> <br/>

                                Conforme a lo anterior, los CANALES VIRTUALES serán en todo momento de titularidad de LA COMPAÑÍA. El usuario no tendrá ningún derecho de dominio ni disposición sobre los CANALES VIRTUALES, por lo que no podrá realizar actos de disposición, gravámenes, licenciamientos, ni cesiones sobre ellos. <br/><br/>

                                En ningún caso se entenderá que el acceso y la navegación del Usuario en los CANALES VIRTUALES, implica que LA COMPAÑÍA haya otorgado una autorización o haya renunciado, transmitido, cedido total o parcialmente sus derechos, ni la concesión de ningún derecho ni expectativa de derecho y en concreto, de la alteración, transformación, explotación, reproducción, distribución, copia o comunicación pública sobre los mismos. <br/> <br/>

                                Respecto del contenido comercial sobre el cual el usuario tiene acceso, este será exclusivamente de los clientes de LA COMPAÑIA, por lo que los Licenciatarios, no se harán responsables del contenido comercial de sus clientes. La función de LA COMPAÑÍA solo se limitará a permitir el acceso comercial mediante los CANALES VIRTUALES. <br/> <br/>

                                El Usuario se abstendrá de obtener los contenidos de los CANALES VIRTUALES empleando para ello medios o procedimientos distintos de los que, en algunos casos, se han puesto a su disposición o, en general, de los que se empleen habitualmente en Internet, siempre que, estos últimos, no entrañen un riesgo, daño o inutilización de los CANALES VIRTUALES y sus contenidos. <br/> <br/>

                                LA COMPAÑÍA, como titulares de los derechos patrimoniales de los CANALES VIRTUALES, se reservara el derecho de iniciar las acciones legales comerciales, civiles, penales o de cualquier clase en contra del Usuario de los CANALES VIRTUALES, que realicen actos contrarios a las reglas de derechos de autor contenidas en la ley o en el presente acuerdo de Términos y Condiciones de licenciamiento de uso de software. <br/><br/>

                                <b>10.	TRATAMIENTO DE DATOS PERSONALES</b> <br/>
                                La recolección y tratamiento de los datos personales tiene como finalidad la transparencia en la relación de LA COMPAÑÍA y el Usuario, así como la administración, gestión y ampliación de los servicios a las preferencias y gustos del perfil del Usuario. También serán necesarios los datos personales del Usuario para realizar mejoras a la aplicación, actualizaciones, envíos de correos electrónicos informativos, y selección de información comercial a la cual el Usuario tendrá acceso. <br/> <br/>

                                LA COMPAÑÍA almacenará automáticamente información general, así como la cantidad y periodicidad de consulta del usuario. La mencionada información personal solo se utilizará para obtener un correcto funcionamiento de la aplicación, junto con los fines estadísticos y comerciales para el uso interno de los CANALES VIRTUALES. <br/> <br/>

                                Conforme a lo anterior, los datos personales que se obtengan en virtud del uso de la aplicación, tendrán fines de mejorar de la aplicación, de suministro de información seleccionada para el Usuario y de uso exclusivo para MEDICALL24, por lo que no se cederán ni suministraran a ningún tercero. El Usuario, al aceptar este acuerdo de Términos y Condiciones acepta el tratamiento de sus datos personales conforme a los fines mencionados, y según lo dispuesto en la Ley 1581 de 2012 y el Decreto 1377 de 2013. <br/> <br/>

                                El Tratamiento de tales datos será realizado por LA COMPAÑÍA con sujeción a las finalidades autorizadas por los Usuarios, contenidas en estos Términos y Condiciones y a lo previsto en la Política de Privacidad y Protección de la Información que ésta ha adoptado y la cual se encuentra publicada en el sitio web <a href='https://medicall24.com.co/' target="_blank"> www.medicall24.com</a> <br/> <br/>

                                <b>11.	RECOLECCIÓN DE INFORMACIÓN PROVENIENTE DE TERCEROS, SERVIDORES PUBLICITARIOS Y PATROCINADORES</b> <br/>
                                A pesar de que el contenido de los CANALES VIRTUALES pueda direccionar a los Usuarios a páginas web de servidores publicitarios y empresas que ofrezcan sus productos por medio de ellos, este Acuerdo de Términos y Condiciones solo tendrá por objeto los CANALES VIRTUALES de MEDICALL24. En consecuencia, LA COMPAÑÍA no se hará responsable, ni asumirá ninguna responsabilidad por el contenido, información publicitaria, promociones de bienes y servicios, información y demás contenido que sus clientes publiquen y en ningún caso, tal información comercial guarda relación con el objeto social de LA COMPAÑÍA. El Usuario, en caso de ingresar a las páginas web o a los enlaces que direccionen las páginas de internet de los clientes de LA COMPAÑÍA, que suministran contenido comercial en los CANALES VIRTUALES, deberá leer y aceptar los términos y condiciones de las mencionadas páginas y aplicaciones. Cada enlace y página web que direccione a páginas de clientes o terceros, se regirá por sus propias políticas de términos y condiciones, de las cuales LA COMPAÑÍA no se hace responsable. <br/> <br/>

                                Las mencionadas páginas web o servidores de los anunciantes podrán emplear cookies, clear, Gifs, web beacons o cualquier tecnología similar para soportar las presentaciones de dichos anuncios. La utilización de esas nuevas tecnologías por terceras partes se rige por sus propias políticas de privacidad y no son objeto de regulación por el presente Acuerdo de Términos y Condiciones de uso de software. <br/> <br/>

                                <b>11.1.	Uso de cookies, clear, Gifs, web beacons o cualquier tecnología similar</b> <br/>
                                LA COMPAÑÍA, así como los anunciantes y patrocinadores podrán usar cookies, clear, Gifs, web beacons o cualquier tecnología similar cuando el Usuario utilice los CANALES VIRTUALES. El ingreso a los CANALES VIRTUALES se tomará como autorización expresa sobre el uso de cookies. La finalidad exclusiva del uso de cookies es recordarle al Usuario sus preferencias comerciales, país, características del navegador, y la información del uso de los CANALES VIRTUALES, entre otros. <br/> <br/>

                                Los servidores podrán utilizar cookies, web beacons y otras tecnologías similares para apoyar a la presentación de dichos anuncios, y para medir la eficacia de los anuncios de publicidad. El uso de estas tecnologías por anunciantes y en general, terceras personas, estará sujeto a sus propias políticas de privacidad y no estará sujeto a lo dispuesto en el presente Acuerdo de Términos y Condiciones de licenciamiento de uso de software. <br/> <br/>

                                <b>12.	LIMITACIÓN DE RESPONSABILIDAD</b> <br/>
                                El usuario utilizará los CANALES VIRTUALES bajo su exclusiva responsabilidad, teniendo en cuentas las siguientes circunstancias: <br/> <br/>

                                a.	LA COMPAÑÍA garantizan al Usuario que los servidores contaran con procedimientos de seguridad necesarios para evitar la pérdida, alteración o acceso de terceros a la información personal del Usuario, sin embargo, cada situación deberá analizarse en particular, puesto que el acceso ilícito a la información podrá constituir un caso de fuerza mayor o caso fortuito, en caso de que los Licenciatarios cumplan con las garantías mínimas de seguridad. <br/>
                                b.	LA COMPAÑÍA no será responsables de las destinaciones o transmisiones de dinero inválidas, fraudes y seguridad de la información que se realicen por medio de las compañías proveedoras de internet. <br/>
                                c.	Bajo ninguna circunstancia, LA COMPAÑÍA, sus clientes, patrocinadores, anunciantes o proveedores de bienes y servicios se harán responsables de manera enunciativa, de daños directos, indirectos, incidentales, especiales, consecuenciales, o de cualquier otra clase. Tampoco se harán responsables por los daños que se deriven o estén relacionados por la utilización indebida de los CANALES VIRTUALES, de cualquier información, producto, servicios y demás gráficos relacionados que se obtengan a través de los servicios ofrecidos por LA COMPAÑÍA, ya sea con fundamento contractual, extracontractual, negligencia, responsabilidad objetiva o de cualquier régimen. <br/> <br/>

                                <b>13.	RESTRICCIONES Y PROHIBICIONES</b> <br/>
                                El Usuario tendrá prohibido para sí o para terceras personas, autorizar la reproducción, copia, modificación o comercialización de los CANALES VIRTUALES y su contenido. El contenido de los CANALES VIRTUALES se extiende a textos, material audiovisual, imágenes gráficas, software, y sonidos en general, que genere la aplicación. Por lo anterior, el Usuario bajo ninguna modalidad o circunstancia podrá explotar comercialmente, copiar, vender, distribuir, licenciar, ceder, o modificar el contenido de la aplicación. Tampoco podrá publicar el contenido de la aplicación en otras aplicaciones, blog´s o sitios web sin autorización previa, expresa y por escrito de LA COMPAÑÍA. <br/> <br/>

                                También le estará prohibido al Usuario la realización o promoción de actividades en la aplicación que sean contrarios a la ley, a las sanas costumbres, al orden público y a la moral. Con carácter enunciativo y no limitativo, se describirán algunas conductas prohibidas: <br/> <br/>

                                a.	Utilizar la aplicación para publicar contenido pornográfico, explotación sexual en cualquiera de sus modalidades, actos de racismo o apología del mismo, consumos de drogas, contenido de propaganda política, estafa o cualquier actitud contraria a las leyes de la República de Colombia y tratados internacionales de ius cogens. <br/>
                                b.	Publicar contenido contrario a las reglas de establecidas en la ley y en el presente Acuerdo de Términos y Condiciones de licenciamiento de uso software respecto a los derechos de autor y a la propiedad industrial, así como cualquier acto de apología a la piratería o a la realización de copias ilegales protegidas por las leyes vigentes. <br/>
                                c.	Utilizar o fomentar el uso de los CANALES VIRTUALES, destinados a violar la intimidad de las personas, infringir la ley estatutaria de habeas data en cualquiera de sus modalidades, enviar correos masivos o spam. <br/>
                                d.	Realizar o promover la modificación del contenido de la aplicación, introducir de manera directa o indirecta virus, gusanos, troyanos y, en general, cualquier actitud tendiente a hackear el sistema y los ordenadores  de los CANALES VIRTUALES. En consecuencia, si LA COMPAÑÍA detecta cualquier contenido que no guarde relación con el material de LA COMPAÑÍA, podrá retirarlo de los CANALES VIRTUALES en cualquier momento y sin previo aviso al Usuario. <br/>

                                <b>14.	EXCLUSIÓN DE RESPONSABILIDADES Y GARANTÍAS</b> <br/> <br/>

                                <b>14.1.	Disponibilidad y continuidad:</b> <br/> <br/>

                                Por las características y necesidades tecnológicas de los CANALES VIRTUALES es posible que se presenten problemas en la disponibilidad o continuidad en el acceso y uso de los servicios de la misma, así como la ocurrencia de fallas técnicas en los servidores de acceso a la red. Por lo anterior, el Usuario entiende y acepta que frente a la ocurrencia de fallas en los CANALES VIRTUALES debido a la indisponibilidad del servicio por causas de fuerza mayor, errores en las redes telemáticas de transferencia de datos o por causas ajenas a la voluntad de LA COMPAÑÍA, ésta no será responsable por los daños y perjuicios de cualquier naturaleza que puedan generarse al Usuario y en esa medida, exime a LA COMPAÑÍA de cualquier responsabilidad en relación con las consecuencias de la ocurrencia de este tipo de eventos.  <br/> <br/>
                                
                                El acceso a la información y servicios que prestan los CANALES VIRTUALES, tienen en principio una duración indefinida, pero la misma dependerá de la decisión de LA COMPAÑÍA en continuar con el servicio prestado a través de los CANALES VIRTUALES. No obstante lo anterior, el acceso a los CANALES VIRTUALES podrá suspenderse o terminarse en cualquier momento en caso de que se compruebe cualquier violación a los esquemas de seguridad informática, o en caso de que se verifique suplantación en la identidad del Usuario. Por lo anterior, LA COMPAÑÍA no será responsable por el retiro de los CANALES VIRTUALES o suspensión de la prestación de los servicios a través de la misma. <br/> <br/>

                                En caso de que se pierda la calidad de Usuario por cualquier causa, el Usuario no podrá hacer uso de los CANALES VIRTUALES para consultar. <br/> <br/>

                                <b>14.2.	Exclusión de responsabilidad.</b> <br/>
                                LA COMPAÑÍA no será responsable de los daños, pérdida de negocio, ingresos o beneficios, daño emergente, lucro cesante o de oportunidades de negocio, de ahorro de gastos y de desaparición o deterioro de datos, así como tampoco será en ningún caso responsable de: (a) los costos, multas, sanciones, indemnizaciones, cargos, daños u honorarios que se deriven como consecuencia del incumplimiento por parte del Usuario de sus obligaciones; (b) La violación del Usuario de cualquier norma que pudiera resultar aplicable a causas o en relación con la utilización de los CANALES VIRTUALES, por lo tanto, el Usuario es y será el único responsable de: (i) el uso que realice de los CANALES VIRTUALES; (ii) el cumplimiento íntegro de cualquier norma que pudiera resultar aplicable a causa o en relación con la utilización de los CANALES VIRTUALES, incluyendo, a título enunciativo pero no limitativo, las normas de uso de los CANALES VIRTUALES aquí contenidas, las disposiciones en materia de protección de datos, confidencialidad, secreto de las comunicaciones y derecho a la intimidad. <br/> <br/>

                                <b>14.3.	Virus y códigos maliciosos:</b> <br/>
                                El Usuario exime a LA COMPAÑÍAS de cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan ser causados por, o que puedan deberse a la presencia de virus u otros códigos maliciosos en los contenidos que puedan producir cualquier tipo de daños en el sistema informático, documentos electrónicos o ficheros de los usuarios, incluyendo a título meramente enunciativo y sin carácter limitativo, "virus informáticos", gusanos, "caballos de troya", errores en la funcionalidad y operatividad ("bugs"), "bombas de tiempo", "cancelbots", "Spyware", "phishing", "dialers", "hoaxes", "jokes" etc. <br/> <br/>

                                <b>14.4.	Uso ilícito:</b> <br/>
                                LA COMPAÑÍA cuenta con normas y procedimientos, restricciones de acceso y uso a la información que garantizan que solamente personal altamente calificado e idóneo maneje las bases de datos sensibles cumpliendo con los protocolos para el manejo de esta información. <br/> <br/>

                                Por lo anterior LA COMPAÑÍA garantiza que los CANALES VIRTUALES se manejarán con adecuados estándares de seguridad, confidencialidad de la información y confiabilidad de manera que cada Usuario pueda mantener la reserva de su información. Sin embargo, no se hace responsable del incumplimiento de cualquier norma aplicable en que puedan incurrir los Usuarios en su acceso a los CANALES VIRTUALES y/o utilización de las informaciones contenidas en la misma. Tampoco se hace responsable del uso ilegítimo que terceras personas puedan hacer de la información allí contenida <br/> <br/>

                                <b>15.	CLÁUSULA DE INDEMNIDAD A FAVOR DE LA COMPAÑIA</b> <br/>
                                El Usuario se obliga a mantener indemne a LA COMPAÑÍA de cualquier reclamación, perdida, daño, costo y pago de honorarios e indemnizaciones que se ocasione en virtud o como consecuencia directa o indirecta del uso de la aplicación, sobre el contenido al cual se tiene acceso y a la violación de leyes o derechos de terceros por parte del Usuario. <br/> <br/>

                                <b>16.	LEGISLACIÓN Y JURISDICCIÓN</b> <br/>
                                Los presentes términos y condiciones se regirán por la Legislación colombiana, y la jurisdicción competente para conocer de cualesquier demanda que el uso de los CANALES VIRTUALES suscite, será la de los Juzgados y Tribunales de la República de Colombia. <br/> <br/>

                                <b>17.	NULIDAD E INEFICACIA DE LOS NUMERALES O CLÁUSULAS</b> <br/>
                                Si cualquier numeral o cláusula incluida en estos Términos y Condiciones fuese declarado, total o parcialmente, nulo o ineficaz, tal nulidad o ineficacia afectará tan sólo a dicha disposición o a la parte de la misma que resulte nula o ineficaz, subsistiendo los Términos y Condiciones en todo lo demás. <br/> <br/>

                                <b>18.	NATURALEZA DE LA RELACIÓN COMERCIAL</b> <br/>
                                El usuario reconoce y acepta que por el solo hecho de utilizar los CANALES VIRTUALES y aceptar los Términos y Condiciones, no tendrá la calidad de socio, mandatario, agente, empleado o representante de LA COMPAÑÍA. Bajo ninguna circunstancia, el Usuario podrá realizar actos a nombre de los Licenciatarios en representación directa ni indirecta. <br/> <br/>

                                <b>19.	FUERZA MAYOR O CASO FORTUITO</b> <br/>
                                Salvo las obligaciones dinerarias, entre ellas, las de pago, ninguna parte será responsable por demoras o responsable por no cumplimiento de las obligaciones pactadas por caso fortuito o fuerza mayor, así como circunstancias imprevisibles o irresistibles, de manera enunciativa y o limitativa, actos de guerra o conflicto civil, desastres naturales, paros, revoluciones y demás que no cuenten con un margen de previsión por las partes. <br/> <br/>

                                <b>20.	CLÁUSULA DE INTEGRACIÓN DE CONTRACTUAL</b> <br/>
                                Las partes acuerdan que el presente Acuerdo de Términos y Condiciones, así como el contrato de prestación de servicios y órdenes de compra de servicios, será el acuerdo único y completo, por lo que no se podrá tener como fuente de interpretación de la relación comercial y, en consecuencia, sustituye cualquier acuerdo previo, escrito o verbal que hayan estipulado las partes. <br/><br/>

                                <b>21.	QUEJAS, SOLICITUDES O RECLAMOS</b> <br/>
                                En caso de que el usuario pretenda presentar una queja, solicitud, sugerencia o reclamo, podrá contactar a un agente de LA COMPAÑÍA, por medio del siguiente correo: soporte@medicall24.com.co <br/> <br/>

                                <b>22.	OBLIGACIONES DEL USUARIO</b>  <br/>
                                1.	Guardar confidencialidad y buen uso de su cuenta y clave privada e intransferible de acceso a los CANALES VIRTUALES. <br/>
                                2.	Ser el responsable de todas las transacciones realizadas a través de su cuenta y clave privada e intransferible de acceso a los CANALES VIRTUALES. <br/>
                                3.	Notificar por escrito debidamente suscrito a LA COMPAÑÍA cualquiera de las siguiente situaciones: <br/>
                                    o	Perdida o hurto de su cuenta o clave privada e intransferible de acceso a los CANALES VIRTUALES. <br/>
                                    o	Uso no autorizado de su cuenta o clave personal e intransferible de acceso a los CANALES VIRTUALES. <br/>
                                    o	Fallas, errores o hechos inusuales al recibir algún mensaje en relación con una orden ejecutada por el usuario a través del sistema electrónico, o que haya sido recibida y/o ejecutada a través del mismo. <br/>
                                    o	Anulación de órdenes no emitidas por el usuario, o de impresiones o desacuerdos en la trasmisión de la información <br/>

                                    4.	Aceptar que la sesión de transacción no se cerrará hasta que el usuario lo decida haciendo clic en el botón cerrar sesión. <br/>
                                    5.	Asumir la responsabilidad por las claves de acceso y las cuentas de usuario que llegase a delegar. <br/>
                                    6.	Respetar la propiedad intelectual y derechos de autor sobre cualquier signo distintivo, información, material o contenido de los CANALES VIRTUALES de LA COMPAÑÍA. <br/>
                                    7.	Aceptar los termino y condiciones que modifique o deroguen las condiciones de acceso y uso de los CANALES VIRTUALES. <br/>
                                    8.	Sera responsable del uso correcto de los CANALES VIRTUALES, así como de la veracidad de los datos que provea en el momento del registro de usuario. <br/> <br/>

                                <b>23.	EXIMENTES DE RESPONSABILIDAD</b> <br/>
                                a.	Publicidad de bienes o servicios no prestados directamente por LA COMPAÑÍA. <br/>
                                b.	Intermitencia o suspensión del funcionamiento de los CANALES VIRTUALES. <br/>
                                c.	Suspensión del usuario por causas no imputables a LA COMPAÑÍA. <br/>
                                d.	Cambio sin previo aviso en el contenido de los CANALES VIRTUALES. <br/>
                                e.	Enlaces o conexiones a otras páginas web que no sean de propiedad de LA COMPAÑÍA. <br/>
                                f.	Dar por terminado este servicio e impedir el acceso a los CANALES VIRTUALES, cuando el usuario haya perdido dicha calidad. <br/> <br/>
                                

                              </div>
                                                  
                          
                          </div>

                          <div className="row justify-content-md-center"> <br/>

                              <div id="titulo_terminos" className="col-md-12">

                                  <b>POLITICA DE PROTECCION DE DATOS</b>

                              </div>
                                                  
                          
                          </div>
                          <br/>
                          <div className="row justify-content-md-center"> <br/>

                              <div id="texto_terminos" className="col-md-12"> 

                              Las partes acuerdan que las políticas de protección de datos se sujetaran en lo dispuesto puesto por la Ley 1581 de 2012, el Decreto 1377 de 2013, la sentencia C-748 de 2011 y los tratados internaciones aplicables a la recolección de información, bases de datos y habeas data. <br/> <br/>
                              
                              El Usuario de los CANALES VIRTUALES, autoriza expresamente por medio del envió de sus datos personales a MEDICALL24 SAS, para que sus datos sean utilizados para conformar una “Base de datos” de propiedad de MEDICALL24 SAS. <br/> <br/>

                              MEDICALL24 SAS anunciará y notificará a los Usuarios de los CANALES VIRTUALES, las Políticas de Protección de Datos de carácter personal, en el cual contiene los términos en lo que se trataran los Datos Personales que se recauden, con el fin de que los usuarios determinen de manera libre y voluntaria si permiten y facilitan el acceso de los datos personales a MEDICALL24 SAS, con ocasión a la utilización o suscripción a los CANALES VIRTUALES. <br/> <br/>

                              En caso de modificación de origen legislativo, reglamentario o jurisprudencial sobre el tratamiento a los datos personales de los Usuarios, MEDICALL24 SAS se reservan el derecho a modificar la presente política para adaptarla a las nuevas disposiciones. En caso de modificación, MEDICALL24 SAS anunciará la modificación por la página web oficial o directamente por los CANALES VIRTUALES, con una antelación prudente. <br/> <br/>

                              MEDICALL24 SAS manifiesta a los usuarios que los datos obtenidos serán objeto de incorporación a las mencionadas bases de datos de carácter personal y sobre las cuales, son titulares y exclusivos responsables. MEDICALL24 SAS suministrará los recursos técnicos y adecuados, de manera previa al Usuario, con el fin de que estos puedan tener acceso a la Política de Privacidad o a cualquier otra información relacionada y así, el Usuario manifestará su consentimiento para el tratamiento de sus datos personales. <br/> <br/>

                              La finalidad de la recolección de información por parte de MEDICALL24 SAS será el mantenimiento de las relaciones contractuales establecidas con el Usuario, así como la gestión, administración, ampliación y mejora de los servicios ofrecidos al Usuario por medio de los CANALES VIRTUALES, así como la adecuación de la aplicación conforme al perfil del cliente. También se utilizara la información para el diseño de nuevos servicios para los usuarios, el envío de actualizaciones, envió de información por medios electrónicos e información comercial sobre productos y servicios a ofrecer. <br/> <br/>

                              MEDICALL24 SAS podrán utilizar la información para enviar acciones publicitarias o promocionales sobre los productos y servicios propios y de terceros, informará al Usuario por medio de correos electrónicos o cualquier otro sistema de comunicación similar, sobre cualquier actividad comercial lícita. <br/> <br/>

                              Si el Usuario no desea recibir comunicaciones e información comercial, así lo deberá manifestar en la opción que se conceda, relativa a la no recepción de información comercial. Sin embargo, al utilizar los CANALES VIRTUALES, el usuario podrá recibir publicidad por medio de banner u otras formas de publicidad, sin que esto altere la información y confidencialidad de la información del usuario. <br/> <br/>

                              MEDICALL24 SAS manifiesta que cuenta con los estándares legales de seguridad en la protección de los Datos Personales, conformes a las disposiciones legales y reglamentarias que la legislación colombiana dispone al respecto. Lo anterior, sin perjuicio de los riesgos inherentes a las actividades realizadas por red de internet, sobre las cuales el Licenciatario se exime de responsabilidad. <br/> <br/>

                              El Usuario acepta la cesión de la información de Datos Personales a terceros, siempre y cuando, esta circunstancia sea advertida por MEDICALL24 SAS en los formularios de recolección de información. Sin embargo, el Usuario se encuentra legitimado para oponerse en cualquier momento sobre las cesiones, conforme a su natural ejercicio de derechos. <br/> <br/>

                              El Usuario, conforme a las disposiciones de orden legal y reglamentario, contenidas en la ley 1581 de 2012, y al decreto 1377 de 2013, podrá ejercer de manera libre y voluntaria sus derechos a la cancelación, rectificación, acceso y oposición sobre la información obtenida. <br/> <br/>

                              MEDICALL24 SAS podrán hacer uso de cookies cuando un usuario utilice los CANALES VIRTUALES. En los cookies, se podrá almacenar pequeñas cantidades de información que se almacenaran en el dispositivo o navegador de cada usuario, con el fin de que el usuario pueda utilizarla posteriormente. Esta opción permitirá al Usuario guardar sus preferencias personales, información técnica y registro de páginas web visitadas. Sin embargo, el uso de cookies estará sujeto a las reglas que para tal fin fije el navegador de selección del Usuario, reglas sobre las cuales MEDICALL24 SAS no tienen influencia alguna. La utilización de los cookies se entiende aceptada por el Usuario una vez descargada la aplicación y se utilicen los servicios e información que esta ofrece. <br/> <br/>

                              El Usuario, previa la utilización de los CANELES VIRTUALES, deberá consultar en la información relativa a la utilización de cookies según el navegador que selección o utilice para la utilización de los CANELES VIRTUALES. Todo lo mencionado, relativo a la utilización de cookies, también le es aplicable a los dispositivos móviles en los cuales se utilice los CANALES VIRTUALES sin necesidad de acudir a navegador, en tal caso, deberá sujetarse a las reglas de su sistema operativo, el cual deberá establecer en la opción ajustes para el normal uso de los cookies. <br/> <br/>

                              Finalmente, MEDICALL24 SAS podrá albergar en la aplicación los web beacon. Los web beacons son imágenes de un pixel por pixel, colocados dentro del código fuente de las páginas. Se pueden utilizar para medir el tráfico de usuarios que visiten una página web. La obtención de información sobre visitantes podrá utilizarse para compartir información con terceros como terceros o anunciantes, lo anterior bajo el estricto cumplimiento de las normas legales y reglamentarias. <br/> <br/>

                              Los derechos de los usuarios, relativos a la corrección, acceso, rectificación, oposición y cancelación podrán ser ejercidos por el Usuario ante MEDICALL24 SAS, enviando un mensaje de datos al correo electrónico soporte.medicall24.com.co <br/> <br/>

                              </div>
                                                  
                          
                          </div>

                          <div className="row justify-content-md-center"> <br/>
      
                              <div  className="col-md-10">

                                    <div className="form-group form-check">
                                      <input type="radio" className="form-check-input" id="exampleCheck1" onClick={this.GetTerminosstado} value="true" checked={this.state.checkedT}   />
                                      <label id="check_terminos" className="form-check-label" htmlFor="exampleCheck1" >  Acepto los Términos y condiciones de uso y la Política de Protección de Datos </label>
                                    </div>

                              </div>
                                                  
                          
                          </div>

                            
                              
                    </div> 
    
                    <div className="modal-footer justify-content-between">                                   
                    
                          <button type="button" className="btn btn-danger" data-dismiss="modal"  onClick={this.GetTerminos_desmarcados}>No acepto</button>            
              
                          <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={this.state.active_button_terminos} > Continuar</button>  
                             
                    </div>

                  </div>
                  
                </div>
                
              </div>    
          

        </div>
         
      )

  }  
  
  
}

function Firma(){

  let sigPad = useRef({})

  return(
    <div>
      
         <button type="button" className="btn btn-primary" onClick={()=> $('#modal-firma').modal('show')}> Firmar contrato</button>
          
    
          <div className="modal fade" id="modal-firma">
            
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title"><b>Firmar contrato</b></h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
  
                  <div className="modal-body">

                    <SignatureCanvas canvasProps={{ 
                      className: 'firmacanvas' }}
                      ref={sigPad}
                    />                       
                      
                  </div> 
  
                  <div className="modal-footer justify-content-between">      
                                      
                        <button type="button" className="btn btn-primary" > Guardar</button>  

                        <button type="button" className="btn btn-success" > Limpiar</button>         
                  
                        <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>            
            
                           
                  </div>
                </div>
                
              </div>
                    
          </div>

    </div>

    
  )

}






export default Pagos_sumitech;