import React, {Component} from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import moment from "moment";
import * as ReactBootStrap from 'react-bootstrap';


import megusto from '../tools/images/iconomegustogris.svg'
import nomegusto from '../tools/images/icononomegusto.svg'
import meinteresa from '../tools/images/iconomeinteresa.svg'

import Reaccion_megusto from '../tools/images/iconomegusto.svg'
import Reaccion_nomegusto from '../tools/images/icononomegustomagenta.svg'
import Reaccion_meinteresa from '../tools/images/iconomeinteresamagenta.svg'

import cargamultimedia from '../tools/images/iconocargarfoto.svg'

import iconocargarvideo from '../tools/images/iconocargarvideo.svg'

import emptypage from '../tools/images/carpeta.gif';
import img_vacia from '../tools/images/imagen portada.jpg';
import Buscar from '../tools/images/Buscar';

import AwesomeSlider from 'react-awesome-slider';
import Estilo from 'react-awesome-slider/dist/styles.css';


import '../tools/css/Publicaciones.css';

import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';


import $ from 'jquery'; // <-to import jquery
import 'bootstrap';

const ApiURL="https://medicallapi-test.azurewebsites.net";
var institution_id =sessionStorage.getItem('id_institution');
var mensaje='';
var buscar_publicacion='';
var tipo_publi='publico';
var num_file='Ningún archivo seleccionado';
var files=[];
var id_publicacion=0;
var tipo_reaccion='';
var textotipo_reaccion='';
var num_reaccion=0;
var icono_reaccion='';
var info_usuario='';
var img_completa='';
var nomInst='';
var fecha_publi=img_vacia;
var pagina=1;
var publica=[]
var id_detalle_publi='textarea_descripcion'


class Publicaciones extends Component {

  

  constructor(props)
    {
     super()
      this.state={
        show:false,
        DataIntitucion: [],    
        DataMembresia: [],   
        Publicaciones:[],
        Pager_Publicacione: [],
        imgs:[],
        Reacciones:[],
        Detalle_publi:[],
        pager_infopublicacione:'',
        loading: false,
        loading_button: true,
        active_button: true,
        loading_button_firma: true,
        active_button_firma: true,
        //detalle_publicacion:'textarea_descripcion',
          
        form:{   
        nombre_inst:'',     
        id_inst:'',
        descrip_publi:'',
        selectedFile:null,
        id_publi:'',
        descrip_publi_edit:'',
        buscar_publicaciones:''
        
        }
        
      }
    }



    handleChange=async e=>{
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
         console.log(this.state.form);
    } 



    GetImge = (i) =>{
      if(i.target.files.length > 10){

        store.addNotification({
          title: 'Atención',
          message: "No puede seleccionar más de 10 archivos para una publicación ",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

      this.setState({imgs:i.target.files})
      files = i.target.files;     
      
      //files_view=URL.createObjectURL(i.target.files[0]);
      //console.log(i.target.value)
      num_file="Ha seleccionado "+(i.target.files.length)+" archivo(s)"
      $('#input_file').hide();
      $('#tabla_imgs').show();
      $('#btn_cancelar_seleccion ').show();

      }
           
     
    }

    GetVideo = (v) =>{
      if(v.target.files.length > 10){

        store.addNotification({
          title: 'Atención',
          message: "No puede seleccionar más de 10 archivos para una publicación ",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

      this.setState({imgs:v.target.files})
      files = v.target.files;     
      
      //files_view=URL.createObjectURL(i.target.files[0]);
      //console.log(i.target.value)
      num_file="Ha seleccionado "+(v.target.files.length)+" archivo(s)"
      $('#input_file').hide();
      $('#tabla_imgs').show();
      $('#btn_cancelar_seleccion ').show();

      }
           
     
    }

    Tipo_Publi = (p) =>{
      //this.setState({tipo_publicacion:p.target.value})
      console.log(p.target.value)
      tipo_publi=(p.target.value)
     
    }


    BuscarPublicacion = (bp) =>{
      this.setState({buscar_publicaciones:bp.target.value})
      console.log(bp.target.value)
      buscar_publicacion=(bp.target.value)
      this.searchDatapublicacion(buscar_publicacion)
     
    }

    
    
    handleModalpublicacion()
    { 
      $('#input_file').show();  
      $('#tabla_imgs').hide();  
      $('#btn_cancelar_seleccion ').hide();
      
      $("#modal-agregar-publicacion input").val(""); 
      $("#modal-agregar-publicacion textarea").val("");
      $('#modal-agregar-publicacion').modal('show'); // <- to show modal publicacion

      this.setState({
        form:{
        descrip_publi:'' 
      }
    })

      num_file='Ningún archivo seleccionado';

    }


    handledrop_form()
    {   
      
      $('#tabla_imgs').hide();
      $('#btn_cancelar_seleccion ').hide();  
      
      $("#modal-agregar-publicacion input").val(""); 
      $("#modal-agregar-publicacion textarea").val("");


      this.setState({
        form:{
        descrip_publi:'' 
      }
    })

      num_file='Ningún archivo seleccionado';

    }


    cencelar_seleccion()
    { 
      $('#input_file').show();      
      $('#tabla_imgs').hide(); 
      $('#btn_cancelar_seleccion ').hide();

      $("#modal-agregar-publicacion input").val(""); 

      this.setState({
        form:{
        descrip_publi:this.state.form.descrip_publi 
      }
    })


      num_file='Ningún archivo seleccionado';

    }


    empty_page_hide(){
      $('#tabla_p').hide();     
      $('#empty_page').hide();
      $('#text_page').hide();
      
    }


    handleModaleliminarpublicacion()
    {      
      $('#modal-eliminar_publicacion').modal('show'); // <- to show modal crear sede 
    }


    handleModalpublicacionedit()
    {     
      $('#modal-publicacionedit').modal('show'); // <- to show modal editar contrato 
    }


    handleModalreacciones()
    {      
      $('#modal-reacciones').modal('show'); // <- to show modal editar contrato 
    }


    geolocalizacion(){
      if (navigator.geolocation)
      {
      	alert("Geolocalización Activa")
      }
      else
      {
      	alert("Geolocalización Inactiva")
      }
    }
    
   
    componentDidMount(){
     
      $('#descripcion').hide();
      $('#descripcion_vermas').hide(); 
      this.Datos_Institucion();
      this.loadMorePublicacion();
      this.ver_menos();
    }

    ver_mas(){

      this.setState({ detalle_publicacion: this.state.active = 'textarea_descripcion_all' }) 
      $('#btn_vermas').hide();  
      $('#btn_vermenos').show();  
     
    }

    ver_menos(){

      this.setState({ detalle_publicacion: this.state.active = 'textarea_descripcion' }) 
      $('#btn_vermas').show();  
      $('#btn_vermenos').hide();     
     
    }



    seleccionar_publicacion=(publi)=>{
      this.setState({
        form:{
          id_publicacion:publi.id,  
          descrip_publi_edit:publi.description
        }
      })
      //var  dato  = solicitud.user.identification;
      //alert(dato);
    }


    Datos_Institucion(){    
      
      axios.get(ApiURL+"/api/Institutions/GetInstitution/"+institution_id)
      .then((response) => {
        console.log(response);
        this.setState({DataIntitucion: response.data.institution})       
        this.setState({DataMembresia: response.data.membership})
        nomInst=this.state.DataIntitucion.name.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));

      })
      .catch((error) => {
        console.log(error);
      })
    }


    Cargar_publicaciones(){  

      this.empty_page_hide();
      
      this.setState({ loading: this.state.loading = false })
    
      axios.get(ApiURL+"/api/Posts/GetAllPosts")
      .then((response) => {
        //console.log(response);

        this.setState({Publicaciones: response.data.data})
        this.setState({Pager_Publicacione: response.data.pager.items})
        this.setState({pager_infopublicacione: response.data.pager.showData})

        if(this.state.Publicaciones.length==0){
          $('#tabla_p').hide();
          $('#empty_page').show();
          $('#text_page').show();
        }else{
          $('#tabla_p').show();
          $('#empty_page').hide();
          $('#text_page').hide();
        }

        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })

    }


    loadMorePublicacion() {
      //var page=page_pendien.pagina
      //this.empty_page_hide();
      this.setState({ loading: this.state.loading = false })
      
      axios.get(ApiURL+"/api/Posts/GetAllPosts?numeroPagina="+pagina)
      .then((response) => {
        console.log(response);

        this.setState({Publicaciones: response.data.data})
        publica=publica.concat(this.state.Publicaciones)
        this.setState({Pager_Publicacione: response.data.pager.items})
        this.setState({pager_infopublicacione: response.data.pager.showData})

        if(this.state.Publicaciones.length==0){
          //$('#tabla_p').hide();
          $('#empty_page').show();
          $('#text_page').show();
        }else{
          //$('#tabla_p').show();
          $('#empty_page').hide();
          $('#text_page').hide();
        }

       
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
    
      }

      searchDatapublicacion(buscar_pendiente) {

        this.setState({ loading: this.state.loading = false })
        
        axios.get(ApiURL+"/api/Posts/GetAllPosts?texto="+buscar_pendiente)
        .then((response) => {

        //console.log(response);
        this.setState({Publicaciones: response.data.data})
        this.setState({Pager_Publicacione: response.data.pager.items})
        this.setState({pager_infopublicacione: response.data.pager.showData})        
  
        
        this.setState({ loading: this.state.loading = true })
        })
        .catch((error) => {
          console.log(error);
        })
      
        }


        Save_publicacion=async()=>{
         
          if( this.state.form.descrip_publi.length==0 || files.length==0 ){
    
            store.addNotification({
              title: 'Atención',
              message: "Faltan datos por ingresar",
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })
    
          }else{
    
            this.setState({ loading_button: this.state.loading_button = false })
            this.setState({ active_button: this.state.active_button = false })
    
            let formData = new FormData();
            
            
            
            formData.append('Description', this.state.form.descrip_publi)  
            for (const e of files) { 
              formData.append('Resources', e, e.files);
           }      
            formData.append('public', tipo_publi)
    
            await axios.post(ApiURL+"/api/Posts/Create", formData,{
    
              headers: {
                'Content-Type': 'multipart/form-data'            
              }
    
            }) 
      
            .then(response=>{
    
              console.log(response.data)
                     
              //this.setState({
                //Type_servicenew:response.data.agreement.typeServiceAgreements,
                //Servi_convenionew:response.data.agreement.serviceAgreements
              //})
              //alert(id_convenio);
              //const [text, setText] = LocalStorage(text, '')
              //setText(dato);
                     
              //window.location.href="/home";
              store.addNotification({
                title: 'Muy bien',
                message: "La publicación se guardó",
                type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
                container: 'top-right',                // where to position the notifications
                animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                dismiss: {
                  duration: 10000,
                  showIcon:true
                }
              })
    
              this.setState({ loading_button: this.state.loading_button = true })
              this.setState({ active_button: this.state.active_button = true })
              
              $('#modal-agregar-publicacion').modal('hide');
              this.Cargar_publicaciones();
              
            })
            .catch(error=>{
              
              if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                //console.log(error.response.data.message);
                //console.log(error.response.status);
                //console.log(error.response.headers);
                mensaje=error.response.data.message
                
                store.addNotification({
                  title: 'Atención',
                  message: mensaje,
                  type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
                  container: 'top-right',                // where to position the notifications
                  animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                  animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                  dismiss: {
                    duration: 10000,
                    showIcon:true
                  }
                })
    
                this.setState({ loading_button: this.state.loading_button = true })
                this.setState({ loading_button: this.state.active_button = true })
    
            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', error.message);
            }
            //console.log(error.config);
          
          })
    
          }
      
        }

      

        Editar_publicacion=async()=>{

          if(this.state.form.id_publicacion=='' || this.state.form.descrip_publi_edit=='' || this.state.form.descrip_publi_edit.length==0){
    
            store.addNotification({
              title: 'Atención',
              message: "Faltan datos por ingresar",
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })
    
          }else{
    
            this.setState({ loading_button: this.state.loading_button = false })
            this.setState({ active_button: this.state.active_button = false })
    
            await axios.put(ApiURL+"/api/Posts/EditDescription",  
            {
              id: this.state.form.id_publicacion,
              description:this.state.form.descrip_publi_edit
            } )          
      
            .then(response=>{
              console.log(response.data)
              mensaje  = response.data.message;
              //alert(mensaje);
              //const [text, setText] = LocalStorage(text, '')
              //setText(dato);          
                     
              //window.location.href="/home";
              store.addNotification({
                title: 'Muy bien',
                message: mensaje,
                type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
                container: 'top-right',                // where to position the notifications
                animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                dismiss: {
                  duration: 10000,
                  showIcon:true
                }
              })
    
              this.setState({ loading_button: this.state.loading_button = true })
              this.setState({ active_button: this.state.active_button = true })
    
              this.Cargar_publicaciones();
              $('#modal-publicacionedit').modal('hide');
              
            })
            .catch(error=>{
             
              if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                //console.log(error.response.data.message);
                //console.log(error.response.status);
                //console.log(error.response.headers);
                mensaje=error.response.data.message
                
                store.addNotification({
                  title: 'Atención',
                  message: mensaje,
                  type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
                  container: 'top-right',                // where to position the notifications
                  animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                  animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                  dismiss: {
                    duration: 10000,
                    showIcon:true
                  }
                })
    
                this.setState({ loading_button: this.state.loading_button = true })
                this.setState({ active_button: this.state.active_button = true })
    
            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', error.message);
            }
            //console.log(error.config);
          
          })
            
          }      
      
        }


        Modal_borrar_publicacion(publi){

          id_publicacion  = publi.id;
    
          this.handleModaleliminarpublicacion();
    
        }

    
         Borrar_publicacion=async()=>{
         

          axios.delete(ApiURL+"/api/Posts/Delete/"+id_publicacion)
          .then((response) => {
            console.log(response.data)
              mensaje  = response.data.message;
              //alert(mensaje);
              //const [text, setText] = LocalStorage(text, '')
              //setText(dato);          
                     
              //window.location.href="/home";
              store.addNotification({
                title: 'Muy bien',
                message: mensaje,
                type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
                container: 'top-right',                // where to position the notifications
                animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                dismiss: {
                  duration: 10000,
                  showIcon:true
                }
              })    
              
              this.Cargar_publicaciones();
            
          })
          .catch((error) => {
            console.log(error);
          })
        }


        Cargar_reacciones(publi){  
        
         
          id_publicacion = publi.id;    
          //alert(tipo_reaccion)
          if(tipo_reaccion=='me gustó'){
          
           icono_reaccion=Reaccion_megusto
           textotipo_reaccion="Esta publicación le gustó a"
           num_reaccion=publi.likes
          }else{
            if(tipo_reaccion=='no me gustó'){
              
              icono_reaccion=Reaccion_nomegusto
              textotipo_reaccion="Esta publicación no le gustó a"
              num_reaccion=publi.notLikes
            }else{
              icono_reaccion=Reaccion_meinteresa
              textotipo_reaccion="Esta publicación le interesa a"
              num_reaccion=publi.interested
            }
          }     
        
          axios.get(ApiURL+"/api/Posts/GetInfoReact?postId="+id_publicacion+"&type="+tipo_reaccion)
          .then((response) => {
          console.log(response.data);
    
            this.setState({Reacciones: response.data})

            this.handleModalreacciones();
           
          })
          .catch((error) => {
            console.log(error);
          })
    
        }


        Detalle_publicacion(publi){ 
        
         
          fecha_publi=publi.date
          id_publicacion = publi.id;    
            
        
          axios.get(ApiURL+"/api/Posts/GetPost?id="+id_publicacion)
          .then((response) => {
          console.log(response.data.resourcePosts);
    
            this.setState({Detalle_publi: response.data.resourcePosts})

            //$('#modal-detallepubli').modal('show');

            

            //alert(this.Detalle_publi.image);

            //this.handleModalreacciones();
           
          })
          .catch((error) => {
            console.log(error);
          })
    
        }

        Detalle_imagen(file){ 


          img_completa=img_vacia

          img_completa=(file.image)

          let posicion = file.image.indexOf(".mp4?");
          if(posicion !== -1){

            $('#imgsdetalle').hide();
            $('#vdsdetalle').show();

          } else{

            $('#imgsdetalle').show();
            $('#vdsdetalle').hide();

            $('#modal-detallepubli').modal('show'); 

          }      
    
        }

        DropDetalle_imagen(){ 

          img_completa=img_vacia
          
          //$('#imgsdetalle').hide();
          //$('#vdsdetalle').hide();
    
        }

 

    render(){  
      const {form} = this.state;

      const scrollToEnd = () =>{
        pagina++;
        //publica=publica
        this.loadMorePublicacion();
      }

      window.onscroll=function(){
       
        if(window.innerHeight + document.documentElement.scrollTop
          === document.documentElement.offsetHeight
          ){

            scrollToEnd()

        }
      }

     return (

     
      <div className="content-wrapper">

            <div className="col-12 col-sm-12">

                  <div className="row">
              
                          <div className="col-sm-12">  
                          <br></br>    
                             <div className="text-center" id="title"> <h6><b> PUBLICACIONES </b>  </h6> </div>      
                          </div>
                          {/* <div className="col-sm-2">  
                          <br></br>                 
                                    <button id="btn_crear" className="btn btn-edit" onClick={()=>{this.handleModalpublicacion()}}> Publicar
                                          <svg width="20" height="15" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                          <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                          </svg> 
                                    </button>
                          </div>                 */}
                         
                  </div>

                  <div className="card card-primary card-outline card-tabs">

                        <div className="card-body">

                              <div className="d-flex justify-content-center">
                    
                                  {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                              
                              </div>
                             
                              <div className="card" id="tabla_p"/*id="tabla_publicaciones"*/> 
      
      
                                    <div className="row">
                          
                                          <div className="col-sm-3">
                                                    <div className="input-group input-group-sm mb-3 mr-sm-2">
                                                       <div className="input-group-prepend">
                                                         <div className="input-group-text"><Buscar/></div>
                                                       </div>
                                                       <input type="search" className="form-control" placeholder="Buscar" name="buscar_solicitadas" onChange={this.BuscarPublicacion}/>
                                                    </div>                                
                                          </div>
                                          
                                    </div>
                                    
      
                                    <div className="row">
      
                                          {publica.map(publi=>{

                                            var descripcion=''
                                            var descripcion_all=''
                                            var ver_mas=''
                                            var ver_menos=''
                                            var tipo_publi=''
                                            var Archivos=[]
                                            var likes=<button className="btn btn-default btn-xs" id="btn_noreaccion" >Me gustó <img src={megusto} alt="logo" width= "25"/></button>
                                            var notlikes=<button className="btn btn-default btn-xs" id="btn_noreaccion" >No me gustó <img src={nomegusto} alt="logo" width= "22"/></button>
                                            var interested=<button className="btn btn-default btn-xs" id="btn_noreaccion_interesa" >Me interesa <img src={meinteresa} width= "25"/></button>
                                            Archivos=publi.resourcePosts

                                            if(publi.likes > 0){
                                              likes=<button className="btn btn-default btn-xs position-relative" id="btn_reaccion"  >Me gustó &nbsp; <span className="position-absolute top-0 start-100 translate-middle badge bg-danger"> {publi.likes}</span> <img src={Reaccion_megusto} alt="logo" width= "25"/></button>
                                            }
                                            if(publi.notLikes > 0){
                                              notlikes=<button className="btn btn-default btn-xs position-relative" id="btn_reaccion"  >No me gustó &nbsp; <span className="position-absolute top-0 start-100 translate-middle badge bg-danger"> {publi.notLikes}</span> <img src={Reaccion_nomegusto} alt="logo" width= "22"/></button>
                                            }
                                            if(publi.interested > 0){
                                              interested=<button className="btn btn-default btn-xs position-relative" id="btn_reaccion_interesa"  >Me interesa &nbsp; <span className="position-absolute top-0 start-100 translate-middle badge bg-danger"> {publi.interested}</span> <img src={Reaccion_meinteresa} width= "25"/></button>
                                            }

                                            if(publi.description.length >= 150){
                                              descripcion= <p id="textarea_descripcion" className="card-text">{publi.description}</p>
                                              //descripcion_all= <p id="textarea_descripcion_all" className="card-text">{publi.description}</p>
                                              //ver_mas=<button className="btn btn-default btn-xs" id="btn_vermas" onClick={()=> this.ver_mas()}  > ...Ver mas  </button>
                                              //ver_menos=<button className="btn btn-default btn-xs" id="btn_vermenos" onClick={()=> this.ver_menos()}  > ...Ver menos  </button>  
                                             
                                             
                                            }else{
                                              if(publi.description.length < 149){
                                                descripcion=<p id="textarea_descripcion_vermas" className="card-text">{publi.description}</p>
                                                ver_mas=''
                                              }
                                               
                                            }

                                            if(institution_id==publi.institution.id){

                                              tipo_publi='Publicado para: '+publi.public

                                            }else{

                                              tipo_publi=''

                                            }
                                            
                                            return(  
                                                                                                  
                                                  <div className="col-md-8" id="publicaciones"> 

                                                         <img id="image_publi" src={publi.institution.avatar} width= "60" className="rounded-circle" />  
                                                         <h5><small>{publi.institution.name}</small> </h5>                                                     
                                                         <p><small className="text-muted"><Moment fromNow locale='es'>
                                                                                             {publi.date}
                                                                                           </Moment> 
                                                            </small>
                                                         </p>
                                                              <AwesomeSlider cssModule={Estilo}>                                                        
                                                                  
                                                                  {Archivos.map(file=>{ return(                                                                     
                                                                    
                                                                      <div data-src={file.image} onClick={()=>{this.Detalle_publicacion(publi);{this.Detalle_imagen(file)}} } />
                                                          
                                                                  )})}
                                                            
                                                              </AwesomeSlider>
                                                                                                                             
                                                        
                                                        <div className="card-body">
                                                              <div className="modal-footer justify-content-between">   
                                                                   
                                                                    {likes} 
                                                                    {notlikes}
                                                                    {interested}
                                                                    {/* <div className="btn-group dropright">
                                                                        <button type="button" className="btn btn-default" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                                  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                                                </svg>
                                                                        </button>
                                                                        <div className="dropdown-menu">                                                                          
                                                                          <a className="dropdown-item" id="dropdown_options" href="#" onClick={()=>{this.handleModalpublicacionedit();this.seleccionar_publicacion(publi)}}>Editar</a>
                                                                          <a className="dropdown-item" id="dropdown_options" href="#" onClick={()=> this.Modal_borrar_publicacion(publi)}>Eliminar</a>                                                                          
                                                                        </div>
                                                                    </div>                                                                    */}
                                                              </div>

                                                             
                                                              {descripcion}   
                                                              {descripcion_all}
                                                              {ver_mas} {ver_menos} <br/>        
                                                              {tipo_publi} 
                                                            
                                                                                                                             
                                                        </div>
                                                  </div>  
                                                                                       
                                                )
                                              }
                                            )
                                          }                                       
                                      
                                          
                                    </div> 
                                                                       
                                    {/* <span>{this.state.pager_infopublicacione}</span>
                                    <nav className="nav">
                                    
                                        <ul className="pagination">
                                            {this.state.Pager_Publicacione.map(page_pendien=>{
                                            
                                              var activo=page_pendien.activo
                                              if(activo==true){
                                                activo='page-item active'
                                              }else{
                                                activo='page-item'
                                              }
                                             // alert(activo)
                                              
                                             return(                          
                                                      
                                                     <li className={activo}>
                                                       <a className="nav-link" onClick={()=>{this.loadMorePublicacion(page_pendien)}}>{page_pendien.texto}</a>
                                                     </li>                                            
                                               )
                                              }
                                             )
                                            }
                                        </ul>
                                              
                                    </nav> */}
                                
                              </div>

                              <div className="d-flex justify-content-center">                              
                                   <img id="empty_page" src={emptypage} alt="logo"  width= "200"/>                             
                              </div>

                              <div className="d-flex justify-content-center">                  
                                   <h5 id="text_page"> <b>No hay información que mostrar</b> </h5>                          
                              </div>

                        </div>
      
                  </div>

            </div>

            <div className="modal fade" id="modal-agregar-publicacion" data-backdrop="static">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="col-11 modal-title text-center"><b>Crear publicación</b></h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> this.handledrop_form()}>
                        <span aria-hidden="true">×</span>
                    </button>                    
                  </div>
                  <div className="modal-body">
        
                      <div className="row">
        
                          <div className="col-md-12">
        
                                <div className="form-group">                    
                                    <label >Descripción de la publicación:</label>                           
                                    <textarea className="form-control" rows="4" id="descrip_publi" name="descrip_publi" placeholder="Ingrese el texto de la publicación..."  onChange={this.handleChange} >{form.descrip_publi}</textarea>
                                    
                                </div>
        
                          </div>
        
                      </div> 


                      <div className="row">
        
                          <div className="col-md-12">

                                <div className="form-group">                    
                                  <label >Publicar para:</label>
                                  <select onChange={this.Tipo_Publi} className="form-control" id="tipo_publicacion" name="tipo_publicacion">
                    
                                    <option  value="publico">Publico</option>
                                    <option  value="profesionales">Profesionales de la salud</option>   
                                    <option  value="pacientes">Pacientes</option>                        
                                  
                                  </select>                      
                                </div> 
                  
                          </div>
                      
                      </div>

                      <div className="form-group text-center">                    
                          <label >Seleccione los archivos:</label>
                         
                      </div>
                                     
        
                      <div className="row" id="input_file">               
                                        
                          <div className="col-md-12 col-auto text-center"> 

                                <div className="form-group">    


                                    <div className="custom-file">
                                          <input type="file" name="selectedFile" id="exampleInputFile" multiple="true" className="inputfile inputfile-5" accept="image/jpg,image/JPG,image/jpeg,image/bmp,image/png,image/PNG,video/avi,video/mp4,video/wmv" onChange={this.GetImge}/>
                                      
                                          <label htmlFor="file-5" for="exampleInputFile">
                                            <figure>
                                            <img src={cargamultimedia}/>
                                            </figure>
                                            {/*<span className="iborrainputfile">{num_file}</span>*/}
                                          </label>
                                    </div>
                                                         
                                </div>                                                           
                          </div> 

                                         
            
                      </div> 

                      <div className="row" id="tabla_imgs"> 

                          <div className="col-md-12">                                    
                       
                                {this.state.imgs && [...this.state.imgs].map((file)=>{

                                  let src,preview,type=file.type;
                                   
                                  // Coincide con la cadena que comienza con image /
                                  if (/^image\/\S+$/.test(type)) {
                                      src = URL.createObjectURL(file)
                                      preview = <img id="imgs" src={src} alt="logo" width= "430" className="rounded" />
                                  }else{
                                        if (/^video\/\S+$/.test(type)) {
                                          src = URL.createObjectURL(file)
                                          preview = <video id="vdos" src={src} width= "430" loop controls />
                                      }
                                  }
                                  return(                              
                                   preview
                                )})}

                          </div>                      

                      </div>

                      {num_file}                      
                 
                  </div>

                  <div className="modal-footer justify-content-between">        
                    <button type="button" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.Save_publicacion()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}Publicar</button>
                    <button type="button" id="btn_cancelar_seleccion" className="btn btn-default" disabled={!this.state.active_button} onClick={()=> this.cencelar_seleccion()}>Cancelar selección</button>
                    <button type="button" className="btn btn-danger" disabled={!this.state.active_button} data-dismiss="modal" onClick={()=> this.handledrop_form()}>Cerrar</button>
                  </div>
                </div>
               
              </div>
             
            </div>


            <div className="modal fade" id="modal-publicacionedit">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title"><b>Editar publicación</b></h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    
                        <div className="row">
            
                              <div className="col-md-12">
                                    <div className="form-group">                    
                                      <label >Descripión de la publicación:</label>
                                      <textarea className="form-control" rows="6" id="descrip_publi_edit" name="descrip_publi_edit" value={form.descrip_publi_edit}  onChange={this.handleChange} >{form.descrip_publi_edit}</textarea>                     
                                    </div>                 
                              </div>                   
            
                        </div>            
            
                  </div>
            
                  <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.Editar_publicacion()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar</button>
                    <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>        
                  </div>
                </div>
                
              </div>
              
            </div>


            <div className="modal fade" id="modal-detallepubli" data-backdrop="static">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">
                    <img id="image_publi" src={this.state.DataIntitucion.avatar} width= "60" className="rounded-circle" />
                    </h4>
                    <h5><small>{nomInst}</small> <br/> <small><Moment fromNow locale='es'>
                             {fecha_publi}
                          </Moment> 
                    </small> </h5> 
                    
                    
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true" >×</span>
                    </button>
                  </div>

                  <div className="modal-body">                    
                      

                          <div className="row justify-content-md-center" id="tabla_imgsdetalle"> 
          
                                <div className="col col-lg-12">    
                                    
                                
                                  <img id="imgsdetalle" src={img_completa} alt="logo" className="rounded"/>                
                                  {/*<video id="vdsdetalle" src={img_completa} loop controls />*/}                              
                               
                                </div>   
                          </div>        
                      
            
                  </div>
            
                </div>
                
              </div>
              
            </div>


            <div className="modal fade" id="modal-eliminar_publicacion" data-backdrop="static">

                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Atención</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" onClick={()=> this.DropDetalle_imagen()}>×</span>
                      </button>
                    </div>
                    <div className="modal-body">                 
  
                          <div className="col-md-12">

                            Si eliminas la publicación no se podrá recuperar.

                            <br></br>
                            <br></br>
  
                            ¿Estás seguro que la deseas eliminar?
  
                          </div>
                                 
                    </div>
          
                          <div className="modal-footer justify-content-between">        
                             <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=> this.Borrar_publicacion()}>Si</button>
                             <button type="button" className="btn btn-danger" data-dismiss="modal">No</button>
                          </div>
                  </div>                
                </div>
            
              </div>


              <div className="modal fade" id="modal-reacciones" data-backdrop="static">

                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title"><b>{textotipo_reaccion+" "+num_reaccion+" persona(s)"}</b></h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    
                    <div className="modal-body" id="tabla_reacciones" >
                      
                        {this.state.Reacciones.map(reaccion=>{

                          if(tipo_reaccion=='me interesa'){
                            info_usuario=<div> <spam>Cel: {reaccion.user.phone}</spam><br></br>  
                            <spam>Correo: {reaccion.user.email}</spam> 
                            </div>
                          }else{
                          info_usuario='' 
                          }

                         

                          return( 
                         
                         
  
                                <a className="dropdown-item">
                                 
                                  <div className="media">
                                    <img id="image_publi" src={reaccion.user.avatar}  width= "40" className="mr-3 rounded-circle"  />
                                    
                                    <div className="media-body">

                                      <h3 className="dropdown-item-title">
                                      <b>{(reaccion.user.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))} </b>
                                        <span className="float-right text-sm text-danger"><img src={icono_reaccion} alt="logo" width= "25" /></span>
                                      </h3>
                                      {info_usuario}
                                      
                                      <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> <Moment fromNow locale='es'>
                                          {reaccion.date}
                                          </Moment></p>
                                    </div>
                                    
                                  </div>

                                  <div className="dropdown-divider" > </div>
                                
                                </a>                              

  

                               )
                              }
                            )               
                          }
                                 
                    </div>
          
                          <div className="modal-footer">        
                             <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                          </div>
                  </div>

                </div>
            
              </div>



      </div>
      
    )

  }
  
}



export default Publicaciones;