import React, {Component} from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import * as ReactBootStrap from 'react-bootstrap';
import '../tools/css/Agendas.css';
import '../tools/css/Home.css';
import Profesionales from '../API/Profesionales';
import DiasSemana from '../API/DiasSemana';
import BtnEdit from '../components/BtnEdit';
import Logo_nuevo from '../tools/images/Nuevo';
import Info from '../tools/images/Info';
import Logo_agenda from '../tools/images/Agenda';
import BtnAdd from '../components/BtnAdd';
import BtnDelete from '../components/BtnDelete';
import emptypage from '../tools/images/carpeta.gif';
import Buscar from '../tools/images/Buscar';

import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';

import $ from 'jquery'; // <-to import jquery
import 'bootstrap';

const ApiURL="https://medicallapi-test.azurewebsites.net";
var institution_id =sessionStorage.getItem('id_institution');
var mensaje='';
var id_profe='0';
var id_especialidad='0';
var id_sede='0';
var id_sededit='0'
var nom_sededit='';
var id_especialidad_edit='0';
var dia_intervalo='null';
var agendaid='0';
var nom_agenda='';
var buscar_agenda='';
var buscar_agenda_inactiva='';
var buscar_agenda_vencida='';
var buscar_agenda_anulada='';
var dato=0;
var btn_activar='';
var estado_agenta='';
var est_agenda='';
var id_tipo_servi=0;



class Agendas extends Component {

  constructor()
    {
     super()
      this.state={
        Horario: [], 
        Parametro:[],
        Agendas: [],         
        Pager_Agendas: [],
        pager_infoagendas:'', 
        Agendas_inactivas: [],         
        Pager_Agendas_inactivas: [],
        pager_infoagendas_inactivas:'', 
        Agendas_vencidas: [],         
        Pager_Agendas_vencidas: [],
        pager_infoagendas_vencidas:'',
        Agendas_anulada: [],         
        Pager_Agendas_anulada: [],
        pager_infoagendas_anulada:'',
        loading: false,
        Sedes:[] ,
        profesionales: [],
        IntervaloAgenda: [],
        Especialidades:[],
        Servicios: [],
        loading_button: true,
        active_button: true,
        active: true,
        loading_button_intervalo: true,
        active_button_intervalo: true,
        loading_button_eliminar: true,
        active_button_eliminar: true,
        active_check:true,
        form:{ 
          nom_agenda: '',         
          fecha_ini:'',
          fecha_fin:'',
          id_agenda_edit:'',
          nom_agenda_edit:'',
          fechaini_edit:new Date,
          fechafin_edit:new Date,
          inicio:'',
          fin:'',
          intervalo:'',
          espe:'0',
          buscar_agendas:'',
          buscar_agendas_inactiva:''              
          }
      }
    }

    Selectdrop(){
      if(id_profe=='0' && this.state.form.fecha_ini!=''){
        $("#api_profesional").val($('#api_profesional > option:first').val()); 
      }
    }

    handlerSelectdrop(){

      $("#nom_agenda").val("");
      $("#fecha_ini").val("");
      $("#fecha_fin").val("");
      $("#profesionales").val($('#profesionales > option:first').val());
      $("#sede").val($('#sede > option:first').val());
      $("#espe").val($('#espe > option:first').val());
      $("#div_agenda1").find("*").prop('disabled', false);
      $("#div_agenda2").find("*").prop('disabled', false);
      $("#div_agenda3").find("*").prop('disabled', false);

      id_profe='0';
      id_especialidad='0';
      id_sede='0';

      this.setState({Especialidades: []})
     
    }


    handlerdropIntervalo(){


      $("#inicio").val("");
      $("#fin").val("");
      $("#dia_agenda").val($('#dia_agenda > option:first').val());
      $("#tipo_servicio").val($('#tipo_servicio > option:first').val());
      $("#intervalo").val("");
      

      dia_intervalo='null';
      id_tipo_servi=0;
     
     
     
    }

    
    handleChange=async e=>{
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
         console.log(this.state.form);    
         this.Selectdrop();     
    }

    Campo_numerico(){
      $(".camponum").keypress(function(tecla)
      {
        if(tecla.charCode<48 || tecla.charCode>57){
         
          return false;

        }
      })
    }

    componentDidMount(){

      $('[data-toggle="tooltip"]').tooltip()
      this.Listar_horario_atencion();
      this.CargarAgendas();
      this.Cargar_sedes();
      this.Cargar_Tipo_servicios()

    }

    GetEspecialidad = (n) =>{
      id_especialidad='0';
      this.setState({espe:n.target.value})
      console.log(n.target.value)
      id_especialidad=(n.target.value)
     
    }

    GetSede = (sd) =>{
      id_sede="0";    
      console.log(sd.target.value)
      id_sede=(sd.target.value)
     
    }

    GetSededit = (sde) =>{
      id_sededit="0";    
      console.log(sde.target.value)
      id_sededit=(sde.target.value)
      
      $('#cb_sededit').hide();
     
    }

    BuscarAgenda = (bc) =>{
      
      console.log(bc.target.value)
      buscar_agenda=(bc.target.value)
      this.searchDataagenda(buscar_agenda)     
    }

    BuscarAgenda_inactiva = (bci) =>{
      
      console.log(bci.target.value)
      buscar_agenda_inactiva=(bci.target.value)
      this.searchDataagenda_inactiva(buscar_agenda_inactiva)     
    }


    BuscarAgenda_vencidas = (bcv) =>{
      
      console.log(bcv.target.value)
      buscar_agenda_vencida=(bcv.target.value)
      this.searchDataagenda_vencidas(buscar_agenda_vencida)     
    }

    BuscarAgenda_anulada = (bca) =>{
      
      console.log(bca.target.value)
      buscar_agenda_anulada=(bca.target.value)
      this.searchDataagenda_anuladas(buscar_agenda_anulada)     
    }

    GetMedico = (med) =>{
     
      console.log(med.target.value)
      id_profe=(med.target.value)
      //this.Cargar_Especialidades(id_profe);
      this.Cargar_Especialidades(id_profe)
     
    }

    GetTiposervi = (ts) => {
      id_tipo_servi=(ts.target.value)
      console.log(ts.target.value)      

      //alert(id_tipo_servi)
    }

    GetDiaAgenda = (da) => {
      dia_intervalo=(da.target.value)
      console.log(da.target.value)      

      //alert(id_tipo_servi)
    }


    Cargar_Especialidades(id_profe){   
      
      id_especialidad='0';

      axios.get(ApiURL+"/api/Specialties/GetSpecialtiesProfessional/"+id_profe)
      .then((response) => {
        console.log(response);
        this.setState({Especialidades: response.data})
        
      })
      .catch((error) => {
        console.log(error);
      })
    }

    Cargar_Tipo_servicios(){    
    
      axios.get(ApiURL+"/api/TypeServices")
      .then((response) => {
        console.log(response.data.filter(item => item.id !== 1));
        this.setState({Servicios: response.data.filter(item => item.id !== 1)})
      })
      .catch((error) => {
        console.log(error);
      })
    }


    empty_page_hide(){
      $('#tabla1').hide();     
      $('#empty_page').hide();
      $('#text_page').hide();

      $('#tabla2').hide();     
      $('#empty_page2').hide();
      $('#text_page2').hide();

      $('#tabla3').hide();     
      $('#empty_page3').hide();
      $('#text_page3').hide();

      $('#tabla4').hide();     
      $('#empty_page4').hide();
      $('#text_page4').hide();
      
    }


    Listar_horario_atencion(){   

      this.setState({ loading: this.state.loading = false })
      axios.get(ApiURL+"/api/WorkingHours/GetWorkingHours")
      .then((response) => {
        console.log(response);

        this.setState({Horario: response.data})

        if(this.state.Horario.length==0){
         
        }else{
      

            this.setState({ loading: this.state.loading = false })
            axios.get(ApiURL+"/api/ConfigParams/ListAll")
            .then((response) => {
              console.log(response);
      
              this.setState({Parametro:response.data})
              
              if(this.state.Parametro.length==0){
                      
              }else{
               
              }
             
      
            })
            .catch((error) => {
              console.log(error);
      
              $('#modal-verificahorario').modal('show');
              
            })   
         
        }
        

        this.setState({ loading: this.state.loading = true })
        //this.handlerSelectdrop();
      })
      .catch((error) => {
        console.log(error);

        
        $('#modal-verificahorario').modal('show');
       
        
      })

    }


    Cargar_profesionall=async()=>{    
    
      await axios.get(ApiURL+"/api/Professionals")
        .then((response) => {
          console.log(response);
          this.setState({profesionales: response.data})
        })
        .catch((error) => {
          console.log(error);
        })
    }

    Cargar_profesionales_con_convenio=async()=>{    
    
      await axios.get(ApiURL+"/api/Professionals/ListByAgreementEpsAndPrivate")
        .then((response) => {
          console.log(response);
          this.setState({profesionales: response.data})
        })
        .catch((error) => {
          if (error.response) {
             
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje+"; por favor configure un convenio por consulta externa EPS y/o consulta particular para continuar.",
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })
  
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
  
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);  
        })
    }


    Cargar_profesional=async()=>{      
    
          await axios.post(ApiURL+"/api/Professionals/ListByAgreementTypeService",
    
            {
              typeServiceId: 2
            })  
    
          .then(response=>{
            console.log(response)
            //var  status  = response.status;
            //alert(status); 
            //mensaje  = response.data.message;
                  
            this.setState({profesionales: response.data})       
                            
            
          })
          .catch(error=>{
    
            if (error.response) {
             
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              //console.log(error.response.data.message);
              //console.log(error.response.status);
              //console.log(error.response.headers);
              mensaje=error.response.data.message
              
              store.addNotification({
                title: 'Atención',
                message: mensaje,
                type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
                container: 'top-right',                // where to position the notifications
                animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
                animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
                dismiss: {
                  duration: 10000,
                  showIcon:true
                }
              })
    
              this.setState({ loading_button: this.state.loading_button = true })
              this.setState({ active_button: this.state.active_button = true })
    
          } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
              this.setState({ loading_button: this.state.loading_button = true })
              this.setState({ active_button: this.state.active_button = true })
          } else {
              // Something happened in setting up the request and triggered an Error
              console.log('Error', error.message);
              this.setState({ loading_button: this.state.loading_button = true })
              this.setState({ active_button: this.state.active_button = true })
          }
          //console.log(error.config);  
        
         })     
  
    }

    Cargar_sedes(){    
    
      axios.get(ApiURL+"/api/Sedes/ListAll")
      .then((response) => {
        console.log(response);
        this.setState({Sedes: response.data})

        if(this.state.Sedes.length==0){

          $('#modal-verifisede').modal('show');
       
        }else{

          
        }


      })
      .catch((error) => {
        console.log(error);
      })
    }


    CargarAgendas(){  

      this.empty_page_hide();

      this.setState({ loading: this.state.loading = false })
      axios.get(ApiURL+"/api/Schedules/ListActive")
      .then((response) => {

        console.log(response);
        this.setState({Agendas: response.data.data})
        this.setState({Pager_Agendas: response.data.pager.items})
        this.setState({pager_infoagendas: response.data.pager.showData})  

        if(this.state.Agendas.length==0){

          $('#tabla1').hide();
          $('#empty_page').show();
          $('#text_page').show();   
          
        }else{

          $('#tabla1').show();
          $('#empty_page').hide();
          $('#text_page').hide(); 
          
        }

        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
    }



    loadMoreDataagendas(pager_agenda) {
      var page=pager_agenda.pagina
      this.setState({ loading: this.state.loading = false })
      
      axios.get(ApiURL+"/api/Schedules/ListActive?numeroPagina="+page)
      .then((response) => {

        console.log(response);

        this.setState({Agendas: response.data.data})
        this.setState({Pager_Agendas: response.data.pager.items})
        this.setState({pager_infoagendas: response.data.pager.showData})
  
      
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
    
      }


      searchDataagenda(buscar_agenda) {

        this.setState({ loading: this.state.loading = false })
        
        axios.get(ApiURL+"/api/Schedules/ListActive?texto="+buscar_agenda)
        .then((response) => {
        console.log(response);

        this.setState({Agendas: response.data.data})
        this.setState({Pager_Agendas: response.data.pager.items})
        this.setState({pager_infoagendas: response.data.pager.showData})
        
        this.setState({ loading: this.state.loading = true })
        })
        .catch((error) => {
          console.log(error);
        })
      
      }


      CargarAgendas_inactivas(){  

          this.empty_page_hide();
    
          this.setState({ loading: this.state.loading = false })
          axios.get(ApiURL+"/api/Schedules/ListInactive")
          .then((response) => {
    
            console.log(response);
            this.setState({Agendas_inactivas: response.data.data})
            this.setState({Pager_Agendas_inactivas: response.data.pager.items})
            this.setState({pager_infoagendas_inactivas: response.data.pager.showData})  
    
            if(this.state.Agendas_inactivas.length==0){
    
              $('#tabla2').hide();
              $('#empty_page2').show();
              $('#text_page2').show();   
              
            }else{
    
              $('#tabla2').show();
              $('#empty_page2').hide();
              $('#text_page2').hide(); 
              
            }
    
            this.setState({ loading: this.state.loading = true })
          })
          .catch((error) => {
            console.log(error);
          })
        }


        searchDataagenda_inactiva(buscar_agenda_inactiva) {

          this.setState({ loading: this.state.loading = false })
          
          axios.get(ApiURL+"/api/Schedules/ListInactive?texto="+buscar_agenda_inactiva)
          .then((response) => {
          console.log(response);
  
          this.setState({Agendas_inactivas: response.data.data})
          this.setState({Pager_Agendas_inactivas: response.data.pager.items})
          this.setState({pager_infoagendas_inactivas: response.data.pager.showData})
          
          this.setState({ loading: this.state.loading = true })
          })
          .catch((error) => {
            console.log(error);
          })
        
        }


        loadMoreDataagendas_inactiva(page_agenda_inactiva) {
          var page=page_agenda_inactiva.pagina
          this.setState({ loading: this.state.loading = false })
          
          axios.get(ApiURL+"/api/Schedules/ListInactive?numeroPagina="+page)
          .then((response) => {
    
            console.log(response);
    
            this.setState({Agendas_inactivas: response.data.data})
            this.setState({Pager_Agendas_inactivas: response.data.pager.items})
            this.setState({pager_infoagendas_inactivas: response.data.pager.showData})
      
          
            this.setState({ loading: this.state.loading = true })
          })
          .catch((error) => {
            console.log(error);
          })
        
        }


        CargarAgendas_vencidas(){  

            this.empty_page_hide();
      
            this.setState({ loading: this.state.loading = false })
            axios.get(ApiURL+"/api/Schedules/ListDefeated")
            .then((response) => {
      
              console.log(response);
              this.setState({Agendas_vencidas: response.data.data})
              this.setState({Pager_Agendas_vencidas: response.data.pager.items})
              this.setState({pager_infoagendas_vencidas: response.data.pager.showData})  
      
              if(this.state.Agendas_vencidas.length==0){
      
                $('#tabla3').hide();
                $('#empty_page3').show();
                $('#text_page3').show();   
                
              }else{
      
                $('#tabla3').show();
                $('#empty_page3').hide();
                $('#text_page3').hide(); 
                
              }
      
              this.setState({ loading: this.state.loading = true })
            })
            .catch((error) => {
              console.log(error);
            })
          }
  
  
          searchDataagenda_vencidas(buscar_agenda_vencida) {
  
            this.setState({ loading: this.state.loading = false })
            
            axios.get(ApiURL+"/api/Schedules/ListDefeated?texto="+buscar_agenda_vencida)
            .then((response) => {
            console.log(response);
    
            this.setState({Agendas_vencidas: response.data.data})
            this.setState({Pager_Agendas_vencidas: response.data.pager.items})
            this.setState({pager_infoagendas_vencidas: response.data.pager.showData})
            
            this.setState({ loading: this.state.loading = true })
            })
            .catch((error) => {
              console.log(error);
            })
          
          }
  
  
        loadMoreDataagendas_vencidas(page_agenda_vencidas) {
            var page=page_agenda_vencidas.pagina
            this.setState({ loading: this.state.loading = false })
            
            axios.get(ApiURL+"/api/Schedules/ListDefeated?numeroPagina="+page)
            .then((response) => {
      
              console.log(response);
      
              this.setState({Agendas_vencidas: response.data.data})
              this.setState({Pager_Agendas_vencidas: response.data.pager.items})
              this.setState({pager_infoagendas_vencidas: response.data.pager.showData})
        
            
              this.setState({ loading: this.state.loading = true })
            })
            .catch((error) => {
              console.log(error);
            })
          
          }


          CargarAgendas_anuladas(){  

            this.empty_page_hide();
      
            this.setState({ loading: this.state.loading = false })
            axios.get(ApiURL+"/api/Schedules/ListCanceled")
            .then((response) => {
      
              console.log(response);
              this.setState({Agendas_anulada: response.data.data})
              this.setState({Pager_Agendas_anulada: response.data.pager.items})
              this.setState({pager_infoagendas_anulada: response.data.pager.showData})  
      
              if(this.state.Agendas_vencidas.length==0){
      
                $('#tabla4').hide();
                $('#empty_page4').show();
                $('#text_page4').show();   
                
              }else{
      
                $('#tabla4').show();
                $('#empty_page4').hide();
                $('#text_page4').hide(); 
                
              }
      
              this.setState({ loading: this.state.loading = true })
            })
            .catch((error) => {
              console.log(error);
            })
          }
  
  
          searchDataagenda_anuladas(buscar_agenda_anulada) {
  
            this.setState({ loading: this.state.loading = false })
            
            axios.get(ApiURL+"/api/Schedules/ListCanceled?texto="+buscar_agenda_anulada)
            .then((response) => {
            console.log(response);
    
            this.setState({Agendas_anulada: response.data.data})
            this.setState({Pager_Agendas_anulada: response.data.pager.items})
            this.setState({pager_infoagendas_anulada: response.data.pager.showData})
            
            this.setState({ loading: this.state.loading = true })
            })
            .catch((error) => {
              console.log(error);
            })
          
          }
  
  
        loadMoreDataagendas_anuladas(page_agenda_anulada) {
            var page=page_agenda_anulada.pagina
            this.setState({ loading: this.state.loading = false })
            
            axios.get(ApiURL+"/api/Schedules/ListCanceled?numeroPagina="+page)
            .then((response) => {
      
              console.log(response);
      
              this.setState({Agendas_anulada: response.data.data})
              this.setState({Pager_Agendas_anulada: response.data.pager.items})
              this.setState({pager_infoagendas_anulada: response.data.pager.showData})
        
            
              this.setState({ loading: this.state.loading = true })
            })
            .catch((error) => {
              console.log(error);
            })
          
          }


    SaveAgenda=async()=>{

      if(this.state.form.fecha_ini.length>10 || this.state.form.fecha_fin.length>10){

        store.addNotification({
          title: 'Atención',
          message: "Ingrese un rango de fecha valido",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        if( !moment(this.state.form.fecha_ini,'YYYY-MM-DD', true).isValid() || !moment(this.state.form.fecha_fin,'YYYY-MM-DD', true).isValid()){
          
          store.addNotification({
            title: 'Atención',
            message: "La fecha ingresada no es valida",
            type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })
        
        }else{
        

      if (this.state.form.nom_agenda=='' || this.state.form.fecha_ini=='' || this.state.form.fecha_fin=='' || this.state.form.nom_agenda=='' || id_profe==0 || id_especialidad==0 || id_sede=="0"){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.post(ApiURL+"/api/Schedules/Create",
  
          {
            name:this.state.form.nom_agenda ,            
            beginDate: this.state.form.fecha_ini,
            endDate: this.state.form.fecha_fin,
            institutionId:institution_id,
            professionalId:id_profe,
            specialtyId:id_especialidad,
            sedeId:id_sede
          } )  
  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data.message;
          agendaid = response.data.data.id;
          nom_agenda = response.data.data.name;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
          this.setState({ active: this.state.active = false })

          $("#div_agenda1").find("*").prop('disabled', true);
          $("#div_agenda2").find("*").prop('disabled', true);
          $("#div_agenda3").find("*").prop('disabled', true);

          this.setState({
            form:{
              nom_agenda:'',       
              fecha_ini:'',  
              fecha_fin:'',
              intervalo:''        
            }
          })

          this.TraerIntervalos(agendaid);
          this.CargarAgendas();          
          $('#modal-agregarintervalo').modal('show');
          
          
          
        })
        .catch(error=>{
            
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 15000,
                showIcon:true
              }
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
      
      })

       }

      } 
      
    }

    }


    seleccionaragenda=(agenda)=>{
      agendaid=agenda.id
      nom_agenda=agenda.name.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
      est_agenda=agenda.state
      this.setState({ active_check: this.state.active_check = true })
      id_especialidad_edit=agenda.specialty.id
      nom_sededit=agenda.sede.name.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
      id_sededit=agenda.sede.id
      
      this.setState({
        form:{
          id_agenda_edit:agenda.id,          
          nom_agenda_edit:agenda.name.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))),
          fechaini_edit:moment(agenda.beginDate).format("YYYY-MM-DD"),  
          fechafin_edit:moment(agenda.endDate).format("YYYY-MM-DD"),
          nom_agenda:this.state.form.nom_agenda,
          fecha_ini:this.state.form.fecha_ini,
          fecha_fin:this.state.form.fecha_fin,
          inicio:this.state.form.inicio,
          fin:this.state.form.fin,
          intervalo:'',
         
         
        }
      })

      if(est_agenda=='inactivo'){
        btn_activar=<div className="switch-button">                                      
        <input type="checkbox" name="switch-button" id="switch-label" className="switch-button__checkbox" onClick={()=> this.Cambiar_estadoagenda(estado_agenta="activo")} />
        <label htmlFor="switch-label" className="switch-button__label" />
      </div>
      }else{
        if(est_agenda=='activo'){
          btn_activar=<div className="switch-button">                                      
          <input type="checkbox" name="switch-button" id="switch-label" className="switch-button__checkbox" onClick={()=> this.Cambiar_estadoagenda(estado_agenta="inactivo")} checked={this.state.active_check}  />
          <label htmlFor="switch-label" className="switch-button__label" />
        </div>
        }       
      }
      $('#cb_sededit').show();
      //this.TraerIntervalos(agenda.id)      
      //var  dato  = solicitud.user.identification;
      //alert(this.state.form.fechaini_edit);
    }
    

    seleccionaragenda_inactiva=(agenda_inactiva)=>{
      agendaid=agenda_inactiva.id
      nom_agenda=agenda_inactiva.name.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
      est_agenda=agenda_inactiva.state
      this.setState({ active_check: this.state.active_check = false })
      id_especialidad_edit=agenda_inactiva.specialty.id
      nom_sededit=agenda_inactiva.sede.name.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
      id_sededit=agenda_inactiva.sede.id
      
      this.setState({
        form:{
          id_agenda_edit:agenda_inactiva.id,          
          nom_agenda_edit:agenda_inactiva.name.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))),
          fechaini_edit:moment(agenda_inactiva.beginDate).format("YYYY-MM-DD"),  
          fechafin_edit:moment(agenda_inactiva.endDate).format("YYYY-MM-DD"),
          nom_agenda:this.state.form.nom_agenda,
          fecha_ini:this.state.form.fecha_ini,
          fecha_fin:this.state.form.fecha_fin,
          inicio:this.state.form.inicio,
          fin:this.state.form.fin,
          intervalo:'',
         
         
        }
      })

      
      if(est_agenda=='inactivo'){
        btn_activar=<div className="switch-button">                                      
        <input type="checkbox" name="switch-button" id="switch-label" className="switch-button__checkbox" onClick={()=> this.Cambiar_estadoagenda(estado_agenta="activo")} checked={this.state.active_check} />
        <label htmlFor="switch-label" className="switch-button__label" />
      </div>
      }else{
        if(est_agenda=='activo'){
          btn_activar=<div className="switch-button">                                      
          <input type="checkbox" name="switch-button" id="switch-label" className="switch-button__checkbox" onClick={()=> this.Cambiar_estadoagenda(estado_agenta="inactivo")}  />
          <label htmlFor="switch-label" className="switch-button__label" />
        </div>
        }       
      }
      
      $('#cb_sededit').show();
      //this.TraerIntervalos(agenda.id)      
      //var  dato  = solicitud.user.identification;
      //alert(this.state.form.fechaini_edit);
    }


    Cambiar_estadoagenda=async()=>{

      if(agendaid=='0' ){

        store.addNotification({
          title: 'Atención',
          message: "No ha ingresado información valida de la agenda",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
       
        await axios.put(ApiURL+"/api/Schedules/ChangeState/"+agendaid,
        
        {
          state: estado_agenta        
        })  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data.message;
         
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })           
          
         
          this.CargarAgendas();  
          this.CargarAgendas_inactivas();
          
          if(est_agenda=='inactivo'){
            btn_activar=<div className="switch-button">                                      
                          <input type="checkbox" name="switch-button" id="switch-label" className="switch-button__checkbox" onClick={()=> this.Cambiar_estadoagenda(estado_agenta="activo")} />
                          <label htmlFor="switch-label" className="switch-button__label" />
                        </div>
          }else{
            if(est_agenda=='activo'){
              btn_activar=<div className="switch-button">                                      
                <input type="checkbox" name="switch-button" id="switch-label" className="switch-button__checkbox" onClick={()=> this.Cambiar_estadoagenda(estado_agenta="inactivo")} />
                <label htmlFor="switch-label" className="switch-button__label" />
              </div>
            }       
          }

          $('#modal-editaragenda').modal('hide');
          
        })
        .catch(error=>{
            
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })

      }
      
  
    }



    SaveIntevalo=async()=>{

      if (this.state.form.inicio=='' || this.state.form.fin=='' || agendaid==0 || this.state.form.intervalo.length==0 || dia_intervalo=="null" || id_tipo_servi==0){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{
        this.setState({ loading_button: this.state.loading_button_intervalo = false })
        this.setState({ loading_button: this.state.active_button_intervalo = false })

        await axios.post(ApiURL+"/api/Schedules/AddScheduleDay",
  
          {
            scheduleId:agendaid ,            
            day: dia_intervalo,
            beginTime: this.state.form.inicio,
            endTime:this.state.form.fin,
            interval:this.state.form.intervalo,
            typeServiceId:id_tipo_servi

          } 
          )  
  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
               
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button_intervalo = true })
          this.setState({ loading_button: this.state.active_button_intervalo = true })

          this.TraerIntervalos(agendaid);
          
        })
        .catch(error=>{
            
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button: this.state.loading_button_intervalo = true })
            this.setState({ loading_button: this.state.active_button_intervalo = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })

      }      
  
    }

    EditarAgenda=async()=>{

      if(this.state.form.fechaini_edit.length>10 || this.state.form.fechafin_edit.length>10){

        store.addNotification({
          title: 'Atención',
          message: "Ingrese un rango de fecha valido",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

      if (this.state.form.id_agenda_edit.length==0 || this.state.form.nom_agenda_edit==''){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.put(ApiURL+"/api/Schedules/Edit/"+this.state.form.id_agenda_edit,
  
          {
            id:this.state.form.id_agenda_edit ,            
            name: this.state.form.nom_agenda_edit,
            beginDate: this.state.form.fechaini_edit,
            endDate:this.state.form.fechafin_edit,
            specialtyId:id_especialidad_edit,
            sedeId:id_sededit
          } 
          )  
  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
          this.CargarAgendas();
          $('#modal-editaragenda').modal('hide');
          
          
        })
        .catch(error=>{
            
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })

      } 
     }
    }

    Borrar_intervalo(horario){
      dato  = horario.id;

      this.handleModaleliminaintervalo();

     }


    EliminarIntervalo=()=>{
     
      //var  dato  = horario.id;
      //alert(dato);

      this.setState({ loading_button_eliminar: this.state.loading_button_eliminar = false })
      this.setState({ active_button_eliminar: this.state.active_button_eliminar = false })

      axios.delete(ApiURL+"/api/Schedules/RemoveScheduleDay/"+dato)
      .then((response) => {
        console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);          
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          }) 
          
          this.setState({ loading_button_eliminar: this.state.loading_button_eliminar = true })
          this.setState({ active_button_eliminar: this.state.active_button_eliminar = true })

          $('#modal-elimina_intervalo').modal('hide');          
          this.TraerIntervalos(agendaid);
        
      })
      .catch((error) => {
        console.log(error);
      })
    }


    TraerIntervalos(agendaid){  
     
      //var id=agenda.id   
       
      axios.get(ApiURL+"/api/Schedules/GetSchedule/"+agendaid)  
  
        .then(response=>{
          console.log(response)
          this.setState({IntervaloAgenda:response.data.scheduleDays})         
        })
        .catch(error=>{            
        console.log(error);      
      })
      
    }
    
    handleModaleditaragenda()
    {
     
      $('#modal-editaragenda').modal('show'); // <- to show modal crear horario
    }

    handleModalagregarintervalo()
    {      
      $('#modal-agregarintervalo').modal('show'); // <- to show modal editar horario
      
      
    }

    

    handleModaleliminaintervalo()
    {      
      $('#modal-elimina_intervalo').modal('show'); // <- to show modal elimiinar intervalo
      
    }


    handleridprofesional = (p) => {
      console.log(p)
      id_profe=(p)
      //alert(id_profe)
      this.Cargar_Especialidades(id_profe)
    }


    
       

    render(){ 
      
      const {form} = this.state;

     return (
     
          <div className="content-wrapper">   
         
              <div className="col-12 col-sm-12">

                  <div className="row">
            
                      <div className="col-sm-10"> 
                      <br></br>           
                         <div className="text-center" id="title"> <h6> <b> GESTIONAR AGENDAS </b>  </h6> </div>            
                      </div>             
            
                  </div>

                  <div className="card card-primary card-outline card-tabs">

                        <div className="card-header p-0 pt-1 border-bottom-0">
                          
                          <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                              
                              <li className="nav-item">
                                <a className="nav-link active" id="custom-tabs-three-profile-tab" data-toggle="pill" href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile" aria-selected="true" onClick={()=>{this.CargarAgendas()}} >Agendas creadas <Logo_agenda/> </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" id="custom-tabs-three-agenda_inactiva-tab" data-toggle="pill" href="#custom-tabs-three-agenda_inactiva" role="tab" aria-controls="custom-tabs-three-agenda_inactiva" aria-selected="false" onClick={()=>{this.CargarAgendas_inactivas()}} >Agendas inactivas <Logo_agenda/> </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" id="custom-tabs-three-agenda_vencida-tab" data-toggle="pill" href="#custom-tabs-three-agenda_vencida" role="tab" aria-controls="custom-tabs-three-agenda_vencida" aria-selected="false" onClick={()=>{this.CargarAgendas_vencidas()}} >Agendas vencidas <Logo_agenda/> </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" id="custom-tabs-three-agenda_anulada-tab" data-toggle="pill" href="#custom-tabs-three-agenda_anulada" role="tab" aria-controls="custom-tabs-three-agenda_anulada" aria-selected="false" onClick={()=>{this.CargarAgendas_anuladas()}} >Agendas anuladas <Logo_agenda/> </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="false" onClick={()=>{this.CargarAgendas();this.Cargar_sedes();this.Cargar_profesionales_con_convenio()}} >Crear agenda <Logo_nuevo/> </a>
                              </li>

                          </ul>
                        
                        </div>
                  
                      <div className="card-body">
                        
                          <div className="tab-content" id="custom-tabs-three-tabContent"> 
  

                              <div className="tab-pane fade show active" id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab"> 

                                  <div class="d-flex justify-content-center">
                                                
                                      {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                                        
                                  </div>
                                  
                                  <div className="card table-responsive" id="tabla1">    
                                       

                                      <div className="row">
                                      
                                                <div className="col-sm-3">
                                                      <div className="input-group input-group-sm mb-3 mr-sm-2">
                                                         <div className="input-group-prepend">
                                                           <div className="input-group-text"><Buscar/></div>
                                                         </div>
                                                         <input type="search" className="form-control" placeholder="Buscar" name="buscar_agendas" onChange={this.BuscarAgenda}/>
                                                      </div>                                
                                                </div>
                                                
                                            </div>                      
                              
                                        
                                      <table id="tabla_agendas" className="table table-bordered table-striped table-hover">
                                        <thead>
                                          <tr>
                                            <th>ID</th>
                                            <th>Sede</th>
                                            <th>Nombre agenda</th>
                                            <th>Profesional</th>
                                            <th>Especialidad</th>
                                            <th>Fecha inicio</th>
                                            <th>Fecha fin</th>
                                            <th>Editar</th>  
                                            <th>Agregar Intervalo</th>        
                                          </tr>
                                        </thead>
    
                                        <tbody>
                                             {this.state.Agendas.map(agenda=>{
                                               return(
                                            
                                               <tr>
                                                 <td>{agenda.id}</td>  
                                                 <td>{(agenda.sede.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>        
                                                 <td>{(agenda.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>                                                      
                                                 <td>{ agenda.professional.user.name1 ? (agenda.professional.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} {agenda.professional.user.name2 ? (agenda.professional.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' } { agenda.professional.user.lastname1 ? (agenda.professional.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' } { agenda.professional.user.lastname2 ? (agenda.professional.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }</td>
                                                 <td>{(agenda.specialty.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                 <td> 
                                                 <Moment format="YYYY/MM/DD">
                                                 {agenda.beginDate}
                                                 </Moment>
                                                 </td>
                                                 <td> 
                                                 <Moment format="YYYY/MM/DD">
                                                 {agenda.endDate}
                                                 </Moment>
                                                 </td>
                                                 <td onClick={()=>{this.handleModaleditaragenda();this.seleccionaragenda(agenda)}}> <BtnEdit  />
                                                 </td>
                                                 <td onClick={()=>{this.handleModalagregarintervalo();this.seleccionaragenda(agenda);this.TraerIntervalos(agendaid)}}> <BtnAdd/>
                                                 </td>
                                               </tr>
                                               )
                                              }
                                            )}                                                            
                                                                               
                                        </tbody>
                                        
                                      </table>

                                      <span>{this.state.pager_infoagendas}</span>
                                                <nav className="nav">
                                                
                                                    <ul className="pagination">
              
                                                        {this.state.Pager_Agendas.map(page_agenda=>{
                                                        
                                                          var activo=page_agenda.activo
                                                          if(activo==true){
                                                            activo='page-item active'
                                                          }else{
                                                            activo='page-item'
                                                          }
              
                                                         // alert(activo)
                                                          
                                                         return(                          
                                                                  
                                                                 <li className={activo}>
                                                                   <a className="nav-link" onClick={()=>{this.loadMoreDataagendas(page_agenda)}}>{page_agenda.texto}</a>
                                                                 </li>                                            
                                                           )
                                                          }
                                                         )
                                                        }

                                                    </ul>
                                                          
                                                </nav>                          
                
                                  </div>

                                  <div className="d-flex justify-content-center">                              
                                        <img id="empty_page" src={emptypage} alt="logo"  width= "200"/>                             
                                  </div>

                                  <div className="d-flex justify-content-center">                  
                                       <h5 id="text_page"> <b>No hay información que mostrar</b> </h5>                          
                                  </div>

                              </div>

                              <div className="tab-pane fade" id="custom-tabs-three-agenda_inactiva" role="tabpanel" aria-labelledby="custom-tabs-three-agenda_inactiva"> 

                                  <div class="d-flex justify-content-center">
                                                
                                      {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                                        
                                  </div>
                                  
                                  <div className="card table-responsive" id="tabla2">    
                                       

                                      <div className="row">
                                      
                                                <div className="col-sm-3">
                                                      <div className="input-group input-group-sm mb-3 mr-sm-2">
                                                         <div className="input-group-prepend">
                                                           <div className="input-group-text"><Buscar/></div>
                                                         </div>
                                                         <input type="search" className="form-control" placeholder="Buscar" name="buscar_agendas" onChange={this.BuscarAgenda_inactiva}/>
                                                      </div>                                
                                                </div>
                                                
                                            </div>                      
                              
                                        
                                      <table id="tabla_agendas" className="table table-bordered table-striped table-hover">
                                        <thead>
                                          <tr>
                                            <th>ID</th>
                                            <th>Sede</th>
                                            <th>Nombre agenda</th>
                                            <th>Profesional</th>
                                            <th>Especialidad</th>
                                            <th>Fecha inicio</th>
                                            <th>Fecha fin</th>
                                            <th>Editar</th>
                                                 
                                          </tr>
                                        </thead>
    
                                        <tbody>
                                             {this.state.Agendas_inactivas.map(agenda_inactiva=>{
                                               return(
                                            
                                               <tr>
                                                 <td>{agenda_inactiva.id}</td>  
                                                 <td>{(agenda_inactiva.sede.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>        
                                                 <td>{(agenda_inactiva.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>                                                      
                                                 <td>{ agenda_inactiva.professional.user.name1 ? (agenda_inactiva.professional.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} {agenda_inactiva.professional.user.name2 ? (agenda_inactiva.professional.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' } { agenda_inactiva.professional.user.lastname1 ? (agenda_inactiva.professional.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' } { agenda_inactiva.professional.user.lastname2 ? (agenda_inactiva.professional.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }</td>
                                                 <td>{(agenda_inactiva.specialty.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                 <td> 
                                                 <Moment format="YYYY/MM/DD">
                                                 {agenda_inactiva.beginDate}
                                                 </Moment>
                                                 </td>
                                                 <td> 
                                                 <Moment format="YYYY/MM/DD">
                                                 {agenda_inactiva.endDate}
                                                 </Moment>
                                                 </td>
                                                 <td onClick={()=>{this.handleModaleditaragenda();this.seleccionaragenda_inactiva(agenda_inactiva)}}> <BtnEdit  /> </td>
                                                 
                                               </tr>
                                               )
                                              }
                                            )}                                                            
                                                                               
                                        </tbody>
                                        
                                      </table>

                                      <span>{this.state.pager_infoagendas_inactivas}</span>
                                                <nav className="nav">
                                                
                                                    <ul className="pagination">
              
                                                        {this.state.Pager_Agendas_inactivas.map(page_agenda_inactiva=>{
                                                        
                                                          var activo=page_agenda_inactiva.activo
                                                          if(activo==true){
                                                            activo='page-item active'
                                                          }else{
                                                            activo='page-item'
                                                          }
              
                                                         // alert(activo)
                                                          
                                                         return(                          
                                                                  
                                                                 <li className={activo}>
                                                                   <a className="nav-link" onClick={()=>{this.loadMoreDataagendas_inactiva(page_agenda_inactiva)}}>{page_agenda_inactiva.texto}</a>
                                                                 </li>                                            
                                                           )
                                                          }
                                                         )
                                                        }

                                                    </ul>
                                                          
                                                </nav>                          
                
                                  </div>

                                  <div className="d-flex justify-content-center">                              
                                        <img id="empty_page2" src={emptypage} alt="logo"  width= "200"/>                             
                                  </div>

                                  <div className="d-flex justify-content-center">                  
                                       <h5 id="text_page2"> <b>No hay información que mostrar</b> </h5>                          
                                  </div>

                              </div> 

                              <div className="tab-pane fade" id="custom-tabs-three-agenda_vencida" role="tabpanel" aria-labelledby="custom-tabs-three-agenda_vencida"> 

                                  <div class="d-flex justify-content-center">
                                                
                                      {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                                        
                                  </div>
                                  
                                  <div className="card table-responsive" id="tabla3">    
                                       

                                      <div className="row">
                                      
                                                <div className="col-sm-3">
                                                      <div className="input-group input-group-sm mb-3 mr-sm-2">
                                                         <div className="input-group-prepend">
                                                           <div className="input-group-text"><Buscar/></div>
                                                         </div>
                                                         <input type="search" className="form-control" placeholder="Buscar" name="buscar_agendas" onChange={this.BuscarAgenda_vencidas}/>
                                                      </div>                                
                                                </div>
                                                
                                            </div>                      
                              
                                        
                                      <table id="tabla_agendas" className="table table-bordered table-striped table-hover">
                                        <thead>
                                          <tr>
                                            <th>ID</th>
                                            <th>Sede</th>
                                            <th>Nombre agenda</th>
                                            <th>Profesional</th>
                                            <th>Especialidad</th>
                                            <th>Fecha inicio</th>
                                            <th>Fecha fin</th>
                                                 
                                          </tr>
                                        </thead>
    
                                        <tbody>
                                             {this.state.Agendas_vencidas.map(agenda_vencida=>{
                                               return(
                                            
                                               <tr>
                                                 <td>{agenda_vencida.id}</td>  
                                                 <td>{(agenda_vencida.sede.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>        
                                                 <td>{(agenda_vencida.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>                                                      
                                                 <td>{ agenda_vencida.professional.user.name1 ? (agenda_vencida.professional.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} {agenda_vencida.professional.user.name2 ? (agenda_vencida.professional.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' } { agenda_vencida.professional.user.lastname1 ? (agenda_vencida.professional.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' } { agenda_vencida.professional.user.lastname2 ? (agenda_vencida.professional.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }</td>
                                                 <td>{(agenda_vencida.specialty.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                 <td> 
                                                 <Moment format="YYYY/MM/DD">
                                                 {agenda_vencida.beginDate}
                                                 </Moment>
                                                 </td>
                                                 <td> 
                                                 <Moment format="YYYY/MM/DD">
                                                 {agenda_vencida.endDate}
                                                 </Moment>
                                                 </td>
                                                 
                                               </tr>
                                               )
                                              }
                                            )}                                                            
                                                                               
                                        </tbody>
                                        
                                      </table>

                                      <span>{this.state.pager_infoagendas_vencidas}</span>
                                                <nav className="nav">
                                                
                                                    <ul className="pagination">
              
                                                        {this.state.Pager_Agendas_inactivas.map(page_agenda_vencida=>{
                                                        
                                                          var activo=page_agenda_vencida.activo
                                                          if(activo==true){
                                                            activo='page-item active'
                                                          }else{
                                                            activo='page-item'
                                                          }
              
                                                         // alert(activo)
                                                          
                                                         return(                          
                                                                  
                                                                 <li className={activo}>
                                                                   <a className="nav-link" onClick={()=>{this.loadMoreDataagendas_vencidas(page_agenda_vencida)}}>{page_agenda_vencida.texto}</a>
                                                                 </li>                                            
                                                           )
                                                          }
                                                         )
                                                        }

                                                    </ul>
                                                          
                                                </nav>                          
                
                                  </div>

                                  <div className="d-flex justify-content-center">                              
                                        <img id="empty_page3" src={emptypage} alt="logo"  width= "200"/>                             
                                  </div>

                                  <div className="d-flex justify-content-center">                  
                                       <h5 id="text_page3"> <b>No hay información que mostrar</b> </h5>                          
                                  </div>

                              </div> 

                              <div className="tab-pane fade" id="custom-tabs-three-agenda_anulada" role="tabpanel" aria-labelledby="custom-tabs-three-agenda_anulada"> 

                                  <div class="d-flex justify-content-center">
                                                
                                      {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                                        
                                  </div>
                                  
                                  <div className="card table-responsive" id="tabla4">    
                                       

                                      <div className="row">
                                      
                                                <div className="col-sm-3">
                                                      <div className="input-group input-group-sm mb-3 mr-sm-2">
                                                         <div className="input-group-prepend">
                                                           <div className="input-group-text"><Buscar/></div>
                                                         </div>
                                                         <input type="search" className="form-control" placeholder="Buscar" name="buscar_agendas" onChange={this.BuscarAgenda_anulada}/>
                                                      </div>                                
                                                </div>
                                                
                                            </div>                      
                              
                                        
                                      <table id="tabla_agendas" className="table table-bordered table-striped table-hover">
                                        <thead>
                                          <tr>
                                            <th>ID</th>
                                            <th>Sede</th>
                                            <th>Nombre agenda</th>
                                            <th>Profesional</th>
                                            <th>Especialidad</th>
                                            <th>Fecha inicio</th>
                                            <th>Fecha fin</th>
                                                 
                                          </tr>
                                        </thead>
    
                                        <tbody>
                                             {this.state.Agendas_anulada.map(agenda_anulada=>{
                                               return(
                                            
                                               <tr>
                                                 <td>{agenda_anulada.id}</td>  
                                                 <td>{(agenda_anulada.sede.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>        
                                                 <td>{(agenda_anulada.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>                                                      
                                                 <td>{ agenda_anulada.professional.user.name1 ? (agenda_anulada.professional.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} {agenda_anulada.professional.user.name2 ? (agenda_anulada.professional.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' } { agenda_anulada.professional.user.lastname1 ? (agenda_anulada.professional.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' } { agenda_anulada.professional.user.lastname2 ? (agenda_anulada.professional.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }</td>
                                                 <td>{(agenda_anulada.specialty.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                 <td> 
                                                 <Moment format="YYYY/MM/DD">
                                                 {agenda_anulada.beginDate}
                                                 </Moment>
                                                 </td>
                                                 <td> 
                                                 <Moment format="YYYY/MM/DD">
                                                 {agenda_anulada.endDate}
                                                 </Moment>
                                                 </td>
                                                 
                                               </tr>
                                               )
                                              }
                                            )}                                                            
                                                                               
                                        </tbody>
                                        
                                      </table>

                                      <span>{this.state.pager_infoagendas_anulada}</span>
                                                <nav className="nav">
                                                
                                                    <ul className="pagination">
              
                                                        {this.state.Pager_Agendas_anulada.map(page_agenda_anulada=>{
                                                        
                                                          var activo=page_agenda_anulada.activo
                                                          if(activo==true){
                                                            activo='page-item active'
                                                          }else{
                                                            activo='page-item'
                                                          }
              
                                                         // alert(activo)
                                                          
                                                         return(                          
                                                                  
                                                                 <li className={activo}>
                                                                   <a className="nav-link" onClick={()=>{this.loadMoreDataagendas_anuladas(page_agenda_anulada)}}>{page_agenda_anulada.texto}</a>
                                                                 </li>                                            
                                                           )
                                                          }
                                                         )
                                                        }

                                                    </ul>
                                                          
                                                </nav>                          
                
                                  </div>

                                  <div className="d-flex justify-content-center">                              
                                        <img id="empty_page4" src={emptypage} alt="logo" width= "200"/>                             
                                  </div>

                                  <div className="d-flex justify-content-center">                  
                                       <h5 id="text_page4"> <b>No hay información que mostrar</b> </h5>                          
                                  </div>

                              </div>

                              <div className="tab-pane fade" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">                          
                              
                                    <div className="card">                                         
                                      
                                        <div className="row" id="div_agenda1">
                            
                                            <div className="col-md-4">
                                            
                                                    <div className="form-group"> 
                                                               <label >Nombre de la agenda:</label>
                                                                <input type="text" maxLength="50" className="form-control" placeholder="Nombre de la agenda" id="nom_agenda" name="nom_agenda" onChange={this.handleChange} disabled={!this.state.active}/>                   
                                                                                       
                                                    </div>                            
                                            </div>
                            
                                            <div className="col-md-4">
                                                   
                                                    <div className="form-group">                    
                                                            <label >Fecha inicio:</label>
                                                            <input type="date" className="form-control" id="fecha_ini" name="fecha_ini" onChange={this.handleChange} disabled={!this.state.active}/>            
                                                    </div>
                                            </div>
                        
                                            <div className="col-md-4">
                                                   
                                                    <div className="form-group">                    
                                                            <label >Fecha fin:</label>
                                                            <input type="date" className="form-control" id="fecha_fin" name="fecha_fin" onChange={this.handleChange} disabled={!this.state.active}/>               
                                                    </div>
                                            </div>
                            
                                        </div>                          
                                      
                                        <div className="row" id="div_agenda2">
                        
                                            <div className="col-md-4">
                                                                                 
                                                    <div className="form-group">                    
                                                              <label >Profesional:</label>
                                                              <select onChange={this.GetMedico} className="form-control" id="profesionales" name="profesionales" disabled={!this.state.active} >
                    
                                                                  <option  value="0"> Seleccione...</option> ,
                                                                  {this.state.profesionales.map(item=>                              
                                                                  <option key={item.id} value={item.id}>  {item.user.name1 ? (item.user.name1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }  {item.user.name2 ? (item.user.name2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''} { item.user.lastname1 ? (item.user.lastname1).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' } { item.user.lastname2 ? (item.user.lastname2).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }</option>  
                                                                  )}
                                                                          
                                                              </select>                        
                                                    </div>                                           
                          
                                            </div>
                                        
                                            <div className="col-md-4">                                 
                                                <div className="form-group">                    
                                                          <label >Especialidad:</label>
                                                          <select onChange={this.GetEspecialidad}  className="form-control" id="espe" name="espe" disabled={!this.state.active}>
                                                              <option  value="0"> Seleccione...</option> ,
                                                              {this.state.Especialidades.map(item=>                              
                                                              <option key={item.id} value={item.id}> {(item.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                              )}                                                                      
                                                          </select>                   
                                                </div>
                                            </div> 

                                            <div className="col-md-4">                                 
                                                <div className="form-group">                    
                                                          <label >Sede:</label>
                                                          <select onChange={this.GetSede}  className="form-control" id="sede" name="sede" disabled={!this.state.active}>
                                                              <option  value="0"> Seleccione...</option> ,
                                                              {this.state.Sedes.map(sede=>                              
                                                              <option key={sede.id} value={sede.id}> {(sede.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                              )}                                                                      
                                                          </select>                   
                                                </div>
                                            </div>                                                    
                        
                                        </div>                                    
                        
      
                                        <div className="modal-footer"> 

                                            <div id="div_agenda3" >
         
                                              <button id="btn_save"  className="btn btn-save" disabled={!this.state.active_button} onClick={()=> this.SaveAgenda()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar
                                                 <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                                    <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                                                 </svg>
                                              </button> 

                                            </div>

                                              <button  className="btn btn-primary" onClick={()=> {this.handlerSelectdrop()}} > Nuevo
                                                     <svg width="1.2em" height="1.2em" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                     </svg>
                                              </button>
         
                                        </div>                        
                                     
                      
                                    </div> 

                              </div>     
                       
                          </div>

                      </div>
               
                  </div>

              </div>
   
   
              <div className="modal fade" id="modal-editaragenda">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Editar Agenda</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>

                        <div className="modal-body">                         
                             
                            <div className="row">
                                
                                <div className="col-md-2">
                                      <div className="form-group">                    
                                        <label >ID:</label>
                                        <input type="text" className="form-control" name="id_agenda_edit" value={form.id_agenda_edit} readOnly />                      
                                      </div>                 
                                </div>
                    
                                <div className="col-md-10">
                                      <div className="form-group">                    
                                        <label >Agenda:</label>
                                        <input type="text" className="form-control" name="nom_agenda_edit" onChange={this.handleChange} value={form.nom_agenda_edit} />                      
                                      </div>                 
                                </div>
                    
                            </div>

                            <div className="row">
                                
                                <div className="col-md-6">              
                                    <div className="form-group">                    
                                            <label >Inicio:</label>
                                            <input type="date" className="form-control" name="fechaini_edit" onChange={this.handleChange} value={form.fechaini_edit} />
                                    </div>              
                                </div>
                                                  
                                <div className="col-md-6">
                                    <div className="form-group">                    
                                            <label >Fin:</label>
                                            <input type="date" className="form-control" name="fechafin_edit" onChange={this.handleChange} value={form.fechafin_edit}/>                    
                                    </div>                 
                                </div>                                                                
                                                                        
                            </div> 

                            <div className="row">  

                                <div className="col-md-6">                                 
                                    <div className="form-group">                    
                                              <label >Sede:</label>
                                              <select onChange={this.GetSededit} onClick={this.GetSededit}  className="form-control" id="sede_edit" name="sede_edit">
                                                  <option id="cb_sededit" value={id_sededit}>{nom_sededit}</option>  
                                                  {this.state.Sedes.map(sede=>                              
                                                  <option key={sede.id} value={sede.id}> {(sede.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                  )}                                                                      
                                              </select>                   
                                    </div>
                                </div>        
                          
                                <div className="col-md-6">
                                    <label >Inactivar/Activar:</label>
                                    <div className="form-group">                                    
                                        {btn_activar}
                                    </div>
                                </div>                          
          
                            </div>
                       
                        </div>

                        <div className="modal-footer justify-content-between">        
                             <button type="button" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.EditarAgenda()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar</button>
                             <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>
                        </div>

                  </div>
                 
                </div>
               
              </div> 
              
   
   
              <div className="modal fade" id="modal-agregarintervalo"  data-backdrop="static">
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Agregar Intervalo</b><spam  data-toggle="tooltip" data-placement="top" title="Los intervalos son parámetros que permiten establecer automáticamente los turnos de la agenda. Para configurarlos, hay que ingresar la hora de inicio y la hora de finalización de la jornada laboral del profesional; seguidamente, se debe seleccionar un día de la semana; y por último, hay que definir la duración de la consulta." > <Info/> </spam></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> {this.handlerdropIntervalo()}}>
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    
                          <div className="modal-body">                    
                                
                              <div className="row">
                                    
                                    <div className="col-md-2">
                                          <div className="form-group">                    
                                            <label >ID agenda:</label>
                                            <input type="text" className="form-control" name="id_agenda_edit" value={agendaid} readOnly />                      
                                          </div>                 
                                    </div>
                        
                                    <div className="col-md-4">
                                          <div className="form-group">                    
                                            <label >Nombre agenda:</label>
                                            <input type="text" className="form-control" name="nom_agenda_edit" value={nom_agenda} readOnly />                      
                                          </div>                 
                                    </div>

                                    <div className="col-md-3">              
                                          <div className="form-group">                    
                                            <label >Hora de inicio:</label>
                                            <spam  data-toggle="tooltip" data-placement="top" title="Define  la hora de inicio de la jornada laboral del profesional, en la que  se brindará la atención a los pacientes programados por consulta externa." > <Info/> </spam>
                                            <input type="time" className="form-control" name="inicio" id="inicio" onChange={this.handleChange} />
                                          </div>              
                                    </div>
                                                  
                                    <div className="col-md-3">
                                          <div className="form-group">                    
                                            <label >Hora de finalización:</label>
                                            <spam  data-toggle="tooltip" data-placement="top" title="Define  la hora de finalización de la jornada laboral del profesional, que marca el límite de la atención que se brindará a los pacientes programados por consulta externa." > <Info/> </spam>
                                            <input type="time" className="form-control" name="fin" id="fin" onChange={this.handleChange}/>                    
                                          </div>                 
                                    </div>
                        
                              </div>

                              <div className="row">                              
                                    
                                    <div className="col-md-6">
                                          <div className="form-group">                    
                                           <label >Día de la semana:</label>
                                           <spam  data-toggle="tooltip" data-placement="top" title="Define el día de la semana en el que se brindará la atención a los pacientes programados por consulta externa." > <Info/> </spam>
                                              <select onChange={this.GetDiaAgenda} className="form-control" id="dia_agenda">
                                                <option  value="null">Seleccione...</option>
                                                <option  value="1">Lunes</option>
                                                <option  value="2">Martes</option>
                                                <option  value="3">Miercoles</option>
                                                <option  value="4">Jueves</option>
                                                <option  value="5">Viernes</option>
                                                <option  value="6">Sábado</option>
                                                <option  value="0">Domingo</option>
                                                
                                            </select>                                           
                                          </div>                 
                                    </div> 

                                    <div className="col-md-3">
                                          <div className="form-group">                    
                                            <label >Tiempo de atención (minutos):</label>
                                            <spam  data-toggle="tooltip" data-placement="top" title="Define el tiempo promedio de duración de una consulta que se brinda a través de la Telemedicina." > <Info/> </spam>
                                            <input type="text" className="form-control camponum" maxLength="2" name="intervalo" id="intervalo" autoComplete='nope' value={form.intervalo} onChange={this.handleChange} onKeyDown={this.Campo_numerico()} />                                            
                                          </div>                 
                                    </div>  
                                    <div className="col-md-3">                                 
                                          <div className="form-group">                    
                                            <label >Tipo de servicio:</label>
                                            <spam  data-toggle="tooltip" data-placement="top" title="Define el tipo de servicio que el intervalo ingresado ofertá al momento de mostrar las horas de las citas disponibles." > <Info/> </spam>
                                                <select onChange={this.GetTiposervi}  className="form-control" id="tipo_servicio" name="tipo_servicio">
                                                  <option  value="0"> Seleccione...</option> ,
                                                  {this.state.Servicios.map(servicio=>                              
                                                  <option key={servicio.id} value={servicio.id}> {(servicio.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</option>  
                                                  )}                                                                      
                                                  </select>                   
                                          </div>
                                    </div>                           
                        
                              </div>
                           
                              <div className="row">
                                  
                                    <div className="col-md-12">
                                       
                                        <div className="card table-responsive">
                                 
                                           {/* /.card-header */}
                                           <div className="card-body">
                                              <table id="tabla_intervalos" className="table table-bordered table-striped table-hover">
                                                <thead>
                                                  <tr>
                                                    <th>ID</th>
                                                    <th>Día</th> 
                                                    <th>Inicio</th>
                                                    <th>Fin</th>
                                                    <th>Intervalo</th> 
                                                    <th>Tipo de servicio</th>                                      
                                                    <th>Eliminar</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                   {this.state.IntervaloAgenda.map(horario=>{
                                                     var dia=horario.day
                                                     var tipo_servicio=horario.typeServiceId
                                                     if(dia=='1'){
                                                       dia='Lunes'
                                                     }else{
                                                       if(dia=='2'){
                                                         dia='Martes'
                                                       }else{
                                                         if(dia=='3'){
                                                           dia='Miercoles'
                                                         }else{
                                                           if(dia=='4'){
                                                             dia='Jueves'
                                                           }else{
                                                             if(dia=='5'){
                                                               dia='Viernes'
                                                             }else{
                                                               if(dia=='6'){
                                                                 dia='Sabado'
                                                               }else{
                                                                 dia='Domingo'
                                                               }
                                                             }
                                                           }
                                                         }
                                                       }
                                                     }

                                                     if(tipo_servicio=='2'){
                                                      tipo_servicio='Consulta externa EPS'
                                                     }else{
                                                      if(tipo_servicio=='3'){
                                                        tipo_servicio='Consulta particular'
                                                      }
                                                     }
                                                     return(      
                                                     
                                                     <tr>          
                                                       <td>{horario.id}</td>                                  
                                                       <td>{dia}</td> 
                                                       <td>
                                                       <Moment format="HH:mm">
                                                        {horario.beginTime}
                                                       </Moment>
                                                       </td>
                                                       <td>
                                                       <Moment format="HH:mm">
                                                       {horario.endTime}
                                                       </Moment></td> 
                                                       <td>{horario.interval}</td> 
                                                       <td>{tipo_servicio}</td> 
                                                       <td onClick={()=>{this.Borrar_intervalo(horario)}}> <BtnDelete />  </td>
                                                     </tr>
                                             
                                                        )
                                                       }
                                                     )
                                                   }                
                                                </tbody>
                                              </table>
                                           </div>
                                           
                                        </div>            
                 
                                    </div>

                              </div>                            
                        

                              <div className="modal-footer justify-content-between">        
                                   <button type="button" className="btn btn-primary" disabled={!this.state.active_button_intervalo} onClick={()=> this.SaveIntevalo()}> {this.state.loading_button_intervalo?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar</button>
                                   <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={()=> {this.handlerdropIntervalo()}}>Cerrar</button>
                              </div>

                          </div>
                 
                  </div>
               
                </div>

              </div>

             

              <div className="modal fade" id="modal-verificahorario" data-backdrop="static">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-12 modal-title text-center" id="correo_activacion"><b>Atención</b></h4>
                     
                    </div>
    
                    <div className="modal-body">

                          
                          {/* /<span id="alert-recoverypass"> </span> */}
                      
                          <div className="row justify-content-md-center" id="mensaje_recupera"> 

                              <div className="col-md-12">

                              Aún no se ha configurado el horario laboral, ni el tiempo de respuesta en consulta externa; estos parámetros deben definirse antes de crear las agendas, 
                              <a id="btn-registrarse" href="/horarios">  ingresa aquí </a> &nbsp;
                              para establecerlos.

                              </div>
                                                  
                          
                          </div>

                          <br/>
                       
                             
                              
                    </div> 
    
                   

                  </div>
                  
                </div>
                
              </div>


              <div className="modal fade" id="modal-verifisede" data-backdrop="static">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header" id="fondo_modal_compra">
                      <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Atención</b></h4>
                     
                    </div>
    
                    <div className="modal-body">

                          
                          {/* /<span id="alert-recoverypass"> </span> */}
                      
                          <div className="row justify-content-md-center" id="mensaje_recupera"> 

                              <div className="col-md-12">

                              El prestador de salud no ha creado una sede; no se prodrán crear agendas.
                              <br/>
                              <br/>
                              <a id="btn-registrarse" href="/sedes">Ingresa aquí </a> &nbsp; para crear una.

                              </div>
                                                  
                          
                          </div>

                          <br/>                      
                             
                              
                    </div>   
                   

                  </div>
                  
                </div>
                
              </div>


              <div className="modal fade" id="modal-elimina_intervalo" data-backdrop="static">

                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Eliminar Intervalo</b></h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
  
                          <div className="col-md-12">
  
                           ¿Seguro que deseas eliminar el horario de atención?
  
                          </div>
                                 
                    </div>
          
                          <div className="modal-footer justify-content-between">        
                             <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=> this.EliminarIntervalo()}>Si</button>
                             <button type="button" className="btn btn-danger" data-dismiss="modal">No</button>
                          </div>
                  </div>                
                </div>
            
              </div>


          </div>    
      
    )

  }
  
}

export default Agendas;