import React, {Component} from 'react';
import axios from 'axios';
import * as ReactBootStrap from 'react-bootstrap';
import '../tools/css/Crear_cuenta.css';

import emptypage from '../tools/images/carpeta.gif';
import BtnCancel from '../components/BtnCancel';
import BtnEdit from '../components/BtnEdit';
import Eps from '../API/Eps';
import $ from 'jquery'; // <-to import jquery
import 'bootstrap';

import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';


const ApiURL="https://medicallapi-test.azurewebsites.net";
var institution_id =sessionStorage.getItem('id_institution');
var nivel=0;
var mensaje='';
var llave_publi='';



class Cuota_Moderadora extends Component {

  constructor()
    {
     super()
      this.state={
        show:false,
        loading: false,
        loading_button: true,
        active_button: true,
        Cuotas: [], 
        DataIntitucion: [],   
        Error:[],     
        form:{ 
          level:'',
          valor:'',
          id_cuota:'',  
          nivel_edit:'',  
          valor_edit:''       
          }
      }    
    
    }

    handleChange=async e=>{
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
         console.log(this.state.form);
         this.Selectdrop();
    }

    Campo_numerico(){
      $(".camponum").keypress(function(tecla)
      {
        if(tecla.charCode<48 || tecla.charCode>57){
         
          return false;

        }
      })
    }

    Formato_pesos(params) {

      
      
    }

    Selectdrop(){
      
      if(nivel==0 && this.state.form.valor!='' ){
        $("#level").val($('#level > option:first').val());        
      }
  

    }

    handlerSelectdrop=async()=>{
      
      $("#level").val($('#level>option:first').val());
      $("#valor").val("");
      $("#div_cuota1").find("*").prop('disabled', false);
      $("#div_cuota2").find("*").prop('disabled', false);
      
      nivel=0;
     
    }

    Limpiar_form=()=>{
      this.setState({
        form:{          
          valor:''     
        }
      })
     
    }

    GetNivel = (e) =>{
      this.setState({level:e.target.value})
      console.log(e.target.value)
      nivel=(e.target.value)
      //alert(nivel)
     
    }


    seleccionacuotas=(cuota)=>{
      this.setState({
        form:{
          id_cuota:cuota.id,  
          nivel_edit:cuota.level,   
          valor_edit:cuota.amount         
        }
      })
      //var  dato  = solicitud.user.identification;
      //alert(dato);
    }


    empty_page_hide(){
      $('#tabla1').hide();     
      $('#empty_page').hide();
      $('#text_page').hide(); 
    }

    componentDidMount(){
      this.Listar_cuotasmoderadoras()
      this.Datos_Institucion()
    }

    Datos_Institucion(){    
      
      axios.get(ApiURL+"/api/Institutions/GetInstitution/"+institution_id)
      .then((response) => {
        console.log(response.data.institution);

        this.setState({DataIntitucion: response.data.institution})       
      

          llave_publi=response.data.institution.publicKey

          //alert(llave_publi)      

        
      })
      .catch((error) => {
        console.log(error);
      })
    }


    Listar_cuotasmoderadoras(){   
      
      this.empty_page_hide();

      this.setState({ loading: this.state.loading = false })
    
      axios.get(ApiURL+"/api/ModeratingFees/ListAll")
      .then((response) => {
        console.log(response);
        this.setState({Cuotas: response.data})

        if(this.state.Cuotas.length==0){
          $('#tabla1').hide();
          $('#empty_page').show();
          $('#text_page').show(); 
        }else{
          $('#tabla1').show();
          $('#empty_page').hide();
          $('#text_page').hide(); 
        }
        
        this.setState({ loading: this.state.loading = true })

      })
      .catch((error) => {
        console.log(error);
      })
    }


    savecuota=async()=>{
      
      if(nivel==0 || this.state.form.valor==''){
        
        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        if(llave_publi==null || llave_publi==''){

          $('#modal-llaveswompi').modal('show');

        }else{

       

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.post(ApiURL+"/api/ModeratingFees/Create",
  
          {
          level: nivel,
          amount: this.state.form.valor
          
          } ) 
            
        .then(response=>{
          console.log(response.data)
          var  mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

          $("#div_cuota1").find("*").prop('disabled', true);
          $("#div_cuota2").find("*").prop('disabled', true);

          this.Limpiar_form();

          
        })
        
        .catch(error=>{

          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
 
      
      })

      }

      }      
  
    }


    editcuotas=async()=>{

      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.put(ApiURL+"/api/ModeratingFees/Edit/"+this.state.form.id_cuota,  
        {
          id:this.state.form.id_cuota,
          level:this.state.form.nivel_edit,
          amount: this.state.form.valor_edit,
          
        } )          
  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);          
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

          this.Listar_cuotasmoderadoras();

          $('#modal-cuotasedit').modal('hide'); // <- to hide modal editar couta 
          
        })
        .catch(error=>{

        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

       

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
           
        }
        //console.log(error.config);
          
      
      })
  
     }

    

    handleModalconsentsoedit()
    {
      this.setState({show2:!this.state.show})
      $('#modal-cuotasedit').modal('show'); // <- to show modal editar contrato 
    }
       

    render(){ 
      const {form} = this.state; 

     return (

     
        <div className="content-wrapper">        
  
            <div className="col-12 col-sm-12">

                <div className="row">

                    <div className="col-sm-10"> 
                    <br></br>        
                       <div className="text-center" id="title"> <h6> <b> CUOTAS MODERADORAS</b>  </h6> </div>          
                    </div>                   
          
                </div>
          
                <div className="card card-primary card-outline card-tabs">

                      <div className="card-header p-0 pt-1 border-bottom-0">

                        <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                          
                          <li className="nav-item">
                            <a className="nav-link active" id="custom-tabs-three-profile-tab" data-toggle="pill" href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile" aria-selected="true"  onClick={()=>{this.Listar_cuotasmoderadoras()}}>Cuota moderadora</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="false"  onClick={()=>{this.handlerSelectdrop()}}>Configurar cuota moderadora </a>
                          </li>
                                                   
                         
                        </ul>
                      </div>
          
                      <div className="card-body">

                          <div className="tab-content" id="custom-tabs-three-tabContent">
                            
                              <div className="tab-pane fade show active" id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab">
                                    
                                    <div class="d-flex justify-content-center">
                                            
                                        {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                                
                                    </div>
                                    
                                    <div className="card table-responsive" id="tabla1">         
                      
                                        <table id="tabla_solicitudes" className="table table-bordered table-striped">
                                            <thead>
                                              <tr>
                                                <th>ID registro</th>
                                                <th>Nivel</th>
                                                <th>Valor</th>
                                                <th>Editar</th>
                                                         
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {this.state.Cuotas.map(cuota=>{
                                              return(
                                      
                                              <tr>
                                                <td>{cuota.id}</td>
                                                <td>{cuota.level}</td>          
                                                <td>{cuota.amount}</td>          
                                                <td onClick={()=>{this.handleModalconsentsoedit();this.seleccionacuotas(cuota)}}> <BtnEdit  />
                                                </td>
                                              </tr>
                                              )
                                             }
                                           )}
                                              
                                                             
                                            </tbody>
                                        </table>
                                
                          
                                    </div>
    
                                    <div className="d-flex justify-content-center">                              
                                        <img id="empty_page" src={emptypage} alt="logo"  width= "200"/>                             
                                    </div>
    
                                    <div className="d-flex justify-content-center">                  
                                         <h5 id="text_page"> <b>No hay información que mostrar</b> </h5>                          
                                    </div>
                      
                              </div>
                              
                              <div className="tab-pane fade" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                                
                                <div className="card">                              
                      
                                    <div className="row" id="div_cuota1">
                          
                                        <div className="col-md-6">              
                                            <div className="form-group">                    
                                                    <label >Seleccione el nivel a configurar:</label>
                                                    <select className="form-control" id="level" name="level" onChange={this.GetNivel}>
                                                      
                                                      <option  value="0"> Seleccione...</option> ,
                                                      <option  value="1">Nivel 1</option>
                                                      <option  value="2">Nivel 2</option>
                                                      <option  value="3">NIvel 3</option>
                                              
                                                    </select>
                                            </div>              
                                        </div>
                                                          
                                        <div className="col-md-6">
                                            <div className="form-group">                    
                                              <label >Ingrese el valor correspondiente al nivel:</label>
                                              <input type="text" maxLength="6" className="form-control camponum" id="valor" placeholder="valor" name="valor" autoComplete='nope' onChange={this.handleChange} onKeyDown={this.Campo_numerico()}/>                   
                                            </div>                 
                                        </div>                                                
                                                                  
                                    </div>                    
                      
                                     <div className="modal-footer"> 

                                          <div id="div_cuota2">       
                                              <button id="btn_save"  className="btn btn-save" disabled={!this.state.active_button} onClick={()=> this.savecuota()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar
                                                <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                   <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                                   <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                                                </svg>
                                              </button> 
                                          </div>

                                              <button  className="btn btn-primary" onClick={()=> this.handlerSelectdrop()} > Nuevo
                                                  <svg width="1.2em" height="1.2em" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                   <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                  </svg>
                                              </button>
                                     </div>                           
                             
                                </div>                               
                      
                              </div>
                            
                              
                    
                          </div>                          
                         
                      </div>

                </div>             
            
            </div>
  
  
            <div className="modal fade" id="modal-cuotasedit">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title"><b>Editar cuota moderadora</b></h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    
                        <div className="row">
            
            
                              <div className="col-md-6">
                                    <div className="form-group">                    
                                      <label >ID registro:</label>
                                      <input type="text" className="form-control" id="id_cuota" name="id_cuota" value={form.id_cuota} readOnly />                      
                                    </div>                 
                              </div>
            
                              <div className="col-md-6">
                                    <div className="form-group">                    
                                      <label >Nivel:</label>
                                      <input type="text" maxLength="20" className="form-control" id="nivel_edit" name="nivel_edit" value={form.nivel_edit} readOnly/>                  
                                    </div>                 
                              </div>
            
                              <div className="col-md-6">
                                    <div className="form-group">                    
                                      <label >Valor:</label>
                                        <input type="text" maxLength="6" className="form-control camponum" id="valor_edit" name="valor_edit" autoComplete='nope' onChange={this.handleChange} onKeyDown={this.Campo_numerico()} value={form.valor_edit}/>                     
                                    </div>                 
                              </div>
            
                        </div>
                           
                       
                      </div>
            
                  <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.editcuotas()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}Guardar</button>
                    <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>        
                  </div>
                </div>
              
              </div>
             
            </div> 


            <div className="modal fade" id="modal-llaveswompi" data-backdrop="static">

              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header" id="fondo_modal_compra">
                    <h4 className="col-11 modal-title text-center" id="correo_activacion"><b>Llaves asociadas a Wompi</b></h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">

                        <div className="row justify-content-md-center" id="mensaje_recupera"> 
                            <div className="col-md-12">
                              Debe integrar las llaves de autenticación de WOMPI generadas para su comercio. Estas llaves son la forma en la que se identifica el comercio a la hora de procesar transacciones.
                                <br></br>
                                <br></br>
                              Si todavía no tiene las llaves de autenticación, regístrese en <a id="btn-registrarse" href="https://comercios.wompi.co/" target="_blank">  comercios.wompi.co </a>  y obtenga sus llaves en segundos. Consulte el instructivo de autogestión para crear una cuenta en WOMPI pulsando  <a id="btn-registrarse" href="https://soporte.wompi.co/hc/es-419/articles/4402327655699-Pasos-para-crear-el-registro-en-la-pasarela-de-pagos-Wompi" target="_blank"> aquí. </a>
                                <br></br>
                                <br></br>
                              Recuerde que debe registrar su comercio bajo el modelo GATEWAY, seleccionando el plan AVANZADO.
                            </div>                                               
                        
                        </div>                               
                  </div>
                  <div className="modal-footer justify-content-between">
                  <a  href="/perfil" >  <button type="button" className="btn btn-primary"   > Integrar llaves</button> </a> 
                   
                  </div>
        
                      
                </div>                
              </div>
          
            </div>
  
  
        </div>      
      
    )

  }
  
}


export default Cuota_Moderadora;