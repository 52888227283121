import React, {Component} from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import * as ReactBootStrap from 'react-bootstrap';
import '../tools/css/ContratosAdd.css';
import '../tools/css/Home.css';

import emptypage from '../tools/images/carpeta.gif';
import BtnEdit from '../components/BtnEdit';
import Eps from '../API/Eps';
import Logo_nuevo from '../tools/images/Nuevo';
import Logo_edit from '../tools/images/Edit';
import Buscar from '../tools/images/Buscar';

import $ from 'jquery'; // <-to import jquery
import 'bootstrap';

import ReactNotifications from 'react-notifications-component';
import { store } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';


const ApiURL="https://medicallapi-test.azurewebsites.net";
var mensaje='';
var id_servi='0';
var id_entity='0';
var buscar_contrato='';

class Contratos extends Component {

  constructor()
    {
     super()
      this.state={
        show:false,                
        Contratos: [],
        Pager_Contratos: [],
        pager_infocontratos:'',
        loading: false,
        loading_button: true,
        active_button: true,
        form:{ 
          nombre_contrato:'',
          num_contra: '',
          id_contra_edit:'',
          num_contra_edit:'',
          nombre_contratoedit:'',
          fecha_ini:'',
          fecha_fin:'',
          fecha_ini_edit:'',
          fecha_fin_edit:'',
          buscar_contratos:''         
          }
      }   
    
    }

    handleChange=async e=>{
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
         console.log(this.state.form);
         this.Selectdrop();
    }

    componentDidMount(){

      $('[data-toggle="tooltip"]').tooltip()

      this.ListaContratos();

    }


    Selectdrop(){
      
      if(id_entity==0 && this.state.form.fecha_ini!='' ){
        $("#eps").val($('#eps > option:first').val());        
      }

      if(id_entity==0 && this.state.form.nombre_contrato!=''){
        $("#eps").val($('#eps > option:first').val());        
      }

      if(id_entity==0 && this.state.form.num_contra!=''){
        $("#eps").val($('#eps > option:first').val());        
      }    

    }

    BuscarContrato = (bs) =>{
      this.setState({buscar_contratos:bs.target.value})
      console.log(bs.target.value)
      buscar_contrato=(bs.target.value)
      this.searchDatacontratos(buscar_contrato)
     
    }



    empty_page_hide(){
      $('#tabla1').hide();     
      $('#empty_page').hide();
    }


    ListaContratos(){ 
      
      this.empty_page_hide();

      this.setState({ loading: this.state.loading = false })
    
      axios.get(ApiURL+"/api/Contracts/List")
      .then((response) => {

        console.log(response);
        this.setState({Contratos: response.data.data})
        this.setState({Pager_Contratos: response.data.pager.items})
        this.setState({pager_infocontratos: response.data.pager.showData}) 

        if(this.state.Contratos.length==0){
          $('#tabla1').hide();
          $('#empty_page').show();
        }else{
          $('#tabla1').show();
          $('#empty_page').hide();
        }
        
        this.setState({ loading: this.state.loading = true })
        
      })
      .catch((error) => {
        console.log(error);
      })
    }

    loadMoreDatacontratos(page_solici) {
      var page=page_solici.pagina
      this.setState({ loading: this.state.loading = false })
      
      axios.get(ApiURL+"/api/Contracts/List?numeropagina="+page)
      .then((response) => {
        console.log(response);

        this.setState({Contratos: response.data.data})
        this.setState({Pager_Contratos: response.data.pager.items})
        this.setState({pager_infocontratos: response.data.pager.showData})
  
      
        this.setState({ loading: this.state.loading = true })
      })
      .catch((error) => {
        console.log(error);
      })
    
      }

      searchDatacontratos(buscar_contratos) {

        this.setState({ loading: this.state.loading = false })
        
        axios.get(ApiURL+"/api/Contracts/List?texto="+buscar_contratos)
        .then((response) => {
        console.log(response);

        this.setState({Contratos: response.data.data})
        this.setState({Pager_Contratos: response.data.pager.items})
        this.setState({pager_infocontratos: response.data.pager.showData})
        
          this.setState({ loading: this.state.loading = true })
        })
        .catch((error) => {
          console.log(error);
        })
      
        }


    seleccionarcontrato=(contrato)=>{
      this.setState({
        form:{
          id_contra_edit:contrato.id,  
          nombre_contratoedit:contrato.name.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))),
          num_contra_edit:contrato.number,
          fecha_ini_edit:moment(contrato.startDate).format("YYYY-MM-DD"),
          fecha_fin_edit:moment(contrato.endDate).format("YYYY-MM-DD"),
          id_entity:contrato.entityId  
         
        }
      })
      //var  dato  = solicitud.user.identification;
      //alert(dato);
    }

    Limpiar_form=()=>{
      this.setState({
        form:{          
          nombre_contrato:'',
          num_contra:'',
          fecha_ini:'',
          fecha_fin:''         
        }
      })
     
    }


    savecontrato=async()=>{

      if(!moment(this.state.form.fecha_ini,'YYYY-MM-DD', true).isValid() || !moment(this.state.form.fecha_fin,'YYYY-MM-DD', true).isValid()){

        store.addNotification({
          title: 'Atención',
          message: "Ingrese un rango de fecha valido",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

      if(id_entity==0 || this.state.form.nombre_contrato=='' || this.state.form.num_contra=='' || this.state.form.fecha_ini=='' || this.state.form.fecha_fin==''){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.post(ApiURL+"/api/Contracts/Save",
  
          {
            name:this.state.form.nombre_contrato,
            entityId:id_entity ,
            number: this.state.form.num_contra,
            startDate: this.state.form.fecha_ini,
            endDate: this.state.form.fecha_fin
          } )  
  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

          $("#div_contrato1").find("*").prop('disabled', true);
          $("#div_contrato2").find("*").prop('disabled', true);
          $("#div_contrato3").find("*").prop('disabled', true);

          this.Limpiar_form();
          
          
        })
        .catch(error=>{
            
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);
      
      })

      }

    }
      
  
    }


    editcontrato=async()=>{

      if(!moment(this.state.form.fecha_ini_edit,'YYYY-MM-DD', true).isValid() || !moment(this.state.form.fecha_fin_edit,'YYYY-MM-DD', true).isValid()){

        store.addNotification({
          title: 'Atención',
          message: "Ingrese un rango de fecha valido",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

      if(this.state.form.id_contra_edit==0 || this.state.form.num_contra_edit==0){

        store.addNotification({
          title: 'Atención',
          message: "Faltan datos por ingresar",
          type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 10000,
            showIcon:true
          }
        })

      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ active_button: this.state.active_button = false })

        await axios.put(ApiURL+"/api/Contracts/Edit/"+this.state.form.id_contra_edit,  
        {
          id: this.state.form.id_contra_edit,
          name:this.state.form.nombre_contratoedit,
          entityId:id_entity,
          number: this.state.form.num_contra_edit,
          startDate: this.state.form.fecha_ini_edit,
          endDate: this.state.form.fecha_fin_edit
        } )          
  
        .then(response=>{
          console.log(response.data)
          mensaje  = response.data.message;
          //alert(mensaje);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);          
                 
          //window.location.href="/home";
          store.addNotification({
            title: 'Muy bien',
            message: mensaje,
            type: 'success',                         // 'default', 'success', 'info', 'warning', 'danger'
            container: 'top-right',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
              duration: 10000,
              showIcon:true
            }
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

          this.ListaContratos();
          $('#modal-consentimientoedit').modal('hide');
          
        })
        .catch(error=>{
         
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            mensaje=error.response.data.message
            
            store.addNotification({
              title: 'Atención',
              message: mensaje,
              type: 'danger',                         // 'default', 'success', 'info', 'warning', 'danger'
              container: 'top-right',                // where to position the notifications
              animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
              animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
              dismiss: {
                duration: 10000,
                showIcon:true
              }
            })

            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })

        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        //console.log(error.config);
      
      })
        
      } 
      
    }
  
    }
    


    handleModalcontratoedit()
    { 
      //$('#modal-mensajecontrato').modal('show'); // <- to show modal editar contrato     
      $('#modal-consentimientoedit').modal('show'); // <- to show modal editar contrato 
    }



    handlerSelectdrop=async()=>{
      
      $("#eps").val($('#eps>option:first').val());
      $("#nombre_contrato").val("");
      $("#num_contra").val("");
      $("#fecha_ini").val("");
      $("#fecha_fin").val("");
      $("#div_contrato1").find("*").prop('disabled', false);
      $("#div_contrato2").find("*").prop('disabled', false);
      $("#div_contrato3").find("*").prop('disabled', false);
      
      id_entity='0';
     
    }


    handleridservi = (e) => {
      console.log(e)
      id_servi=(e)
      //alert(id_servi)
    }


    handlerideps = (p) => {
      console.log(p)
      id_entity=(p)
      //alert(id_entity)
    } 


    render(){  
      const {form} = this.state;

     return (

        <div className="content-wrapper">
  
              <div className="col-12 col-sm-12">

                  <div className="row">
            
                      <div className="col-sm-10">           
                      <br></br>
                         <div className="text-center" id="title"> <h6> <b> GESTIONAR CONTRATOS</b>  </h6> </div>
            
                      </div>  
            
                  </div>
            
                  <div className="card card-primary card-outline card-tabs">

                        <div className="card-header p-0 pt-1 border-bottom-0">
                          <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
                            <li className="nav-item">
                              <a className="nav-link active" id="custom-tabs-three-profile-tab" data-toggle="pill" href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile" aria-selected="true" onClick={()=>{this.handlerSelectdrop();this.ListaContratos()}} > Contratos <Logo_edit/></a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="false"  >Crear contrato <Logo_nuevo/>  </a>
                            </li>                     
                           
                          </ul>
                        </div>

                        <div className="card-body">

                              <div className="tab-content" id="custom-tabs-three-tabContent">

                                  <div className="tab-pane fade show active" id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab">
                                        
                                        <div class="d-flex justify-content-center">
                                            
                                            {this.state.loading?false: <ReactBootStrap.Spinner animation="border" />}
                                    
                                        </div>                                 
                                        
                                        <div className="card table-responsive" id="tabla1">
    
                                              <div className="row">
                                      
                                                <div className="col-sm-3">
                                                      <div className="input-group input-group-sm mb-3 mr-sm-2">
                                                         <div className="input-group-prepend">
                                                           <div className="input-group-text"><Buscar/></div>
                                                         </div>
                                                         <input type="search" className="form-control" placeholder="Buscar" name="buscar_contratos" onChange={this.BuscarContrato}/>
                                                      </div>                                
                                                </div>
                                                
                                              </div>                       
                                  
                                
                                              <table id="tabla_solicitudes" className="table table-bordered table-striped">
                                                  <thead>
                                                    <tr>
                                                      <th>ID</th>
                                                      <th>Nombre de contrato</th>
                                                      <th>Entidad</th>
                                                      <th>Numero de contrato</th>   
                                                      <th>Fecha inicio</th> 
                                                      <th>Fecha final</th> 
                                                      <th>Estado</th>                
                                                      <th>Editar</th>          
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {this.state.Contratos.map(contrato=>{
                                                    return(
                                            
                                                    <tr>
                                                      <td>{contrato.id}</td>  
                                                      <td>{(contrato.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>        
                                                      <td>{(contrato.entity.razonSocial).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>
                                                      <td>{contrato.number}</td>
                                                      <td> 
                                                      <Moment format="YYYY/MM/DD">
                                                      {contrato.startDate}
                                                      </Moment>
                                                      </td>
                                                      <td> 
                                                      <Moment format="YYYY/MM/DD">
                                                      {contrato.endDate}
                                                      </Moment>
                                                      </td> 
                                                      <td>{(contrato.status).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</td>              
                                                      <td onClick={()=>{this.handleModalcontratoedit();this.seleccionarcontrato(contrato)}}> <BtnEdit  />
                                                      </td>
                                                    </tr>
                                                    )
                                                   }
                                                 )}
                                                    
                                                                   
                                                  </tbody>
    
                                              </table>
    
                                              <span>{this.state.pager_infocontratos}</span>
                                                <nav className="nav">
                                                
                                                    <ul className="pagination">
              
                                                        {this.state.Pager_Contratos.map(page_contra=>{
                                                        
                                                          var activo=page_contra.activo
                                                          if(activo==true){
                                                            activo='page-item active'
                                                          }else{
                                                            activo='page-item'
                                                          }
              
                                                         // alert(activo)
                                                          
                                                         return(                          
                                                                  
                                                                 <li className={activo}>
                                                                   <a className="nav-link" onClick={()=>{this.loadMoreDatasolicitadas(page_contra)}}>{page_contra.texto}</a>
                                                                 </li>                                            
                                                           )
                                                          }
                                                         )
                                                        }
    
                                                    </ul>
                                                          
                                                </nav>                                  
                                                   
                                    
                                        </div>
    
                                        <div className="d-flex justify-content-center">                              
                                            <img id="empty_page" src={emptypage} alt="logo"  width= "200"/>                             
                                        </div>
                                      
                                  </div>

                                  <div className="tab-pane fade" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">
                                    
                                      <div className="card">         
                            
                                          <div className="row" id="div_contrato1">
                                
                                              <div className="col-md-6">
                                                    <div className="form-group">                    
                                                            <label >Entidades:</label>
                                                            <Eps handlerideps={this.handlerideps}/>                    
                                                    </div>
                                              </div>

                                              <div className="col-md-6">              
                                                  <div className="form-group">  

                                                      <label >Nombre del contrato:</label>

                                                      <div className="input-group">                                                   
                                                          <input type="text" maxLength="80" className="form-control" placeholder="Nombre del contrato" name="nombre_contrato" id="nombre_contrato" onChange={this.handleChange}/>                       
                                                          <div className="input-group-append">
                                                                 <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde al nombre del contrato suscrito entre las partes, que es asignado por el prestador, para identificar el tipo de servicio a prestar a los usuarios del contratante" > <span className="fas fa-info-circle"></span> </button>
                                                          </div>
                                                      </div>

                                                  </div>              
                                              </div>                                  
                                
                                          </div>
                                
                                          <div className="row" id="div_contrato2" >

                                             <div className="col-md-6">              
                                                  <div className="form-group">   

                                                      <label >Numero de contrato:</label>

                                                      <div className="input-group">
                                                          <input type="text" maxLength="80" className="form-control" placeholder="Numero de contrato" name="num_contra" id="num_contra" onChange={this.handleChange}/>                       
                                                          <div className="input-group-append">
                                                                 <button id="icono_crea" className="btn btn-primary form-control-md" type="button" data-toggle="tooltip" data-placement="top" title="Corresponde al consecutivo o código asignado al contrato suscrito entre las partes, que legaliza la prestación del servicio de salud." > <span className="fas fa-info-circle"></span> </button>
                                                          </div>
                                                      </div>

                                                  </div>              
                                              </div>
                                
                                              <div className="col-md-3">              
                                                  <div className="form-group">                    
                                                          <label >Fecha inicio:</label>
                                                          <input type="date" className="form-control" name="fecha_ini" id="fecha_ini" onChange={this.handleChange}/>
                                                  </div>              
                                              </div>
                                                                
                                              <div className="col-md-3">
                                                  <div className="form-group">                    
                                                          <label >Fecha terminación:</label>
                                                          <input type="date" className="form-control" name="fecha_fin" id="fecha_fin" onChange={this.handleChange}/>                    
                                                  </div>                 
                                              </div>                                                                
                                                                        
                                          </div>                                            
                                
                                          <div className="modal-footer">   

                                            <div id="div_contrato3">  

                                              <button id="btn_save"  className="btn btn-save" disabled={!this.state.active_button} onClick={()=> this.savecontrato()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar
                                                <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" class="bi bi-check2-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                   <path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                                   <path fill-rule="evenodd" d="M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"/>
                                                </svg>
                                              </button> 

                                            </div> 
                                          
                                              <button  className="btn btn-primary" onClick={()=> this.handlerSelectdrop()} > Nuevo
                                                     <svg width="1.2em" height="1.2em" viewBox="3 3 10 10" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                     </svg>
                                              </button>
                                          </div>                            
                              
                                   
                                      </div>
                                   
                          
                                  </div>                               
                                                     
                        
                              </div>                            
                           
                          </div>

                  </div>              
              
            </div>
  
  
            <div className="modal fade" id="modal-consentimientoedit">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title"><b>Editar Contrato</b></h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">

                        <div className="row">
            
                              <div className="col-md-2">
                                    <div className="form-group">                    
                                      <label >ID:</label>
                                      <input type="text" className="form-control" name="id_contra_edit" value={form.id_contra_edit} readOnly />                      
                                    </div>                 
                              </div> 

                              <div className="col-md-10">
                                    <div className="form-group">                    
                                      <label >Numero de contrato:</label>
                                      <input type="text" className="form-control" id="num_contra_edit" name="num_contra_edit" onChange={this.handleChange} value={form.num_contra_edit} />                      
                                    </div>                 
                              </div>                          
            
                        </div>
                    
                        <div className="row">
            
                              <div className="col-md-12">
                                    <div className="form-group">                    
                                      <label >Nombre contrato:</label>
                                      <input type="text" className="form-control" name="nombre_contratoedit" value={form.nombre_contratoedit} onChange={this.handleChange}/>                      
                                    </div>                 
                              </div>                   
            
                        </div>
            
                        <div className="row">
            
                              <div className="col-md-6">              
                                  <div className="form-group">                    
                                          <label >Fecha inicio:</label>
                                          <input type="date" className="form-control" name="fecha_ini_edit" onChange={this.handleChange} value={form.fecha_ini_edit}/>
                                  </div>              
                              </div>
                                                
                              <div className="col-md-6">
                                  <div className="form-group">                    
                                          <label >Fecha terminación:</label>
                                          <input type="date" className="form-control" name="fecha_fin_edit" onChange={this.handleChange} value={form.fecha_fin_edit}/>                    
                                  </div>                 
                              </div>
            
                        </div>
                           
                                 
            
            
                      </div>
            
                  <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-primary" disabled={!this.state.active_button} onClick={()=> this.editcontrato()}> {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />} Guardar</button>
                    <button type="button" className="btn btn-danger" data-dismiss="modal">Cerrar</button>        
                  </div>
                </div>
                
              </div>
              
            </div> 

            <div className="modal fade" id="modal-mensajecontrato" data-backdrop="static">

                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title"><b>Atención</b></h4>
                      
                    </div>
                    <div className="modal-body">
  
                          <div className="col-md-12">
  
                              Las modificaciones de fechas de inicio o fecha final que se realicen al contrato, puede que no se apliquen de manera inmediata; este proceso podría tardar hasta hasta 6 horas en completarse.
  
                          </div>
                                 
                    </div>
          
                          <div className="modal-footer justify-content-between">        
                             {/* <button type="button" className="btn btn-primary" onClick={()=> this.aceptarcita()} data-dismiss="modal" >Si</button> */}
                             <button type="button" className="btn btn-primary" data-dismiss="modal">Continuar</button>
                          </div>
                  </div>                
                </div>
            
            </div>         
  
  
        </div>      
      
    )

  }
  
}


export default Contratos;